import * as React from 'react'
import { Typography, Box, Grid, TextField, ToggleButton } from '@mui/material'
import { RoutingTool } from '../utility/connections'
import { LoadingButton } from '@mui/lab'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import { round } from '../misc/other'
import axios from 'axios'
import { dayOfMonth } from '../misc/dates'
import { Navigate } from 'react-router-dom'
import { ErrorAlert } from '../alerts/ErrorAlert'
import { axiosErrorHandling } from '../misc/other'



export const PaybyCheckRec = ({customerInfo, paymentInfo, promoCodes, numberOfMonths, paymentDue, setDisabledMonth, setupLink}) =>{

let fullName = customerInfo.first_name + ' ' + customerInfo.last_name
let original_message = '***If you want all documents mailed to you please click the mailbox icon below for a fee of $5.00, please check that your mailing address in the billing info is correct.***'
let fiveDollarMessage = 'Your documents will be mailed to you and a $5.00 fee has been added, to change the number of months click this icon again.'

const [promoText, setPromoText] = React.useState(null)
const [newTotalDue, setnewTotalDue] = React.useState(round(paymentDue))
const [routingNumber, setRoutingNumber] = React.useState('')
const [recurringPaymentSetup, setRecurringPaymentSetup] = React.useState(false)
const [errorAlert, setErrorAlert] = React.useState(false)
const [errorMessage, setErrorMessage] = React.useState('')
const [paymentProcessing, setPaymentProcessing] = React.useState(false)
const [bankName, setBankName] = React.useState('')
const [accountNumber, setAccountNumber] = React.useState('')
const [routingValidated, setRoutingValidated] = React.useState(false)
const [mailDocs, setMailDocs] = React.useState(0)
const [mailOption, setMailOption] = React.useState(false)
const [mailMessage, setMailMessage] = React.useState(original_message)

var RoutingToolData = new FormData()
RoutingToolData.append('secret_key', RoutingTool.secretKey)
RoutingToolData.append('public_key', RoutingTool.publicKey)

let billinginfoPromos = paymentInfo.promoCodesUsed



const errorClose =()=>{
    setErrorAlert(false)
}


const routingNumberCheck = (event) =>{
    setRoutingNumber(event.target.value.toString())
}

const accountNumberChange = (event) =>{
    setAccountNumber(event.target.value.toString())
}

const payClick = async () =>{

    const totalDue =  round(newTotalDue*numberOfMonths)
    setPaymentProcessing(true)
    let values = {
        accountNumber: accountNumber,
        routingNumber: routingNumber,
        customerNumber: customerInfo.customer_number,
        bankName: bankName,
        fullName: fullName,
        email: customerInfo.email,
        address1: customerInfo.street_address_1,
        address2: customerInfo.street_address_2,
        city: customerInfo.city,
        state: customerInfo.state,
        zip: customerInfo.zip,
        zip4: customerInfo.zip4
    }

const dataToSend ={ values, 
                    phone_number: customerInfo.phone_number, 
                    number_of_payments: numberOfMonths, 
                    recurring_payment_amount: newTotalDue  }
   await axios({
        method: "POST",
        url: "api/oracle6/recurringpayment",
        data: dataToSend          
    })
    .then( async result=>{
        
        if(result.status===200 &&  Number(result.data.Result[0])=== 0){
            let numberOfPaymentsLeft = numberOfMonths - 1 
            let balance =  round(newTotalDue*numberOfPaymentsLeft)
            let id = result.data.Check_ID[0]
        
         await axios({
                method: "POST",
                url: "api/oracle3/recurring",
                data: {data: paymentInfo.accounts,
                       balance: balance,
                       number_of_payments: numberOfPaymentsLeft,
                       day_of_month: Number(dayOfMonth),
                       mail_documents: mailDocs,
                       promo_code_used: promoCodes,
                       waveID: customerInfo.wave_customer_number

                    }

            })
            .then( async result=>{

                
                if(result.data==="Recurring Payments Set Up"){
                            
                    await axios({
                        method: "POST",
                        url: 'api/oracle3/recinvoicegen',
                        data: {data: paymentInfo.accounts,
                               customerInfo: customerInfo,
                               totalDue: totalDue,
                               payments_made: Number(newTotalDue).toFixed(2),
                               id: id 
                        }
                    }).then(async results=>{
                        
                        if(results.data==='Invoice Generated'){
                            await axios({
                                method: "POST",
                                url: 'api/oracle6/postrecurringtransaction',
                                data:{id: id,
                                      total: Number(newTotalDue).toFixed(2),
                                      customer_number: customerInfo.customer_number
                                }
                            }).then(result2=>{
                                
                                if(result2.data==='Transaction Posted'){
                                    setPaymentProcessing(false)
                                    setRecurringPaymentSetup(true)

                                }
                                else{
                                    setErrorMessage(result2.data)
                                    setErrorAlert(true)
                                    setPaymentProcessing(false)
                                }
                            }).catch(err=>{
                                
                               let berrormessage = axiosErrorHandling(err)
                                setErrorMessage(berrormessage)
                                setErrorAlert(true)
                                setPaymentProcessing(false)
                            })
                            
                            
                            
                            
                        }else{
                            setErrorMessage(result.data)
                            setErrorAlert(true)
                            setPaymentProcessing(false)
                        }
                    }).catch(err=>{
                        
                        let anerrormessage = axiosErrorHandling(err)
                        setErrorMessage(anerrormessage)
                        setErrorAlert(true)
                        setPaymentProcessing(false)
                    })
                    
                    
                    
                }else{

                    setErrorMessage(result.data)
                    setErrorAlert(true)
                    setPaymentProcessing(false)
                    
                }
            }).catch(errors=>{
                let message = axiosErrorHandling(errors)
                 setErrorMessage(message)
                setErrorAlert(true)
                setPaymentProcessing(false)

            })
   
            
        } else{
            
            setErrorMessage(result.data)
            setErrorAlert(true)
            setPaymentProcessing(false)
        }
    })
    .catch(errors=>{
        let message = axiosErrorHandling(errors)
        setErrorMessage(message)
        setErrorAlert(true)
        setPaymentProcessing(false)
        
    })

    

}

React.useLayoutEffect(()=>{
    if(promoCodes.length>0){
       promoCodes = promoCodes.map((item, index) =>({...item, id: index+1})) 

        setPromoText(promoCodes.map((e)=><li key={e.id}>{e.promoCode}</li>))
        
        }else{
            if(billinginfoPromos.length>0){
                billinginfoPromos = billinginfoPromos.map((item, index)=>({...item, id: index+1}))
                setPromoText(billinginfoPromos.map((e)=><li key={e.id}>{e.promoCode}</li>))
                
            }
            
        }

},[promoCodes, billinginfoPromos ])

React.useEffect(()=>{
        

    if(routingNumber.length === 9){
        
                   
        
        axios({
            method: 'POST',
            url: 'https://verify.routingtool.com/api/get_token',
            headers: {'Content-Type': 'multipart/form-data'},
            data: RoutingToolData

        })
        .then(response=>{
            
            let accessToken = response.data.access_token
            let dataToSend = new FormData()
            dataToSend.append('routing',routingNumber)
            axios({
                method: "POST",
                url: 'https://verify.routingtool.com/api/get_routing',
                headers: {'Content-Type': 'multipart/form-data','X-Access-Token': accessToken},
                data: dataToSend

            }).then(response=>{
               
                if (response.data.info.result_code === "WF01" || response.data.info.result_code === "WN02"){setBankName(response.data.info.bank_info.name)
                                                               setRoutingValidated(true)         }
                else{ setBankName('Check your Routing Number'); setRoutingValidated(true)} //change for production false to true
            })
        })
    }

},[routingNumber])



const changeInMail = () =>{

    let newBoolean = !mailOption
    
    setMailOption(newBoolean)
    
    setTimeout(()=>{if(newBoolean){
    
    let newamount = round(((paymentDue*numberOfMonths) + 5)/numberOfMonths)
    setDisabledMonth(true)
    setnewTotalDue(newamount)
    setMailMessage(fiveDollarMessage)
    setMailDocs(1)}
    else{
    setMailMessage(original_message)
     let newmount = round(((newTotalDue*numberOfMonths)-5)/numberOfMonths)
     setDisabledMonth(false)
     setnewTotalDue(newmount)
     
     
     
     setMailDocs(0)
    }}, 250)
}

if(recurringPaymentSetup){return <Navigate to ={setupLink} state={{accounts: paymentInfo, monthlyBill: newTotalDue, totalDue: newTotalDue*numberOfMonths}}/>}

return(
    <Box sx={{minHeight:'30vh', mt: 2, mb: 2}}>
        <ErrorAlert open={errorAlert} error={errorMessage} handleClose={errorClose} />
        <Grid container direction='row' sx={{mb: 2}}>
            <Grid item>
                <Typography color='secondary'>First Payment is due today, you will be billed ${newTotalDue} over the next {paymentInfo.numberofMonths-1} months</Typography>
            </Grid>
         </Grid>   
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                <Typography>Please fill out your checking account info</Typography> 
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='accountnumber'
                    id='accountnumber'
                    label="Account Number"
                    type='number'
                    value={accountNumber}
                    onChange={accountNumberChange}
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField
                    fullWidth
                    name='routingnumber'
                    id='routingnumber'
                    label="Routing Number"
                    type='number'
                    inputProps={{maxlength: 9}}
                    onChange={routingNumberCheck}
                    required
                />
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12} sm={10} md={9}>
                <TextField 
                    fullWidth
                    type='text'
                    margin='none'
                    placeholder='Your Bank'
                    disabled={true}
                    variant='outlined'
                    id='bankName'
                    label="Financial Institution"
                    value={bankName}
                />
            </Grid>

        </Grid>
        <Grid container direction='row-reverse' sx={{mt: 1, mb: 1}} spacing={1} alignItems='center' alignSelf='center' alignContent='center'>
            <Grid item xs={4}>
                <LoadingButton 
                loading={paymentProcessing} 
                loadingPosition='end' 
                disabled={!routingValidated} 
                endIcon={<AccountBalanceIcon />} 
                sx={{height: 45}} 
                fullWidth 
                variant='contained'
                onClick={payClick}
                > Pay ${newTotalDue} </LoadingButton>
            </Grid>
            <Grid item>
                <ToggleButton 
                 value='mailOption'
                 selected={mailOption}
                 onChange={changeInMail}
                 color='primary'           >
                    <MarkunreadMailboxIcon />
                </ToggleButton>
            </Grid>
            <Grid item xs={4}>
                    <Typography>Promo Codes used: {promoText} </Typography>
            </Grid>
            
        </Grid>
        <Grid container direction='row'>      
            <Grid item>
                <Typography variant='body2' color='secondary'>{mailMessage} </Typography>
            </Grid>
        </Grid>
    </Box>
    
    
)
}
import * as React from "react"
import { Typography, Grid, Link, Container, Box } from "@mui/material"
import { PrivacyPolicyDate } from "../misc/dates"



export const RefundPolicy =()=>{

   

    
    let reasons =[{id: 1, label: 'Unauthorized Payee:',reason: ' The person paying the debt was not authorized to make the payment.'},
                  {id: 2, label: 'Unauthorized Transaction:', reason: ' The account that the debit was drawn from was not authorized by the account holder'},
                  {id: 3, label: 'Other:', reason: ' A reasonable situation not accounted for in the previous categories.'}    ]
    
    const reasonList = reasons.map((d)=>
    <li key={d.id}><b>{d.label}</b>{d.reason}</li>)   
        return(
            <React.Fragment>
            <Container>
                <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
        <Grid container direction='column' justifyContent='center' justifyItems='center'>
            <Grid item>  
        <Typography variant='h3' sx={{marginLeft: '8rem', marginRight: '12rem'}} color='primary' gutterBottom >Refund Policy</Typography>
            </Grid>
            <Grid item>
     <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Last updated: {PrivacyPolicyDate}</Typography>
         </Grid>
         <br/>
         <Grid item>
     <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>All sales are final. However we can refund and re-open a settled account for the following reasons: </Typography>
         </Grid>
         <br/>
        <Grid item >
        <Typography variant='body1' sx={{marginLeft: '12rem', marginRight: '12rem', textIndent: 5}}>{reasonList}</Typography>
         </Grid>
         <br/>
         <Grid item>
     <Typography variant='body1' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>To request a refund you can use the feedback module or email: <Link href='mailto:admin@nammeysettlement.com?subject=Refund%20Request' underline='hover'>admin@namemysettlement.com</Link> </Typography>
         </Grid>
         <br/>
         <Grid item>
     <Typography variant='body1' color='secondary' gutterBottom={true} sx={{marginLeft: '10rem', marginRight: '12rem'}}>Typically All Sales are Final and any request to Refund the money must be received by the Friday following the transaction. (If the transaction occured on Friday the 13th you must request the refund by the 20th.). Every Friday we send the account settled information to Experian, Equifax, TransUnion and FICO.  We do this every Friday. Once reported we cannot refund the ammount because the account is considered closed. </Typography>
         </Grid>
         
        </Grid>
        </Box>
       
        </Container>
        
    </React.Fragment>

        )
    } 
import * as React from 'react'
import {Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer'
import axios from 'axios'
import { formatted_date } from '../misc/dates'
import { round } from '../misc/other'

const BASE_URL = window.location.origin


const getPDFData = async (customer_number) => {

   
    const promise = await axios.post('api/oracle5/pdfinfo', {customer_number: customer_number})
    .then((result)=>{
        if (result.status===200){
            let pulledUserData = {first_name: result.data[0],
                                  last_name:  result.data[1],
                                  street_address_1: result.data[2],
                                  street_address_2: result.data[3],
                                  city: result.data[4],
                                  state: result.data[5],
                                  zip: result.data[6],
                                 zip4: result.data[7],
                                  }
            return pulledUserData
        
            }
        
        
         else {console.log('Other than 200 result')} 


    }).catch(errors=>{console.log("Here are the errors in the getPDFData call: ", errors)})

    return promise


}

export const getProps = async (data, offerAccepted) =>{
    let customer_number = data[0].customer_number
    let accounts = []
    let zip = ''
    let accountString = ''
    let total = offerAccepted
        
    for(let l=0; l < data.length; l++){
    accounts.push(data[l])
    }
    for(let m=0; m<accounts.length; m++){
        accountString += `-Original Creditor: ${accounts[m].original_creditor}, Account Number: ${accounts[m].original_account_number} Original Amount: $${accounts[m].principal} Settlment Offer: $${round((total*accounts[m].per_of_settlement))} \n`;
              }
    
    const PDFUserData = getPDFData(customer_number).then((user)=>{return user}) 
    
    let userData = await PDFUserData
    userData.zip = userData.zip.slice(0,5)
    
    if (userData.zip4 === null ||userData.zip4 === '' ){
        zip = `${userData.zip}`
    }else{
        zip = `${userData.zip}-${userData.zip4}`
    }
    
    if (userData.street_address_2=== ''){
        userData.street_address_2 = null
    }
    
    
     
    return({customer_number: customer_number, 
            total: round(total), 
            accountString: accountString, userData: userData, zip: zip})
    
    
    

}

Font.register({family: 'Roboto', src: "https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2"})
let pagefont = 11





const styles = StyleSheet.create({
    page:{flexDirection: 'column',
    backgroundColor: 'white'

    },

    letterView: {
        
        margin: 2.5,
        padding: 1,        
        alignItems: 'center'
    },
    letterheadLogo:{
        width: 320,
        height: 70,
        margin: 2.5,     
                },
    addressView:{
        alignItems: 'center',
        flexDirection: 'column',
        color: 'gray',
        fontSize: 14
        
        
    },
    address:{
        fontSize: pagefont,
        color: '#100c08',           
        marginBottom: pagefont,
        marginLeft: 16

    },
    afterAddress:{
        marginBottom: pagefont,
        marginLeft: 16
    },
    body:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: pagefont
    },
    subjectLine: {
         marginBottom: 12,
         fontWeight: 'bold',
         fontSize: 12,
         marginLeft: 16

    },
    accountsLine:{
        color: '#100c08',
        fontSize: 9,
        marginLeft: 16,
        marginRight: 16,
        marginBottom: pagefont,
        lineHeight: 1.5
    },
    signature:{
        width: 200,
        marginLeft: 16
        

    },
    signatureStart:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        marginRight: 16,
        marginTop: pagefont
    },
    signatureBlock:{
        color: '#100c08',
        fontSize: pagefont,
        marginLeft: 16,
        lineHeight: 1.2
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        color: '#100c08',
        fontSize: pagefont-1,
        marginLeft: 14,
        marginRight: 14,
        marginBottom: pagefont
    },
    bullet:{
        height: '100%'
    },
    indentAddress: {
        textIndent: '200px',
        fontSize: pagefont-1,
        color: '#100c08'


    },
    mailto:{
        textIndent: '150px',
        fontSize: pagefont-1,
        color: '#100c08'

    }



})

const ListItem = ({children}) =>{
    return(
        <View style={styles.row}>
            <View style={styles.bullet}>
                <Text>{String.fromCharCode(0x2022)+ ' '}</Text>
            </View>
                <Text>{children}</Text>
        </View>
    )

  


}

export const OfferLetterPDF = ({customer_number, total, accountString, userData, zip}) => (
    



    <Document>
        <Page size='LETTER' style={styles.page}>
        <View style={styles.letterView}>
            <Image style={styles.letterheadLogo} src={BASE_URL+'/SmallPNGForLetterheadv3.png'}/>
        </View>
        <View style={styles.addressView}>
            <Text>PO Box 15333</Text>
            <Text style={styles.afterAddress}>Spokane Valley, WA 99215-333</Text>
        </View>
        <View style={styles.address}>
            <Text>{formatted_date}</Text>
        </View>
        <View style={styles.address}>
            <Text>{userData.first_name} {userData.last_name}</Text>
             <Text>{userData.street_address_1}</Text>
             <Text>{userData.street_address_2}</Text>
             <Text>{userData.city}, {userData.state} {zip} </Text>
        </View>
        <View sytle={styles.afterAddress}>
            <Text style={styles.body}>Customer Number: {customer_number}</Text>
        </View>
        <View>
            <View>
               <Text style={styles.subjectLine}> SUBJECT: SETTLEMENT OFFER LETTER FOR DEBT OF CUSTOMER: {customer_number} </Text> 
            </View>
            <View>
                <Text style={styles.body}>This letter is to inform you that your offer or the offer you accepted as described below made or offered 
                                          on NameMySettlement.com has been accepted.</Text>
            </View>
            <View>
                <Text style={styles.body}>Description of Account(s) below:</Text>
            </View>
            <View>
                <Text style={styles.accountsLine}>{accountString}</Text>                
            </View>
            <View>
                <Text style={styles.body}>The total settlement amount is: ${total.toLocaleString('en-us',{maximumFractionDigits: 2, minimumFractionDigits: 2})}, as agreed upon for each account.
                                          Once the settlement amount is paid the debt will be then settled and no further payments or actions will be required.  
                                          A 1099-C will be filed with the IRS if the difference from the Face Value and Settlement Amount is greater than $600.00 
                                          and may be counted as taxable income, however some people will be able to file exclusions or exceptions to this income.  
                                          Please contact a Certified Public Accountant for further Tax Advice on how to properly report this income.    </Text>
            </View>
            <View>
                <ListItem>Please note that a $5.00 mailing and/or payment processing fee may be charged based on method of payment and communication 
                                          delivery options.   </ListItem>
            </View>
            <View>
                <ListItem>Once payment is received and processed the debt will be considered reconciled and we will report to Equifax, 
                                          Transunion, and Experian that the debt has been settled. </ListItem>
                                          
                                          <View>
                                            <ListItem>No new offers will be entertained.
                                            </ListItem>   
                                          </View>
                                          <View>
                                            <ListItem>	Paying via ACH: Your bank information will not be stored on our servers and a third party will process 
                                                the transaction. When you enter payment information on the website owned and operated by NameMySettlement.com or 
                                                any its parent companies or subsidies, NameMySettlement.com will not have any access to your bank account or information. 
                                                You will only be charged what is entered in the Total Due Field.
                                                </ListItem>
                                                </View>
                                                <View>
                                                    <ListItem>
                                                    Any Marketing Promotional Offers that you wish to use will be redeemed on checkout but is not subject to the 1099-C.

                                                    </ListItem>
                                                </View>
                                                <View>
                                                    <ListItem>
                                                    Paying via Money Order: Please include a copy of this letter with your payment. We recommend certified mail with a return 
                                                    receipt so you can verify that we receive your payment.
                                                    </ListItem>
                                                    <View>

                                                    <Text style={styles.mailto}>Mail to:</Text>
                                                    </View>
                                                    <View>
                                                        <Text style={styles.indentAddress}>NameMySettlement.com</Text>
                                                        <Text style={styles.indentAddress}>PO Box 15333</Text>
                                                        <Text style={styles.indentAddress}>Spokane Valley, WA 99215</Text>

                                                        </View>
                                                            
                                          </View>
            </View>
            <View>
                <Text style={styles.signatureStart}>Thanks for your business,</Text>
            </View>
            <View>
                <Image style={styles.signature} src={BASE_URL+'/Signature.png'}/>
            </View> 
            <View>
                <Text style={styles.signatureBlock}>Brian Boyer</Text>
                <Text style={styles.signatureBlock}>Chief Executive Officer</Text>
                <Text style={styles.signatureBlock}>NameMySettlement.com</Text>
            </View>
            </View>

        

        </Page>
    </Document>

);
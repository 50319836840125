import * as React from 'react'
import {Grid, 
    FormControl, 
    Input, 
    InputAdornment,
    Typography,
    Slider,Box, Link, Breadcrumbs }from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { CounterOffer } from '../../alerts/CounterOffer'
import { AcceptedSettlement } from '../../alerts/AcceptedSettlement'
import { Navigate, useLocation, Link as RouterLink } from 'react-router-dom'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import { axiosErrorHandling } from '../../misc/other'
import axios from 'axios'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'

export const UserSettlement = () =>{
    let location = useLocation()
    const context = JSON.parse(window.localStorage.getItem('context'))
    const accountpath = '/user/accounts/' + context.email

    
    
    let settlementLeast = 0
    let settlementData = location.state.accounts
    
    for(let i=0; i<settlementData.length; i++){
       
        settlementLeast += (settlementData[i].principal * settlementData[i].settlement_per)
        
    }
    

    
    
    for(let j=0; j<settlementData.length; j++){
        
        settlementData[j].per_of_settlement = Math.round(((settlementData[j].principal * settlementData[j].settlement_per) / settlementLeast)*100)/100

    }
        
        
       
        const closetosixhundred = 660

        const [max, setMax] = React.useState(location.state.totalDue)
        const [min, setMin] = React.useState(0)
        const [userOffer, setUserOffer] = React.useState(((location.state.totalDue)/2).toFixed(2))
        const [sixHundredAlert, setSixHundredAlert] = React.useState(false)
        const [counterOfferOpen, setCounterOfferOpen] = React.useState(false)
        const [settlementOpen, setSettlementOpen] = React.useState(false)
        const [offerAmount, setOfferAmount] = React.useState(0)
        const [accountData, setAccountData] = React.useState(settlementData)
        const [readyForPayment, setReadyForPayment] = React.useState(false)
        const [totalDue, setTotalDue] = React.useState(0)
        const [loading, setLoading] = React.useState(false)
        const [savings, setSavings] = React.useState(Math.round((max- userOffer)*100)/100)
        const [error, setError] = React.useState(false)
        const [errorMessage, setErrorMessage] = React.useState('')
        
    
  
        const handleSliderChange = (event, newValue) =>{
            let newSavings = location.state.totalDue - newValue
            setUserOffer(newValue)
            setSavings(newSavings)
      if(closetosixhundred > savings && savings > 600 ){
        setSixHundredAlert(true)
    } else {setSixHundredAlert(false)}
        }
    


    const handleInputChange = (event) =>{
        
        setUserOffer(event.target.value === '' ? '' : Number(event.target.value))
        let newSavings = location.state.totalDue - userOffer
        setSavings(newSavings)
        if(closetosixhundred > savings && savings > 600 ){
            setSixHundredAlert(true)
        } else {setSixHundredAlert(false)}
        
    }
        
    const handleBlur = () =>{
            if(userOffer < min) {
                setUserOffer(min)
            } else if (userOffer > max){
                setUserOffer(max)
            }
            if(closetosixhundred > savings && savings > 600 ){
                setSixHundredAlert(true)
            } else {setSixHundredAlert(false)}
        
        }
        const closeOfferModal=()=>{
            setCounterOfferOpen(false)
        
        }
        
        const gotoPayment=()=>{
            setReadyForPayment(true)
        }
        
        const closeAcceptedModal=()=>
        {  setSettlementOpen(false)
        
        }
        
        const marks = [
        { value: min,
            label: ('$'+ min)
        },
        { value: max,
            label: ('$' + max)
        
        }
        ]
       
    const closeError =() =>{
        setError(false)
    }
      
     
  
  const onOffer = async () =>{
    setLoading(true)
    
    if(userOffer < settlementLeast){
        let newOffer;
       await axios({
              method: 'POST',
              url: 'api/oracle5/counteroffer',
              data: {settlementLeast: settlementLeast, userOffer: Number(userOffer)}
        }).then(result=>{
            
            newOffer = result.data.newOffer
            return newOffer
            
            
        }).then(newOffer=>{
            setOfferAmount(newOffer)
            setMax((newOffer-1))
            setMin((userOffer+1))
            setAccountData(settlementData)        
            setCounterOfferOpen(true)
            setLoading(true)
            
        }).catch(err=>{
            let message = axiosErrorHandling(err)
            setErrorMessage(message)
            setError(true)
        })
            
            
        
        
    } else{
        for(let h=0; h<settlementData.length; h++){
            settlementData[h].settlement_amount = userOffer * settlementData[h].per_of_settlement
            settlementData[h].account_balance = userOffer * settlementData[h].per_of_settlement
        }    

       await axios({
            method: 'POST',
            url: 'api/oracle5/acceptoffer',
            data: settlementData
        }).then(result=>{
            
            if (result.data==='offer accepted'){
                setTotalDue(userOffer)
                setAccountData(settlementData)
                setSettlementOpen(true)
                setLoading(false)
            } else{
                let errMessage = 'Something went wrong'
                setErrorMessage(errMessage)
                setError(true)
            }

        }).catch(err=>{
            let message = axiosErrorHandling(err)
            setErrorMessage(message)
            setError(true)
        })
        
        
    }

}


let sixHundredWarning;
if(sixHundredAlert) { sixHundredWarning = <Grid item> <Typography sx={{mb:'2rem'}} variant='h6'color='primary' gutterBottom={true}> *If savings are less than $600 we do not have to report to the IRS any savings as taxable income.</Typography> </Grid>
} else {<Grid></Grid>}


if(readyForPayment){return <Navigate to='/user/payment' state={{accounts: accountData, promoCodesUsed: location.state.promoCodesUsed, totalDue: totalDue}} />}

    return(
       
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <ErrorAlert open={error} handleClose={closeError} error={errorMessage} />    
        <Breadcrumbs sx={{mb: 2}}>
            <Link underline='hover' component={RouterLink} to={accountpath}>Accounts</Link>
            <Link underline='none' color='gray'>Confirm Debt</Link>
        </Breadcrumbs>
        <CounterOffer open ={counterOfferOpen} onClose ={closeOfferModal} offerAmount ={offerAmount} offerAccepted ={offerAmount} data = {accountData} gotoPayment={gotoPayment} settotaldue={setTotalDue} />
        <AcceptedSettlement open={settlementOpen} onClose={closeAcceptedModal} offerAccepted ={totalDue} data = {accountData} gotoPayment={gotoPayment}/>
<Grid container direction='column' justifyContent='center' alignItems='center'>
    <Grid item>
    <Typography sx={{mb:'2rem'}} variant='h6'color='secondary' gutterBottom={true}> Make Your Offer Below: </Typography>
    </Grid>
    <Grid item>
        <FormControl fullWidth sx={{mb:'2rem', width: '12rem'}} variant='outlined'>
        
        <Input
        fullWidth
        id='offer'
        variant='outlined'
        value={userOffer}
        startAdornment={<InputAdornment position='start'>$</InputAdornment>}
        type='number'
        onChange={handleInputChange}
        onBlur={handleBlur}
        
        
        inputProps={{
            min: min,
            max: max,
            type: 'number',
            'aria-labelledby' : 'offer-slder',
            label: 'Offer Ammount',
            

        }}
        />
    <Grid item>
        <LoadingButton loading={loading} loadingPosition='end' endIcon={<PriceCheckIcon/>} sx={{mt: 2}} variant='contained' color='secondary' fullWidth type='button' onClick={onOffer} >Offer</LoadingButton>
    </Grid>
        </FormControl>
    </Grid>
    <Grid item>
        <Slider sx={{width: '12rem'}}
                value={Number(userOffer)} 
                min={min} 
                max={max} 
                onChange={handleSliderChange} 
                aria-labelledby="offer-slider"
                marks={marks}/>
    </Grid>
    <Grid item>
    <Typography sx={{mb: '12rem'}} variant='h6'color='secondary' gutterBottom={true}>Total Savings: ${savings.toFixed(2)}</Typography>
    </Grid>
    {sixHundredWarning}
    
</Grid>
</Box>


    )}
import * as React from 'react'
import { Typography, Box, Breadcrumbs, Link, ToggleButton, ToggleButtonGroup, Grid } from '@mui/material'
import { useLocation, Link as RouterLink } from 'react-router-dom'
import { AdminDownloadDocuments, AdminUploadDocuments } from '../../../forms/Documents'



export const AdminDocuments =()=>{

    const location = useLocation()
    console.log('Here is the location data:  ', location)
    const context = JSON.parse(window.localStorage.getItem('usercontext'))
   
    const accountpath = '/admin/customercare/accounts/' + context.email

    const [alignment, setAlignment] = React.useState('upload')

    const handleAlignment = (event, newAlignment) =>{
        setAlignment(newAlignment)
        
    }
    const downloadClick = () =>{
        
        setAlignment('download')
        
    }

    let form;

    if(alignment==='upload'){
        form =
                    
        <AdminUploadDocuments download ={downloadClick}/>        
        
    }else{
        form = 
        <AdminDownloadDocuments/>
    }
    



    return(
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
            <Breadcrumbs sx={{mb: 3}}>
            <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirm'>Confirm Info</Link>
            <Link underline='hover' component={RouterLink} to={accountpath}>Select Accounts</Link>
            
        </Breadcrumbs>  
        <Grid container
              direction='column'
              alignItems='center'
              justifyContent='center'
              sx={{mb: '1rem'}}>
         <Grid item sx={{mb: '1rem'}}>
            <Typography color='primary' variant='h5' align='center'>Use the Upload Customer Docs to Upload Documents for the selected customer. Use the Download Docs to Review uploaded Docs and to confirm them.</Typography>
        </Grid>         
        <Grid item sx={{mb: '1rem'}}>
    <ToggleButtonGroup color='secondary' value={alignment}size='large' aria-label='Upload Documents or Download Documents Button'>
            <ToggleButton value='upload'color='primary'  onClick={handleAlignment}>Upload Customer Documents</ToggleButton>
            <ToggleButton value='download' color='primary' onClick={handleAlignment}>Download and Confirm</ToggleButton>


    </ToggleButtonGroup>
    </Grid>
    {form}
    
    </Grid>
        </Box>
    )


}
import * as React from 'react'
import {Box, Container} from '@mui/material'
import { ConfirmationForm } from '../forms/ConfirmationForm'


export const ConfirmAccounts = () =>{

  let data = JSON.parse(window.localStorage.getItem('context'))


    return (
      <React.Fragment>
       <Container>
            <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
            <ConfirmationForm data={data}/>
            </Box>        
            </Container>
            
    
    
      </React.Fragment>
    
    
    
    )
    
    
    }
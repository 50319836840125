import * as React from 'react'
import { Navigate }  from 'react-router-dom'
import { Grid, Typography, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { round } from '../../../misc/other'
import axios from 'axios'
import DashboardIcon from '@mui/icons-material/Dashboard'





export const AdminMoneyOrder = ({paymentInfo, totalDue})=>{
    
    const path = '/admin/dashboard'
    const totalDueplus5 =  round(totalDue + 5)

    const [goHome, setGotoHome]= React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const onClick = () =>{
        setLoading(true)
        axios({
            method: 'POST',
            url: 'api/oracle3/moneyorder',
            data:{data: paymentInfo.accounts,
                  balance: totalDueplus5,
                  promo_code_used: paymentInfo.accounts[0].promo_code_used
             } 
        }).then(result=>{
            console.log('Here is the result, ', result.data)
            if(result.data==="Pending MoneyOrder"){
                setLoading(false)
                setGotoHome(true)
            }
            setLoading(false)
        }).catch(err=>{
            console.log('Here is the err: ', err)
            setLoading(false)
        })

    }
    
    if (goHome){
        return <Navigate  to={path}/>
    }

  

    
    
    return(
        
        <Box sx={{minHeight:'40vh', mt: 2}}>
        <Grid container direction='row' justifyContent='center' alignItems ='center'>
        <Grid item xs={10} >
        <Typography sx ={{mb: 4}} variant='h6'gutterBottom={true}> Read to customer:  To complete payment by money order, make the money order out
                                          to NameMySettlement.com with the amount of ${totalDueplus5}. Which is your total due plus $5 for processing. Please send the Money Order to the following address, PO Box 15333, Spokane Valley, WA 99215. Please include your offer 
                                          letter in the envelope or your customer number on the memo portion of the money order.  It is best to send the Money Order certified mail with a return on receipt service to verify that 
                                          we have received the Money Order. Once received we will send you an invoice. </Typography>
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
        <LoadingButton loading={loading} onClick={onClick} loadingPosition='end' endIcon={<DashboardIcon/>} variant='contained' color='primary'> Go Back to Account Home</LoadingButton>    
        
        </Grid>
        
        </Grid>
        
        
        </Box>
        
        
    
    )

}
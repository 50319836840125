import * as React from 'react'
import {Route, createBrowserRouter, createRoutesFromElements, defer} from 'react-router-dom'
import { UnsecuredLayout } from '../layouts/UnsecuredLayout'
import { Main } from '../pages/Main'
import { ErrorPage } from '../pages/Error'
import { FAQ } from '../pages/FAQ'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'
import { RefundPolicy } from '../pages/RefundPolicy'
import { TermsAndConditions } from '../pages/TermsAndConditions'
import { Login } from '../pages/Login'
import { getUserData } from '../misc/auth'
import { UserAccounts } from '../pages/User/UserLanding'
import { accountLoader, adminFeedbackRequest} from './loaders'
import { UserLayout } from '../layouts/UserLayout'
import { AdminLayout } from '../layouts/AdminLayout'
import { AdminDashboard } from '../pages/Admin/Dashboard'
import { AddCustomers } from '../pages/Admin/AddCustomers'
import { CustomerCare } from '../pages/Admin/CustomerCare'
import { AdminConfirmAccounts } from '../pages/Admin/CustomerCare/ConfirmData'
import { Unsubscribe } from '../pages/Unsubscribe'
import { ConfirmAccounts } from '../pages/ConfirmAccounts'
import { UserDocuments } from '../pages/User/UserDocuments'
import { UserProfile } from '../pages/User/UserProfile'
import { AdminUserAccounts } from '../pages/Admin/CustomerCare/AdminAccounts'
import { ConfirmUserAccounts } from '../pages/User/UserConfirmAccount'
import { UserSettlement } from '../pages/User/UserSettlement'
import { AdminConfirmUserAccounts } from '../pages/Admin/CustomerCare/AdminConfirmUserAccounts'
import { AdminSettlement } from '../pages/Admin/CustomerCare/AdminSettlement'
import { AdminDocuments } from '../pages/Admin/CustomerCare/AdminDocuments'
import { UserPayment } from '../pages/User/UserPayment'
import { SendMarketingEmail } from '../pages/Admin/SendEmail'
import { ValidateEmail} from '../pages/Admin/ValidateEmail'
import { AddEmailTemplate } from '../pages/Admin/AddEmailTemplate'
import { FeedBackAdmin } from '../pages/Admin/FeedbackAdmin'
import { AddPromo } from '../pages/Admin/Promo'
import { MoneyOrder } from '../pages/User/MoneyOrder'
import { Completed } from '../pages/User/UserCompleted'
import { RecurringSetup } from '../pages/User/UserRecurring'
import { AdminPayment } from '../pages/Admin/CustomerCare/AdminPayment'
import { AdminChangePassword } from '../pages/Admin/AdminChangePassword'
import { ProcessPhysicalPayment } from '../pages/Admin/ProcessMOCheck'
import { AdminRemoveEmail } from '../pages/Admin/RemoveEmail'
import { AdminCompleted } from '../pages/Admin/CustomerCare/AdminComplete'
import { AdminMoneyOrder } from '../pages/Admin/CustomerCare/AdminMoneyOrder'
import { AdminRecurringSetup } from '../pages/Admin/CustomerCare/AdminRecurring'




export const router = createBrowserRouter(
  createRoutesFromElements(
   <React.Fragment> 
     <Route path='/' element={<UnsecuredLayout />} loader={()=>defer({userPromise: getUserData()})} errorElement={<ErrorPage />} >
        <Route index element={<Main />}  />
        <Route path='confirm' element={<ConfirmAccounts />} />
        <Route path='FAQ' element={<FAQ />} />
        <Route path='login' element={<Login />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='refund-policy' element={<RefundPolicy />} />
        <Route path='termsandconditions' element={<TermsAndConditions />} />
        <Route path='unsubscribe' element={<Unsubscribe />} />
     </Route>

     <Route path='/user' element={<UserLayout />}  errorElement={<ErrorPage />}>
       <Route path='accounts/:email' element={<UserAccounts />} loader={async ({params})=>{return await accountLoader(params.email)}} />
       <Route path='completed' element={<Completed />} />
       <Route path='confirmaccounts' element={<ConfirmUserAccounts />} />
       <Route path='documents' element={<UserDocuments />} />
       <Route path='FAQ' element={<FAQ />} />
       <Route path='moneyorder' element={<MoneyOrder />} />
       <Route path='payment' element={<UserPayment />} />
       <Route path='privacy-policy' element={<PrivacyPolicy />} />
       <Route path='profile' element={<UserProfile />} />
       <Route path='recurringsetup' element={<RecurringSetup />} />
       <Route path='refund-policy' element={<RefundPolicy />} />
       <Route path='settlement' element={<UserSettlement />} />
       <Route path='termsandconditions' element={<TermsAndConditions />} />
      



    </Route>
    <Route path='/admin' element={<AdminLayout />}  errorElement={<ErrorPage />}>
      <Route path='addpromo' element={<AddPromo />}/>
      <Route path='dashboard' element={<AdminDashboard />} />
      <Route path='addemailtemplate' element= {<AddEmailTemplate />} />
      <Route path='addcustomers' element={<AddCustomers />} />
      <Route path='changepassword' element={<AdminChangePassword />} />
      <Route path='customercare' element={<CustomerCare />} />
      <Route path='customercare/accounts/:email' element={< AdminUserAccounts />} loader={async ({params})=>{return await accountLoader(params.email)}} />
      <Route path='customercare/payment/complete' element={< AdminCompleted/>} />
      <Route path='customercare/complete' element={< AdminCompleted/>} />
      <Route path='customercare/confirm' element={<AdminConfirmAccounts />} />
      <Route path='customercare/confirmaccounts' element={<AdminConfirmUserAccounts />} />
      <Route path='customercare/documents' element={<AdminDocuments />} />
      <Route path='customercare/moneyorder' element={<AdminMoneyOrder/>} />
      <Route path='customercare/payment' element={<AdminPayment />}  />
      <Route path='customercare/recurringsetup' element={<AdminRecurringSetup />} />
      <Route path='customercare/settlement' element={<AdminSettlement />} />
      <Route path='feedback' loader={async ()=> {return await fetch(adminFeedbackRequest)}} element={<FeedBackAdmin/>}  />
      <Route path='processing' element={<ProcessPhysicalPayment />} />
      <Route path='removeemail' element={<AdminRemoveEmail />} />
      <Route path='sendmarketingemail' element={<SendMarketingEmail />} />
      <Route path='validateemail' element={<ValidateEmail />} />
    </Route>
    </React.Fragment>

   
   
   
  )  
)


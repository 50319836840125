import * as React from 'react'
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography,TextField  } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import {styled} from '@mui/material/styles'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import * as yup from 'yup'
import axios from 'axios';


const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  const validationschema = yup.object().shape({
    email: yup.string().email("Email needs to include an @ and a . for registering")
  })

export const ForgotPassword = ({open, handleClose, email}) =>{
    
    const [isLoading, setIsLoading] = React.useState(false)
    const [findErrorMessage, setFindErrorMessage] = React.useState('')
    const formik = useFormik({
        initialValues:{
            email: email
        },
        validationSchema: validationschema
        
    })

    const onClick = () =>{
        
        setIsLoading(true)
        axios({method: 'POST',
               url: 'api/oracle1/resetpassword',
               data: formik.values 
        }).then(result=>{
            
            if(result.data=='We did not find the email.'){
                setFindErrorMessage(`This email is not tied to any account that is set up, please check the spelling of the email address, use the feedback button for help, or call us at 1-866-465-6269 during business operating hours`)
                setIsLoading(false)
            }else{
                setFindErrorMessage('Your Password has been sent to the email on file')
                setIsLoading(false)
                handleClose()
            }
            
        })
    }

   

    return(
        <Box>
        <CustomDialog open={open} onClose={handleClose}>
                <DialogTitle sx={{padding: 1, mr: '3rem'}} >
                    Please Enter Your Email Associated with your Account: 
                </DialogTitle>
                <DialogContent dividers>
                    <TextField 
                    label='Email'
                    name='email'
                    type='email'
                    id='email'
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.touched.email)}
                    onChange={formik.handleChange}
                    helperText={formik.errors.email}
                    />

                </DialogContent>
                <DialogContent>
                   <Typography variant='body2' color='red' > {findErrorMessage} </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={isLoading} onClick={onClick} endIcon={<ForwardToInboxIcon />}>Send New Password</LoadingButton>
                </DialogActions>
        </CustomDialog>
        </Box>
    )


}
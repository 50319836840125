import * as React from 'react'
import { Navigate } from 'react-router-dom'
import {useFormik} from 'formik'
import * as yup from 'yup'
import { TextField, Grid, Box, Button, Typography } from '@mui/material'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import { axiosErrorHandling } from '../misc/other'
import { AdminSearchErrorFLNS } from '../alerts/AdminSearchError'
import { ErrorAlert } from '../alerts/ErrorAlert'
import FindInPageIcon from '@mui/icons-material/FindInPage';


export const FLNSearchForm = () =>{

    const validationSchema = yup.object().shape({
        first_name: yup.string().min(2, "Please enter a valid first name"),
        last_name: yup.string().min(2, "Please enter a valid last name"),
        
        })

    const [isSearching, setIsSearching] = React.useState(false)
    const [findAlert, setFindAlert] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [hasData, setHasData] = React.useState(false)
    const [data, setData] = React.useState([])
    const [rowSelected, setRowSelected] = React.useState([])
    const [gridView, setGridView] = React.useState(null)
    const [selectWarning, setSelectWarning] = React.useState('')
    

const alertClose = () =>{
    setFindAlert(false)
}

const errorAllertClose = () =>{
    setErrorAlert(false)
}

const getCustomerNumber =() =>{
    let context = JSON.parse(window.localStorage.getItem('usercontext'))
    
    if(context.admin<2){
        setSelectWarning('')
        setHasData(true)
    } else{
        setSelectWarning('* Please select an account')
    }
    

}

const columns =[
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'first_name', headerName: 'First Name', flex: 2},
        {field: 'phone_number', headerName: 'Phone', flex: 2},
        {field: 'email', headerName: 'Email', flex: 2},
        {field: 'city', headerName: 'City', flex: 2},
        {field: 'state', headerName: 'State', flex: 2},
        {field: 'zip', headerName: 'Zip', flex: 2},
        {field: 'original_creditor', headerName: 'Account', flex: 2}
]

const formik = useFormik({
    initialValues: {first_name: 'Mary', last_name: 'Davis-Martin III'},
    validationSchema: validationSchema,
    onSubmit:(values, actions) =>{
        setIsSearching(true)
        axios({method: 'POST',
               url:'api/oracle2/getlastname',
               data: values})
               .then(results=>{
                
                setData(results.data)
                if(results.data==='Last Name not found'){
                    setIsSearching(false)
                    setFindAlert(true)
                    actions.setSubmitting(false)
                }else{
                    setData(results.data)
                    setGridView(
                        <React.Fragment>
                            <Grid container direction='row '>
                                <Grid item xs={12} lg={12} xl={12} sx={{mt: 2}} >
                                    
                                        <DataGridPro
                                        density='comfortable'
                                        rows={results.data} 
                                        columns={columns}
                                        autoPageSize={false}
                                        checkboxSelection
                                        columnBufferPx={150} 
                                        hideFooter={false}
                                        slots={{toolbar: GridToolbar}}
                                        onRowSelectionModelChange={(newRow)=>{
                                        setRowSelected(newRow)
                                        }}
                                        slotProps={{toolbar:{showQuickFilter: true}}}
                                        disableMultipleRowSelection={true} 
                                        />
                                </Grid>
                            </Grid>
                            <Grid container direction='row-reverse'>
                                     <Grid item > 
                                        <Button
                                        sx={{mt: 2}}
                                        variant='contained' 
                                        endIcon={<FindInPageIcon />}
                                        onClick={getCustomerNumber}
                                        >Get Customer Number</Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>    
                                    
                                )
                    setIsSearching(false)
                    actions.setSubmitting(false)
                }


               }).catch((err)=>{
                const error_message = axiosErrorHandling(err)
                setIsSearching(false)
                setErrorMessage(error_message)
                
                setErrorAlert(true)
        
                
                 
            })
    }


})

React.useEffect(()=>{
    
  if(rowSelected.length>0){
    
    let id = rowSelected[0]
    
    let foundCustomer = data.filter(object=>{
        return object.id===id
        
    })
    
    let customerObject = foundCustomer[0]
    customerObject.admin=0
    
    window.localStorage.setItem('usercontext', JSON.stringify(customerObject))

    

  }
    
},[rowSelected, data])

if (hasData){
    return <Navigate to='/admin/customercare/confirm' />
}

return(
    <React.Fragment>
<Box component='form' onSubmit={formik.handleSubmit} >
    <Grid container direction='row' justifyContent='center' alignItems='center' sx={{mb:2}}>
      <Grid item xs={12} lg={2} >
        <TextField 
           id='first_name' 
           label='First Name' 
           type='text' 
           fullWidth variant="standard"
           value={formik.values.first_name}
           onChange={formik.handleChange} 
           error={formik.touched.first_name && Boolean(formik.errors.first_name)}
           helperText={formik.errors.first_name}/>
      </Grid>
      <Grid item xs={12} lg={2}>
        <TextField id='last_name' 
                   sx={{mr: 2, ml: 2}}
                   label='Last Name' 
                   type='text' 
                   fullWidth variant="standard" 
                   value={formik.values.last_name}
                   onChange={formik.handleChange} 
                   error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                   helperText={formik.errors.last_name}
                   />
      </Grid>
      <Grid item>
        <LoadingButton loading={isSearching}
                       loadingPosition='end'  
                       sx={{ml: 4, mt: 2}} 
                       endIcon={<SearchIcon />} 
                       variant='contained'
                       type='submit'
                       disabled={!formik.isValid}
                       onClick={formik.handleSubmit}> Search </LoadingButton>
      </Grid>
    </Grid>
    </Box>
    <Box width={1200}>
    
            {gridView}
        
    <AdminSearchErrorFLNS open={findAlert} handleClose={alertClose} />
    <ErrorAlert error={errorMessage} open={errorAlert} handleClose={errorAllertClose} />
    
</Box>
<Typography variant='body1' color='red'>{selectWarning}</Typography>
</React.Fragment>

)
}
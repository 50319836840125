import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { Typography, Box, Button, Grid, TextField, ToggleButton } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { RoutingTool } from '../utility/connections'
import { axiosErrorHandling, round } from '../misc/other'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import axios from 'axios'
import { ErrorAlert } from '../alerts/ErrorAlert'
import { SettlementLetterPDF, getProps } from '../letters/SettlementLetter'
import { PDFDate} from '../misc/dates'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'


export const PaybyCheck = ({customerInfo, paymentInfo, setBillingInfo, promoCodes, totalDue, completeLink}) =>{
    let original_message = '***If you want all documents mailed to you please click the mailbox icon below for a fee of $5.00, please check that your mailing address in the billing info is correct.'
    let fiveDollarMessage = 'Your documents will be mailed to you and a $5.00 fee has been added.'
    const [promoText, setPromoText] = React.useState(null)
    const [routingNumber, setRoutingNumber] = React.useState('')
    const [bankName, setBankName] = React.useState('')
    const [accountNumber, setAccountNumber] = React.useState('')
    const [routingValidated, setRoutingValidated] = React.useState(false)
    const [newTotalDue, setnewTotalDue] = React.useState(totalDue)
    const [paymentProcessing, setPaymentProcessing] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [mailDocs, setMailDocs] = React.useState(0)
    const [mailOption, setMailOption] = React.useState(false)
    const [mailMessage, setMailMessage] = React.useState(original_message)
    const [paidInFull, setPaidInFull] = React.useState(false)
    
    
    const file_name = customerInfo.customer_number.toString() +'_SettlementLetter_'+ PDFDate + '.pdf'

    
    var RoutingToolData = new FormData()

    let billinginfoPromos = paymentInfo.promoCodesUsed
    

    var RoutingToolData = new FormData()
    RoutingToolData.append('secret_key', RoutingTool.secretKey)
    RoutingToolData.append('public_key', RoutingTool.publicKey)

    const getFillData =(data, offerAccepted ) =>{
        let newData = []
    
        for(let i=0; i<data.length; i++){
            newData.push({
                settlement_amount:(offerAccepted*data[i].per_of_settlement),
                original_creditor: data[i].original_creditor,
                original_account_number: data[i].original_account_number,
                batch_number: data[i].batch_number,
                balance: 0
            })
        }
        return newData
    
    }

    let fillData = getFillData(paymentInfo.accounts, totalDue)

   
    const PDFfunction = async(id) =>{
        
        const PDFprops = await getProps(paymentInfo.accounts, customerInfo, Number(totalDue).toFixed(2))
        const doc =<SettlementLetterPDF {...PDFprops} />
        const asPDF = pdf([])
        asPDF.updateContainer(doc)
        const blob = await asPDF.toBlob()
        FileSaver.saveAs(blob, file_name)
    
       await axios({
            method: 'POST',
            url: 'api/oracle4/upload',
            headers: {'Content-Type': 'application/octet-stream'},
            params:{fileName: file_name,
                    customer_number: customerInfo.customer_number,
                    autogenerated: 1,
                    email: customerInfo.email},
            data: blob
            
        }).then(async (response)=>{
            
            if (response.status===200){
                 await axios({
                    method: 'POST',
                    url:'api/oracle5/confirm',
                    data: {customer_number: customerInfo.customer_number,
                           status: 'PAID', 
                           data: fillData,
                           file_name: file_name}
                
                  }).then( async result=>{
                  
                    if (result.status===200){
                        await axios({
                            method: "POST",
                            url: "api/oracle3/generateinvoice",
                            data: {data: paymentInfo.accounts,
                                   id: id, 
                                   customerInfo: customerInfo,
                                   totalDue: Number(newTotalDue).toFixed(2)
                                     }
                    
                        }).then(result=>{
                            
                            if (result.data === 'Invoice Generated'){
                                axios({
                                    method: "POST",
                                    url: "api/oracle6/postchecktransaction",
                                    data: {total: Number(newTotalDue).toFixed(2),
                                           id: id,
                                           customer_number: customerInfo.customer_number  
                                    }
                                }).then(results=>{
                                    
                                    if(results.data==='Transaction Posted'){
                                        setPaymentProcessing(false)
                                        setPaidInFull(true)

                                    } else{
                                        setPaymentProcessing(false)
                                        let newErrorMessage = axiosErrorHandling(results)
                                        setErrorMessage(newErrorMessage)
                                        setError(true)
                                    }
                                }).catch(err=>{
                                        setPaymentProcessing(false)
                                        let newErrorMessage = axiosErrorHandling(err)
                                        setErrorMessage(newErrorMessage)
                                        setError(true)

                                })

                                
                                
                                
                                
                                
                        } else{
                            let errormes='Something went wrong in the paid call update'
                            setErrorMessage(errormes)
                            setError(true)
                            setPaymentProcessing(false)
                            
                        }
                    
                    
                        }).catch(err=>{
                            let errmes = 'Here is the caught error in the paid axios call' + err
                            setPaymentProcessing(false)
                            setErrorMessage(errmes)
                            setError(true)

                        })
                      
                        
                      
                        
                    }
                  })
                  .catch(errors=>{
                    let errorMess = 'Here are the errors in the confirmation call:' + errors
                    setPaymentProcessing(false)
                    setErrorMessage(errorMess)
                    setError(true)

                })
            } else {
                let errMess = 'Here is the error in response from the upload call: '+  response.statusText
                setPaymentProcessing(false)
                setErrorMessage(errMess)
                setError(true)
            }
        }).catch(error=>{
            let errorMessage = 'Here are the errors with the axios upload call: '+ error
            setPaymentProcessing(false)
            setErrorMessage(errorMessage)
            setError(true)
        })
    
        
    
        
    
    }
    



const routingNumberCheck = (event)=> {
       
    setRoutingNumber(event.target.value.toString())
    
}

React.useLayoutEffect(()=>{
setnewTotalDue(totalDue)
},[totalDue])

React.useEffect(()=>{
        

    if(routingNumber.length === 9){
        
                   
        
        axios({
            method: 'POST',
            url: 'https://verify.routingtool.com/api/get_token',
            headers: {'Content-Type': 'multipart/form-data'},
            data: RoutingToolData

        })
        .then(response=>{
            
            let accessToken = response.data.access_token
            let dataToSend = new FormData()
            dataToSend.append('routing',routingNumber)
            axios({
                method: "POST",
                url: 'https://verify.routingtool.com/api/get_routing',
                headers: {'Content-Type': 'multipart/form-data','X-Access-Token': accessToken},
                data: dataToSend

            }).then(response=>{
               
                if (response.data.info.result_code === "WF01" || response.data.info.result_code === "WN02"){setBankName(response.data.info.bank_info.name)
                                                               setRoutingValidated(true)         }
                else{ setBankName('Check your Routing Number'); setRoutingValidated(false)} //change for production false to true
            })
        })
    }

},[routingNumber])

React.useLayoutEffect(()=>{
    if(promoCodes.length>0){
       promoCodes = promoCodes.map((item, index) =>({...item, id: index+1})) 

        setPromoText(promoCodes.map((e)=><li key={e.id}>{e.promoCode}</li>))
        
        }else{
            if(billinginfoPromos.length>0){
                billinginfoPromos = billinginfoPromos.map((item, index)=>({...item, id: index+1}))
                setPromoText(billinginfoPromos.map((e)=><li key={e.id}>{e.promoCode}</li>))
                
            }
            
        }

},[promoCodes, billinginfoPromos ])


if (isNaN(totalDue)){
    totalDue = round(paymentInfo.totalDue)
}

const accountNumberChange = (event) =>{
    setAccountNumber(event.target.value.toString())
}

const onPaybyCheckClick = async () =>{
    try{
    
    setPaymentProcessing(true)
    const phone = customerInfo.phone_number
   

    const values = {
        accountNumber: accountNumber,
        oneTimePayment: Number(newTotalDue).toFixed(2),
        fullName: customerInfo.first_name+' '+ customerInfo.last_name,
        email: customerInfo.email,
        address1: customerInfo.street_address_1,
        address2: customerInfo.street_address_2,
        city: customerInfo.city,
        state: customerInfo.state,
        zip: customerInfo.zip, 
        zip4: customerInfo.zip4,
        country: "US",
        bankName: bankName,
        routingNumber: routingNumber,
        customerNumber: customerInfo.customer_number,
                       
    }

    

    await axios({ method: "POST",
            url: 'api/oracle6/checkpayment',
            data: {values, phone_number: phone}

    }).then(async result=>{
        
        if(result.status===200 && Number(result.data.Result[0])=== 0){
            
            const newID = result.data.Check_ID[0]

    await axios({
                method: "POST",
                url: "api/oracle3/paid",
                data: {data: paymentInfo.accounts,
                       id: result.data.Check_ID[0], 
                       mail_documents: mailDocs,
                       totalPaid: Number(newTotalDue).toFixed(2),
                       paymentType: "E-Check"
                        }

            }).then(async results=>{
                
                
                if (results.data === "Payment Complete"){
                    
                    await PDFfunction(newID)
                    



                }else{
                    
                    let errorMessage = 'error in the paid axios call: ' + results.statusText
                    setErrorMessage(errorMessage)
                    throw errorMessage
                }
            })
        } else {
        let newError = 'Attempt in processing payment resulted in the following error: ' + result.data.ResultDescription[0]   + ' please check your phone number or other billing information and your checking number.  Also verify you have funds in the account.'  
        setErrorMessage(newError)
        throw newError
        }
    }).catch(err=>{
        
        let errormess = axiosErrorHandling(err)
        setErrorMessage(errormess)

        throw err

    })

} catch(err){
        
        setPaymentProcessing(false)
        setError(true)
}

}




const [billingForm, setBillingForm] = React.useState(null)


const onVerifyClick = () =>{
    if (billingForm){
        setBillingForm(null)
    } else {
        setBillingForm(<VerifyBillingForm customerInfo={customerInfo} setBillingInfo={setBillingInfo}/>)
    }
}

const changeInMail = () =>{

    let newBoolean = !mailOption
    
    setMailOption(newBoolean)
    
    setTimeout(()=>{if(newBoolean){
    
    let newamount = newTotalDue + 5
    setnewTotalDue(newamount)
    setMailMessage(fiveDollarMessage)
    setMailDocs(1)}
    else{
    setMailMessage(original_message)
     let newmount = newTotalDue-5
     setnewTotalDue(newmount)
     
     
     setMailDocs(0)
    }}, 250)
}

const errorClose = () =>{
    setError(false)
}

if(paidInFull){return <Navigate to={completeLink} state={{data: paymentInfo.accounts, mail_documents: mailDocs, promo_code_used: promoCodes, totalDue: Number(newTotalDue).toFixed(2)}} />}
return(
    <Box sx={{minHeight:'75vh', paddingTop:.05, mt: .1, mb: 2}}>
        <Grid container direction='row'>
            <Grid item>
            <Typography sx={{mb:1}}variant='body2' color='secondary'>*To add a Promo Code please click the E-Check button again</Typography>
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item>
            <Typography sx={{mb:1}}variant='body2' color='primary'>{mailMessage}</Typography>
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                   
                    <ErrorAlert error={errorMessage} open={error} handleClose={errorClose}/>
            </Grid>
            <Grid item>
                <Typography>Please fill out your checking account info</Typography> 
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: 1}} spacing={1}>
            <Grid item xs={12} sm={10} md={6} lg={6}>
                <TextField
                    fullWidth
                    type='text'
                    name='routingnumber'
                    id='routingnumber'
                    label="Routing Number"
                    inputProps={{maxLength: 9}}
                    onChange={routingNumberCheck}
                    autoFocus = {true}
                    required
                />
            </Grid>
            <Grid item xs={10} sm={8} md={6} lg={6} >     
        <TextField
        fullWidth
        type='text'
        margin='none'
        placeholder="Your Bank"
        disabled={true}
        variant='outlined' 
        id ='bankName'
        name='bankName'
        label= 'Financial Institution'
        value={bankName}
        
        />
        </Grid>
        </Grid>
        
        <Grid container direction='row'>
            <Grid item sx={{mb: 1}} xs={12}>
                <TextField
                    fullWidth
                    name='accountnumber'
                    id='accountnumber'
                    label="Account Number"
                    type='number'
                    value={accountNumber}
                    onChange={accountNumberChange}
                    required
                />
            </Grid>
        </Grid>
        
        <Grid container direction='row' justifyContent='right' sx={{mb: 1}} spacing={1}>
            <Grid item xs={4}>
                <Typography>Promo Codes used: {promoText}</Typography>
            </Grid>
            <Grid item >
                <ToggleButton 
                value='mailOption'
                selected={mailOption}
                onChange={changeInMail}
                color='primary'
               >
                <MarkunreadMailboxIcon /> 
                </ToggleButton> 
            </Grid>
            <Grid item xs={4}>
                <LoadingButton loading={paymentProcessing} loadingPosition='end' fullWidth  variant='contained' onClick={onPaybyCheckClick} endIcon={<AccountBalanceIcon />} disabled={!routingValidated}> Pay ${Number(newTotalDue).toFixed(2)} </LoadingButton>
            </Grid>
        </Grid>      
         <Grid container direction='column'>
            <Grid item xs={12}>
                <Button color='secondary' variant='outlined' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>

            {billingForm}
        </Grid>
    </Box>
    
    
)
}
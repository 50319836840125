import * as React from 'react'
import {Box,  
    Dialog, DialogContent, DialogActions, 
    DialogTitle, DialogContentText, TextField, Button} from '@mui/material'
import {styled} from '@mui/material/styles'
import axios from 'axios'

import SendIcon from '@mui/icons-material/Send'

const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

export const AdminDocumentResponse =({open, onClose})=>{

    const context = JSON.parse(window.localStorage.getItem('usercontext'))
    const admincontext = JSON.parse(window.localStorage.getItem('context'))
    
    const signature =  context.first_name +',\n' + 'Thanks,\n'+ admincontext.first_name + ' ' + admincontext.last_name + '\n' + '1-866-465-6269\n' + admincontext.email
    
   
    const [feedbackParagraphOne, setfeedbackParagraphOne] = React.useState(signature)
    
    const [userEmail, setUserEmail] = React.useState(context.email)
    
    const pageURL = window.location.pathname
    const browser = window.navigator.userAgent


   

    

    const paragraphOneChange = (event) =>{
        setfeedbackParagraphOne(event.target.value)
    }
    
    const feedbackEmailChange = (event) =>{
        setUserEmail(event.target.value)
        
    }
    
    const sendResponse = () =>{
        
       
        axios({
            method:'POST',
            url: 'api/oracle7/documentfeedback',
            data: {url: pageURL,
                   browser_info: browser,
                   paragraphOne: feedbackParagraphOne,
                   email: userEmail,
                   responseEmail: admincontext.email,
                   customer_number: context.customer_number
                   }

        })

    .then(res=>{
        console.log('Here is the response: ', res.data)
    })
    
}

return (
      
    <Box>   
    <CustomDialog open={open} onClose={onClose}>
        <DialogTitle>Enter Response to the Customer Below</DialogTitle>
        <DialogContent dividers>
            <DialogContentText sx={{color:'black'}}>
                Response to the Customer
            </DialogContentText>
                <TextField
                fullWidth
                id='First_Paragraph'
                label='Response Email'
                multiline
                maxRows={25}
                value={feedbackParagraphOne}
                onChange={paragraphOneChange}
                />
        
            <DialogContentText sx={{color:'black'}}>
                Email
            </DialogContentText>
                <TextField
                disabled
                id='email'
                label='Confirm Email Address'
                value={userEmail}
                onChange={feedbackEmailChange}
                />
            </DialogContent>
            <DialogActions>
            <Button variant='contained' color='primary' onClick={sendResponse} endIcon={<SendIcon />}>Send Response</Button>
            <Button variant='contained' color='secondary' onClick={onClose}>Cancel</Button>
            </DialogActions>
    </CustomDialog>
    </Box>



         







)




}
import * as React from 'react'
import {Grid, Typography, TextField, Collapse, IconButton, Alert, Box, Container} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import CloseIcon from '@mui/icons-material/Close'
import axios from 'axios'


export const Unsubscribe = () =>{
    
    const params = new URLSearchParams(window.location.search)

    const email = params.get('email')
    

    let initialEmail;
        if (email){
            initialEmail = email
        } else{
            initialEmail = 'name@domain.com'
        }



    const [emailToUnsubscribe, setEmailToUnsubscribe] = React.useState(initialEmail)
    const [succesfullAlert, setSuccesfullAlert] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [failedAlert, setFailedAlert] = React.useState(false)
    
    
    const emailChange = (e) =>{
        setEmailToUnsubscribe(e.target.value)
        

    }

    const onClick = () => {
        setLoading(true)
        let newemail = emailToUnsubscribe
        axios({
                method: 'POST',
                url: '/api/oracle1/unsubscribe',
                headers: {"Content-Type" : "application/json;charset=utf-8"},
                data: {email: newemail}

        })
        .then(results=>{
                
                if(results.data ==='Email Unsubscribed.'){
                    setSuccesfullAlert(true)
                    setLoading(false)
                }
                else{
                    setFailedAlert(true)
                    setLoading(false)
                }

        })
    }


    return(
        
        <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>    
        <Container>
            
        
        <Grid container direction='column' justifyContent='center' justifyItems='center' alignItems='center'>
            <Grid item xs={10} md={8} lg ={6}>
            <Collapse in={succesfullAlert}>
            <Alert
            sx={{mb: 2}}
            severity='success'
            action={<IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={()=>{
                setSuccesfullAlert(false)
            }}>
                <CloseIcon fontSize='inherit'/>
            </IconButton>
            
            }>
                You Have Unsubscribed From Our Email List You Should No Longer Receive Any More Emails From Us.
                If you would like to negotiate your account please hit the Home button in the Top Right of the Screen 
            </Alert>
         </Collapse>   
        <Collapse in={failedAlert}>
        <Alert
            sx={{mb: 2}}
            severity='error'
            action={<IconButton
            aria-label='close'
            color='inherit'
            size='small'
            onClick={()=>{
                setFailedAlert(false)
            }}>
                <CloseIcon fontSize='inherit'/>
            </IconButton>
            
            }>
                We could not Find Your Email Address or You Already have been unsubcribed Please submit a Feedback ticket below for further information.
            </Alert>
        </Collapse>
            </Grid>
            <Grid item>
            <Typography sx={{mb: 1}}>Enter Email Address to Unsubscribe: </Typography>
            </Grid>
            <Grid item>
            <TextField
            id='email'
            type='email'
            variant='outlined'
            label='Email Address To Unsubscribe'
            value={emailToUnsubscribe}
            onChange={emailChange}
            
            

            />
            </Grid>
            <Grid item>
            <LoadingButton variant='contained' 
                    loading={loading}
                    loadingPosition='end'
                    sx={{mt:1}} 
                    onClick={onClick}
                    disabled={succesfullAlert||failedAlert}
                    >Unsubscribe</LoadingButton>
            </Grid>
            
        </Grid>
        
        
        </Container>
        </Box> 
        
        
    )
    
}
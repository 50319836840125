import * as React from 'react'
import { round } from '../misc/other'
import { Dialog, TextField, DialogTitle,Button, DialogContentText, Typography, IconButton, DialogContent, DialogActions } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'   
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import axios from 'axios'





const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  




export const UserPromo = ({open, original_ammount, settotaldue, setpromocodesused, close, accounts, promoCodesUsed}) =>{
    
    
    if(promoCodesUsed.length>0){
        for(let a=0; a<promoCodesUsed.length; a++){
            promoCodesUsed.id = a+1
        }
    }
    

    const theme = useTheme()
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [promoCodeMessage, setPromoCodeMessage] = React.useState('')
    const [promoCodeSubmit, setPromoCodeSubmit] = React.useState(false)
    const [promoModuleDisplay, setPromoModuleDisplay] = React.useState(false)
    const [disabledButton, setDisabledButton] = React.useState(false)
    const [disabledText, setDisabledText] = React.useState(false)
    const [promoCode, setPromoCode] = React.useState('PROMO20')
    const [promoError, setPromoError] = React.useState(false)
    const [promoCodesArray, setpromoCodesArray] = React.useState(promoCodesUsed)
    const [newTotalDue, setNewTotalDue] = React.useState(original_ammount)


    if(isNaN(newTotalDue)){
        setNewTotalDue(accounts.totalDue)
    }
    
    
    React.useEffect(()=>{
 if(promoCodesArray.length>0){
    let newammount = original_ammount
    for(let c=0; c<promoCodesArray.length; c++){
        
        newammount -=(round((newammount*promoCodesArray[c].percentage)))
    }
    setNewTotalDue(newammount)
    settotaldue(newammount)

    setPromoModuleDisplay(true)
       

        } else {
            setPromoModuleDisplay(false)
        }
  
  
    },[promoCodesArray])

    
     

     const closeAlert = () =>{
        close()

     }


    const onPromoChange = (enteredPromo) => {
        setPromoCode(enteredPromo.target.value)
        if(enteredPromo.target.value.length<5){
            setPromoCodeMessage('Invalid Promo Code Try Again')
                            setPromoError(true)
                            setDisabledButton(true)
        }else{
            setPromoCodeMessage(null)
            setPromoError(false)
            setDisabledButton(false)
        }
        
}
    

   

    let promoCodeDisplay; 
    if(promoModuleDisplay){promoCodeDisplay = <Typography  sx={{mt: '1rem', ml: '6rem'}} variant='h6'>Promo codes used: {promoCodesArray.map((d)=>
        <li key={d.id}><b>{d.promoCode}</b>-{d.percentage*100}%</li>)} </Typography> }


    const checkPromoCode = async () => {
        setDisabledButton(true)
        setDisabledText(true)
        setPromoCodeSubmit(true)
        let indexValue = promoCodesArray.findIndex((e)=>e.promoCode === promoCode)
                
        if(indexValue>=0){
            setPromoCode('Already Used')
            setPromoError(true)
            setPromoCodeMessage('Already Used Promo Code')
            setDisabledButton(false)
            setDisabledText(false)
            setPromoCodeSubmit(false)
        }else{
                setPromoError(false)
                  await  axios({
                        method: 'POST',
                        url: 'api/oracle3/confirmpromo',
                        data: {promo_code: promoCode,
                               promoCodeArray: promoCodesArray,
                               data: accounts}
                    })
                    .
                    then(response=>{
                        if(response.data ==='Promo Code not valid.'){
                            
                            setPromoCodeMessage('Invalid Promo Code Try Again')
                            setPromoError(true)
                            setDisabledButton(false)
                            setDisabledText(false)
                            setPromoCodeSubmit(false)
                        }else{
                            
                           setPromoError(false)
                            setPromoCodeMessage('Promo Accepted, please enter another one')
                            setpromoCodesArray(response.data.promoArray)
                            setpromocodesused(response.data.promoArray)
                            settotaldue(round(response.data.newTotal))
                            setNewTotalDue(round(response.data.newTotal))
                            accounts.promoCodesUsed = response.data.promoArray
                            window.localStorage.setItem('userpromocodes', response.data.promoArraytoWrite)
                            setPromoModuleDisplay(true)
                            setDisabledButton(false)
                            setDisabledText(false)
                            setPromoCodeSubmit(false)
                            
                    
                    } 
                    })
                
            setPromoError(true)
            setDisabledButton(false)
            setDisabledText(false)
            setPromoCode(promoCode.replace(/ /g, ""))
            
    }

       
            
            
           
        }
    
    
    
    
    
        
    
    






    
    

    

   

       
       return(
        <BootstrapDialog fullScreen={fullscreen} onClose={close} open={open} aria-labelledby='form-dialog-title' >
        <DialogTitle  id='form-dialog-title' >
           Enter Promo Code
        </DialogTitle>
        <IconButton aria-label="close"
            onClick={close}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
       <DialogContent dividers sx={{justifyContent: 'center', justifyItems: 'center', justifySelf: 'center'}}>
        <DialogContentText sx={{mb: '2rem',ml: '6rem', mr: '6rem', mt: '2rem'}} color='black'>
            Please enter a Promo Code if you don't have one try PROMO20. Promo Codes are stackable. 
        </DialogContentText>
              
        <TextField
        
        sx={{mb: '2rem', ml: '6rem'}}
       color='primary'
        type='text'
        marign='none'
        variant='outlined'
        id='promo'
        name='promo'
        label='Promo Code'
        defaultValue='PROMO20'
        value={promoCode}
        onChange={onPromoChange}
        error={promoError}
        disabled={disabledText}
        helperText ={promoCodeMessage}
        />
        
        <Typography
        sx={{ml: '6rem'}}
        variant='h4'
        color='secondary'
        >Total Due: $ {Number(newTotalDue).toFixed(2)}</Typography>
       
        
            {promoCodeDisplay}
            </DialogContent>   
           <DialogActions>
    <LoadingButton
            loading={promoCodeSubmit}
            loadingPosition='end' 
            endIcon={<LocalOfferIcon />}
            variant='contained' 
            color='secondary'
            onClick={checkPromoCode}
            disabled={disabledButton}
            >
                Apply</LoadingButton>
                
                
        <Button
        
        variant='contained'
        color='primary'
        onClick={closeAlert}>Back to Payment</Button>
        </DialogActions>
                
                
    </BootstrapDialog>
        

       

)}
import * as React from 'react'
import {MuiTelInput, matchIsValidTel} from 'mui-tel-input'
import { Grid,TextField, 
        Typography, 
        InputLabel, FormControl, 
        Select, MenuItem, 
        ListSubheader, Container } from '@mui/material'
import { Navigate} from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import * as yup from 'yup'
import { AlreadySetUp } from '../alerts/AlreadySetUp'
import { useFormik } from 'formik'
import { SetPassword } from '../alerts/SetPassword'
import axios from 'axios'
import { ErrorAlert } from '../alerts/ErrorAlert'
import PublishIcon from '@mui/icons-material/Publish'
import { axiosErrorHandling } from '../misc/other'


const validationSchema = yup.object().shape({
    first_name: yup.string().min(2, "Please enter a valid first name"),
    last_name: yup.string().min(2, "Please enter a valid last name"),
    street_address_1: yup.string().min(5, 'Please enter a valid address'),
    street_address_2: yup.string().nullable().min(5, 'Please enter a valid address'),
    city: yup.string().min(3, 'Please enter a valid city name'),
    zip: yup.string().min(5,'Please enter 5 numbers').max(5, 'Please enter only 5 numbers'),
    zip4: yup.string().min(4, 'Please enter 4 numbers').max(4, "Please enter only 4 numbers"),
    })


export const ConfirmationForm = ({data}) =>{
    
    
    const confirmInfo = data

    
    
    


    const [passwordAlert, setPasswordAlert] = React.useState(false)
    const [phoneNumber, setPhoneNumber] = React.useState(confirmInfo.phone_number.toString())
    const [phoneToStore, setPhoneToStore] = React.useState(confirmInfo.phone_number)
    const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('')
    const [login, setLogin] = React.useState(false)
    const [phoneError, setPhoneError] = React.useState(false)
    const [infoToPass, setInfoToPass] = React.useState(data)
    const [setUp, setSetup] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const closeError = () =>{
        setErrorAlert(false)

    }

React.useEffect(()=>{
    if(confirmInfo.account_setup===1){
        setSetup(true)
    }

},[confirmInfo])



   let initialzip = confirmInfo.zip.replace(/\s/g, "")
   let initialzip4 = confirmInfo.zip4.replace(/\s/g, "")
   
    const stopLoading = () =>{
        setIsLoading(false)
    }

    const handlePhoneChange = (newValue, info) =>{
    setPhoneNumber(newValue)
    
    if(matchIsValidTel(info.numberValue)){
        
        setPhoneToStore(Number(info.nationalNumber))
        setPhoneError(false)
        setPhoneErrorMessage("")
    }else{
        setPhoneError(true)
        setPhoneErrorMessage('Please enter a valid phone number')
    }

}

const gotoLogin = () =>{
    setLogin(true)
}




const formik = useFormik({
    initialValues:{first_name: confirmInfo.first_name,
                   last_name: confirmInfo.last_name,
                   street_address_1: confirmInfo.street_address_1,
                   street_address_2: confirmInfo.street_address_2,
                   city: confirmInfo.city,
                   state: confirmInfo.state,
                   zip: initialzip,
                   zip4:initialzip4,
                   
                 },

                 validationSchema: validationSchema,
                 onSubmit: (values, actions) =>{
                    setIsLoading(true)  
                    axios({
                          method: 'POST',
                          url:'api/oracle1/uspsapi',
                          data:{street1: values.street_address_1,
                                street2: values.street_address_2,
                                city: values.city,
                                state: values.state,
                                zip: values.zip }
          
                      })
                      .then(result=>{
                          if(result.status===200){
                                                   
                          
                    let info = {
                        customer_number: confirmInfo.customer_number,
                        email: confirmInfo.email,
                        first_name: formik.values.first_name,
                        last_name: formik.values.last_name,
                        street_address_1: result.data.street1,
                        street_address_2: result.data.street2,
                        city: result.data.city,
                        state: result.data.state,
                        phone_number: phoneToStore,
                        zip: result.data.zip,
                        zip4: result.data.zip4, //Comes from UPS call
                        address_verified: 1
                          }
                          setInfoToPass(info)
                          actions.setSubmitting(false)
                        setPasswordAlert(true)    
                      }
                      
                      if(result.status===204){
                        let info = {
                        customer_number: confirmInfo.customer_number,
                        email: confirmInfo.email,
                        first_name: formik.values.first_name,
                        last_name: formik.values.last_name,
                        street_address_1: formik.values.street_address_1,
                        street_address_2: formik.values.street_address_2,
                        city: formik.values.city,
                        state: formik.values.state,
                        phone_number: phoneToStore,
                        zip: formik.values.zip,
                        zip4: formik.values.zip4,
                        address_verified: 0
                          }  
                          setInfoToPass(info)
                          
                      actions.setSubmitting(false)
                      setPasswordAlert(true)  }
                                                
                      })
                      .catch(errors=>{
                          actions.setSubmitting(false)
                          setIsLoading(false)
                         let errMessage = axiosErrorHandling(errors)
                         setErrorMessage(errMessage)
                         setErrorAlert(true)
                            
                        })
              
              }
             })


             if(login){
                return <Navigate to ='/login' replace={true} />
            }

             return  (
    
        <React.Fragment>
            <Container> 
                <ErrorAlert open={errorAlert} handleClose={closeError} error={errorMessage} />
            <Typography color= 'secondary' variant='h5' align='center' display='block' gutterBottom= {true} >Confirm Your Information: </Typography>
            </Container>
            
                <Container component='form' onSubmit={formik.handleSubmit}>
                    
                   <Grid container spacing={2} direction='row' justifyContent='center'  sx={{mb: '2rem'}}>   
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                    <TextField
                    fullWidth
                    type='text'
                    margin='none'
                    variant='outlined' 
                    id ='first_name'
                    name='first_name'
                    label= 'First Name'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.errors.first_name}
                    />
                    </Grid> 
                    <Grid item xs={12} sm={6} md={3} lg={3} >     
                    <TextField
                    fullWidth
                    type='text'
                    margin='none'
                    variant='outlined' 
                    id ='last_name'
                    name='last_name'
                    label= 'Last Name'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.errors.last_name}
                    />
                    </Grid>
                    </Grid>
                    <Grid spacing={2} container direction='row' justifyContent='center' sx={{mb: '2rem'}}>
                    <Grid item  xs={12} sm={12} md ={6} lg={6}>   
                    <TextField
                    type='text'
                    margin='none'
                    fullWidth
                    variant='outlined'
                    id ='street_address_1'
                    name='street_address_1'
                    label= 'Street Address Line 1'
                    value={formik.values.street_address_1}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_1 && Boolean(formik.errors.street_address_1)}
                    helperText={formik.errors.street_address_1}
                    /> 
                    </Grid>    
                    </Grid>
                    <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '2rem'}}>
                    <Grid item xs={12} sm={12} md ={6} lg={6}>   
                    <TextField
                    type='text'
                    margin='none'
                    fullWidth
                    variant='outlined'
                    id ='street_address_2'
                    name='street_address_2'
                    label= 'Street Address Line 2'
                    value={formik.values.street_address_2}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_2 && Boolean(formik.errors.street_address_2)}
                    helperText={formik.errors.street_address_2}
                    /> 
                    </Grid>   
                    </Grid>
                    <Grid container direction='row' justifyContent='center' spacing={2} sx={{mb: '2rem'}}>
                     <Grid item xs={8} sm={12} md={2} lg={2}>      
                    <TextField
                    fullWidth
                    margin='none'
                    variant='outlined' 
                    id ='city'
                    name='city'
                    label= 'City'
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.errors.city}
                    />
                    </Grid>
                    <Grid item xs={4} sm={3} md={1} lg={1}>    
                    <FormControl 
                    variant='outlined' 
                    fullWidth
                    >
                    <InputLabel id='state-label'>State</InputLabel>
                    <Select
                    label='state-label'
                    id='state'
                    defaultValue={formik.values.state}
                    value={formik.values.state}
                    onChange={formik.handleChange}
                                       >
                <ListSubheader>States</ListSubheader>        
                <MenuItem value="AL">AL</MenuItem>
                <MenuItem value="AK">AK</MenuItem>
                <MenuItem value="AR">AR</MenuItem>	
                <MenuItem value="AZ">AZ</MenuItem>
                <MenuItem value="CA">CA</MenuItem>
                <MenuItem value="CO">CO</MenuItem>
                <MenuItem value="CT">CT</MenuItem>
                <MenuItem value="DC">DC</MenuItem>
                <MenuItem value="DE">DE</MenuItem>
                <MenuItem value="FL">FL</MenuItem>
                <MenuItem value="GA">GA</MenuItem>
                <MenuItem value="HI">HI</MenuItem>
                <MenuItem value="IA">IA</MenuItem>	
                <MenuItem value="ID">ID</MenuItem>
                <MenuItem value="IL">IL</MenuItem>
                <MenuItem value="IN">IN</MenuItem>
                <MenuItem value="KS">KS</MenuItem>
                <MenuItem value="KY">KY</MenuItem>
                <MenuItem value="LA">LA</MenuItem>
                <MenuItem value="MA">MA</MenuItem>
                <MenuItem value="MD">MD</MenuItem>
                <MenuItem value="ME">ME</MenuItem>
                <MenuItem value="MI">MI</MenuItem>
                <MenuItem value="MN">MN</MenuItem>
                <MenuItem value="MO">MO</MenuItem>	
                <MenuItem value="MS">MS</MenuItem>
                <MenuItem value="MT">MT</MenuItem>
                <MenuItem value="NC">NC</MenuItem>	
                <MenuItem value="NE">NE</MenuItem>
                <MenuItem value="NH">NH</MenuItem>
                <MenuItem value="NJ">NJ</MenuItem>
                <MenuItem value="NM">NM</MenuItem>			
                <MenuItem value="NV">NV</MenuItem>
                <MenuItem value="NY">NY</MenuItem>
                <MenuItem value="ND">ND</MenuItem>
                <MenuItem value="OH">OH</MenuItem>
                <MenuItem value="OK">OK</MenuItem>
                <MenuItem value="OR">OR</MenuItem>
                <MenuItem value="PA">PA</MenuItem>
                <MenuItem value="RI">RI</MenuItem>
                <MenuItem value="SC">SC</MenuItem>
                <MenuItem value="SD">SD</MenuItem>
                <MenuItem value="TN">TN</MenuItem>
                <MenuItem value="TX">TX</MenuItem>
                <MenuItem value="UT">UT</MenuItem>
                <MenuItem value="VT">VT</MenuItem>
                <MenuItem value="VA">VA</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
                <MenuItem value="WI">WI</MenuItem>	
                <MenuItem value="WV">WV</MenuItem>
                <MenuItem value="WY">WY</MenuItem>
                <ListSubheader>Outlying Territories</ListSubheader>  
                <MenuItem value="AS">AS</MenuItem>
                <MenuItem value="GU">GU</MenuItem>
                <MenuItem value="MP">MP</MenuItem>
                <MenuItem value="PR">PR</MenuItem>
                <MenuItem value="UM">UM</MenuItem>
                <MenuItem value="VI">VI</MenuItem>
                <ListSubheader>Armed Forces</ListSubheader> 
                <MenuItem value="APO-AA">AA</MenuItem>
                <MenuItem value="APO-AP">AP</MenuItem>
                <MenuItem value="APO-AE">AE</MenuItem>
                <MenuItem value="FPO-AA">AA</MenuItem>
                <MenuItem value="FPO-AP">AP</MenuItem>
                <MenuItem value="FPO-AE">AE</MenuItem>		   
                    </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={8} sm ={6} md={2} lg={2}>
                    <TextField
                    fullWidth
                    margin='none'
                    variant='outlined'
                    type='phone' 
                    id ='zip'
                    name='zip'
                    label= 'Zip'
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    error={formik.touched.zip && Boolean(formik.errors.zip)}
                    helperText={formik.errors.zip}
                    />
                    </Grid>
                    <Grid item xs={4} sm ={3} md={1} lg={1}> 
                    <TextField
                    margin='none'
                    variant='outlined'
                    type='phone' 
                    id ='zip4'
                    name='zip4'
                    label= '+4'
                    value={formik.values.zip4}
                    onChange={formik.handleChange}
                    error={formik.touched.zip4 && Boolean(formik.errors.zip4)}
                    helperText={formik.errors.zip4}
                    />
                    </Grid> 
                    </Grid>
                    <Grid container direction='row' justifyContent='center' sx={{mb: '2rem'}} spacing={2}>
                        <Grid item xs={12} sm={12} md ={6} lg={6}>
                        <MuiTelInput
                         fullWidth                    
                        variant='outlined'
                        defaultCountry='US'
                        forceCallingCode={true}
                        disableDropdown={true}
                        onlyCountries={['US']}
                        value={phoneNumber}
                        label='Phone Number'
                        id='phone'
                        name='phone'
                        type='phone'
                        onChange={handlePhoneChange}
                        error={phoneError}
                        helperText={phoneErrorMessage}/>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' justifyContent='center' alignItems='center' sx={{mb: '2rem'}} spacing={2}>
                        <Grid item xs={12} sm={12} md ={6} lg={6}>
                        <LoadingButton
                        loading={isLoading}
                        loadingPosition='end'
                        fullWidth
                        variant='contained'
                        color='secondary'
                        endIcon={<PublishIcon />
                    }
                        disabled ={isLoading||!formik.isValid||phoneError} 
                        type='submit'
                        >Confirm</LoadingButton>            
                        </Grid>
                    </Grid>
                    </Container>
                    <Grid container>
                       <Grid item xs={12} sm={12} md={8} lg={6} sx={{width: '150%'}}> 
                     <SetPassword open={passwordAlert} accounts={infoToPass} stopLoading={stopLoading} setOpen={setPasswordAlert} setErrorAlert={setErrorAlert} setErrorMessage={setErrorMessage}/>
                     </Grid>
                     <Grid item>
                     <AlreadySetUp open={setUp} handleClose={gotoLogin}/>
                     </Grid>
                    </Grid>   
                    </React.Fragment>


)}
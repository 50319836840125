import * as React from 'react'
import { Box, Typography } from '@mui/material'


export const ProcessPhysicalPayment = ()=>{

    return(
        <Box sx={{minHeight: '80vh', mt: 14, mb: 2}}>
        <Typography>You have hit the outside process fee.</Typography>
        </Box>
    )
}

export const xGridLicense = process.env.REACT_APP_XGRIDLICENSE

export const round =(num) =>{
    var m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);

}

export const GetRecapchaSiteKey = (pathname) =>{
    if(pathname==='http://127.0.0.1'){
        return "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    } else if(pathname==='https://www.namemysettlement.com'){
        return process.env.REACT_APP_RECAPTCHAKEY
    }
    
    else{
        return process.env.REACT_APP_RECAPTCHAKEY_STAGE
    }

}

export const axiosErrorHandling =({response}) => {
    let error_message = 'data: '+ response.data + ', status: ' + response.status + ', response.headers: ' + response.headers   

return error_message



}

export const setData = (data) =>{
    for(let i=0; i<data.length;i++){
            data[i].id=i+1     
        if (data[i].account_balance === null ){data[i].account_balance = data[i].principal}
        if (data[i].account_status === null){data[i].account_status = 'Ready to Settle'}
        if (data[i].promo_code_used === null){data[i].promo_code_used = [{}]} 
   
    }
    return data
}



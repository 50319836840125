import * as React from 'react'
import {Box, Container, Fab, Grid, 
    Dialog, DialogContent, DialogActions, 
    DialogTitle, DialogContentText, TextField, Button, Collapse,
    Alert, IconButton} from '@mui/material'
import axios from 'axios'
import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';



export const FeedbackButton =()=>{

    const context = JSON.parse(window.localStorage.getItem('context'))
    
    

    const [openFeedback, setOpenFeedback] = React.useState(false)
    const [feedbackParagraphOne, setfeedbackParagraphOne] = React.useState('')
    const [feedbackParagraphTwo, setfeedbackParagraphTwo] = React.useState('')
    const [feedbackParagraphThree, setfeedbackParagraphThree] = React.useState('')
    const [userEmail, setUserEmail] = React.useState('name@domain.com')
    const [alertSuccess, setAlertSuccess] = React.useState(false)
    const [alertFailed, setAlertFailed] = React.useState(false)
    const pageURL = window.location.pathname
    const browser = window.navigator.userAgent


    const feedbackClick = () =>{
        setOpenFeedback(true)
    }

    const feedbackClose = () =>{
        setOpenFeedback(false)
    }

    const paragraphOneChange = (event) =>{
        setfeedbackParagraphOne(event.target.value)
    }
    const paragraphTwoChange = (event) =>{
        setfeedbackParagraphTwo(event.target.value)
    }

    const paragraphThreeChange = (event) =>{
        setfeedbackParagraphThree(event.target.value)
    }
    const feedbackEmailChange = (event) =>{
        setUserEmail(event.target.value)
        
    }
    
    const submitFeedback = () =>{
        
       
        axios({
            method:'POST',
            url: 'api/oracle1/feedback',
            data: {url: pageURL,
                   browser_info: browser,
                   paragraphOne: feedbackParagraphOne,
                   paragraphTwo: feedbackParagraphTwo,
                   paragraphThree: feedbackParagraphThree,
                   email: userEmail,
                   customer_number: context.customer_number
                   }

        })

    .then(res=>{
        if(res.status===200){
            setAlertSuccess(true)
            setOpenFeedback(false)
            
        }else(
            setAlertFailed(true)
            
        )
    })
    
}

return (
      
    <Container>
    <Box sx={{ mt: 2, mb: 2}} >
    <Grid container direction='row' justifyContent='center'> 
                <Grid item xs={8} sm={8} md={6} lg={6} xl={6}>
        <Collapse in={alertSuccess}>
            <Alert
            severity='success'
            variant='outlined'
            action={
                <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={()=>setAlertSuccess(false)}>
                    <CloseIcon fontSize='inherit'/>
                </IconButton>
            }
             sx={{mb:2, mt:2}}
            >
                Feedback Submitted Successfully
            </Alert>
        
        </Collapse>
        <Collapse in={alertFailed}>
            <Alert
            severity='error'
            variant='outlined'
            action={
                <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={()=>setAlertFailed(false)}>
                    <CloseIcon fontSize='inherit'/>
                </IconButton>
            }
             sx={{mb:2}}
            >
                There was an error in submitting your feedback, please copy and paste your form and send email to feedback@namemysettlement.com.
            </Alert>
        
        </Collapse>
        </Grid>
        </Grid>
        </Box>
    <Grid container spacing={2} direction='column' justifyContent='flex-start' alignItems='center'>
         <Grid item>
     <Fab variant= 'extended' size='large' color='secondary' sx={{mb: 4}} onClick={feedbackClick}>
    <FeedbackIcon />
    Feedback/Suggestions/Help
    </Fab>
    </Grid>
     </Grid>
    <Dialog open={openFeedback} onClose={feedbackClose}>
        <DialogTitle>Enter Feedback/Suggestion Below</DialogTitle>
        <DialogContent>
            <DialogContentText sx={{color:'black'}}>
                What did you expect or would like the website to do? 
            </DialogContentText>
                <TextField
                fullWidth
                id='First_Paragraph'
                label='Expectations'
                multiline
                maxRows={5}
                value={feedbackParagraphOne}
                onChange={paragraphOneChange}
                />
        <DialogContentText sx={{color:'black'}}>
                What were you trying to do? 
            </DialogContentText>
                <TextField
                fullWidth
                id='Second_Paragraph'
                label='What Happened'
                multiline
                maxRows={5}
                value={feedbackParagraphTwo}
                onChange={paragraphTwoChange}
                />
                <DialogContentText sx={{color:'black'}}>
                General Comments or Concerns
            </DialogContentText>
                <TextField
                fullWidth
                id='Third_Paragraph'
                label='Help Needed'
                multiline
                maxRows={5}
                value={feedbackParagraphThree}
                onChange={paragraphThreeChange}
                />
                  <DialogContentText sx={{color:'black'}}>
                Email
            </DialogContentText>
                <TextField
                id='email'
                label='Confirm Email Address'
                value={userEmail}
                onChange={feedbackEmailChange}
                />
            </DialogContent>
            <DialogActions>
            <Button variant='contained' color='primary' onClick={submitFeedback}>Submit</Button>
            <Button variant='contained' color='secondary' onClick={feedbackClose}>Cancel</Button>
            </DialogActions>
    </Dialog>
    </Container>



         







)




}
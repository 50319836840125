import * as React from 'react'
import { Typography, Box, Button, Grid, ToggleButton } from '@mui/material'
import {axiosErrorHandling, round } from '../misc/other'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox'
import {useScript} from '@uidotdev/usehooks'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import PaymentIcon from '@mui/icons-material/Payment'
import { PDFDate } from '../misc/dates'
import { ErrorAlert } from '../alerts/ErrorAlert'
import RefreshIcon from '@mui/icons-material/Refresh'
import { SettlementLetterPDF, getProps } from '../letters/SettlementLetter'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver'



export const PaybyCC = ({customerInfo, paymentInfo, totalDue, promoCodes, completeLink}) =>{


    
let roundedTotalDue = round(totalDue)
let original_message = '***If you want all documents mailed to you please click the mailbox icon below for a fee of $5.00, please check that your mailing address in the billing info is correct.'
let fiveDollarMessage = 'Your documents will be mailed to you and a $5.00 fee has been added.'

const [promoText, setPromoText] = React.useState(null)
const [billingForm, setBillingForm] = React.useState(null)
const [cardProcessing, setCardProcessing] = React.useState(false)
const [error, setError] = React.useState(false)
const [errorMessage, setErrorMessage] = React.useState('')
const [newTotalDue, setnewTotalDue] = React.useState(roundedTotalDue)
const [mailDocs, setMailDocs] = React.useState(0)
const [mailOption, setMailOption] = React.useState(false)
const [mailMessage, setMailMessage] = React.useState(original_message)
const [paidInFull, setPaidInFull] = React.useState(false)
const [paymentButton, setPaymentButton] = React.useState(true)
const [finalPaymentKey, setFinalPaymentKey] = React.useState({})
const [messageValidation, setMessageValidation] = React.useState('')

const file_name = customerInfo.customer_number.toString() +'_SettlementLetter_'+ PDFDate + '.pdf'

let billinginfoPromos = paymentInfo.promoCodesUsed

const getFillData =(data, offerAccepted ) =>{
    let newData = []

    for(let i=0; i<data.length; i++){
        newData.push({
            settlement_amount:(offerAccepted*data[i].per_of_settlement),
            original_creditor: data[i].original_creditor,
            original_account_number: data[i].original_account_number,
            batch_number: data[i].batch_number,
            balance: 0
        })
    }
    return newData

}

React.useLayoutEffect(()=>{
    

    if(promoCodes.length>0){
       promoCodes = promoCodes.map((item, index) =>({...item, id: index+1})) 

        setPromoText(promoCodes.map((e)=><li key={e.id}>{e.promoCode}</li>))
       
        }else{
            if(billinginfoPromos.length>0){
                billinginfoPromos = billinginfoPromos.map((item, index)=>({...item, id: index+1}))
                setPromoText(billinginfoPromos.map((e)=><li key={e.id}>{e.promoCode}</li>))
                
            }
            
        }

},[promoCodes, billinginfoPromos ])

React.useLayoutEffect(()=>{
    

},[paidInFull])

let fillData = getFillData(paymentInfo.accounts, totalDue)


const PDFfunction = async(id) =>{
    
    const PDFprops = await getProps(paymentInfo.accounts, customerInfo, Number(totalDue).toFixed(2))
    const doc =<SettlementLetterPDF {...PDFprops} />
    const asPDF = pdf([])
    asPDF.updateContainer(doc)
    const blob = await asPDF.toBlob()
    FileSaver.saveAs(blob, file_name)

   await axios({
        method: 'POST',
        url: 'api/oracle4/upload',
        headers: {'Content-Type': 'application/octet-stream'},
        params:{fileName: file_name,
                customer_number: customerInfo.customer_number,
                autogenerated: 1,
                email: customerInfo.email},
        data: blob
        
    }).then(async (response)=>{
       
        if (response.status===200){
             await axios({
                method: 'POST',
                url:'api/oracle5/confirm',
                data: {customer_number: customerInfo.customer_number,
                       status: 'PAID', 
                       data: fillData,
                       file_name: file_name}
            
              }).then( async result=>{
               
                if (result.status===200){
                    await axios({
                        method: "POST",
                        url: "api/oracle3/generateinvoice",
                        data: {data: paymentInfo.accounts,
                               id: id, 
                               customerInfo: customerInfo,
                               totalDue: Number(newTotalDue).toFixed(2)
                                 }
                
                    }).then(async result=>{
                        
                        if (result.data === 'Invoice Generated'){
                           await axios({
                                method: "POST",
                                url: "api/oracle6/postcardtransaction",
                                data: {total: Number(newTotalDue).toFixed(2),
                                       id: id,
                                       customer_number: customerInfo.customer_number  
                                }
                            }).then(results=>{
                               
                                if(results.data==='Transaction Posted'){
                                    setCardProcessing(false)
                                    setPaidInFull(true)

                                } else{
                                    setCardProcessing(false)
                                    let newErrorMessage = axiosErrorHandling(results)
                                    setErrorMessage(newErrorMessage)
                                    setError(true)
                                }
                            }).catch(err=>{
                                    setCardProcessing(false)
                                    let newErrorMessage = axiosErrorHandling(err)
                                    setErrorMessage(newErrorMessage)
                                    setError(true)

                            })

                            
                            
                            
                            
                            
                    } else{
                        let errormes='Something went wrong in the paid call update'
                        setErrorMessage(errormes)
                        setError(true)
                        setCardProcessing(false)
                        
                    }
                
                
                    }).catch(err=>{
                        let errmes = 'Here is the caught error in the paid axios call' + err
                        setCardProcessing(false)
                        setErrorMessage(errmes)
                        setError(true)

                    })
                  
                    
                  
                    
                }
              })
              .catch(errors=>{
                let errorMess = 'Here are the errors in the confirmation call:' + errors
                setCardProcessing(false)
                setErrorMessage(errorMess)
                setError(true)

            })
        } else {
            let errMess = 'Here is the error in response from the upload call: '+  response.statusText
            setCardProcessing(false)
            setErrorMessage(errMess)
            setError(true)
        }
    }).catch(error=>{
        let errorMessage = 'Here are the errors with the axios upload call: '+ error
        setCardProcessing(false)
        setErrorMessage(errorMessage)
        setError(true)
    })

    

    

}




const closeError = ()=>{
    setError(false)
}

const backToPaymentClick = ()=>{

   return window.location.reload(false)

}




const status = useScript(process.env.REACT_APP_USAEPAY,
    {removeOnUnmount: true}
)

const billing_address = {
    "firstname": customerInfo.first_name,
    "lastname" : customerInfo.last_name,
    "street" : customerInfo.street_address_1,
    "street2": customerInfo.street_address_2,
    "city": customerInfo.city,
    "state": customerInfo.state,
    "postalcode": customerInfo.zip,
    "country": "USA",
    "phone": customerInfo.phone_number,
    "email": customerInfo.email
}

const isReady = status === "ready"

if (isReady){

let client = new window.usaepay.Client(process.env.REACT_APP_CCKEY)

let paymentCard = client.createPaymentCardEntry();

let CCstyles = {
         'container': {
             'background-color': '#f1f1f1',
             'margin': '5px',
             'border': '2px  #f1f1f1',
             'border-radius': '5px'
         },
         'base': {
             'font-family': 'Roboto',
             'color': 'black',
             'font-size': '16px',
             'height': '75px',
             'border': '2px black',
             'border-radius': '5px'
         },
         'wrapper':{
            'border': '2px  #f1f1f1',
             'border-radius': '5px'
         },
         'label':{
            'font-size': '12px',
            'border': '2px  #f1f1f1',
             'border-radius': '5px'
         }
         
         
 }

 let ccLabels = {
     cnum: 'Enter Card Number',
     exp: 'MM/YY',
     cvv: 'CVV'
 }

 const paymentCardConfig = {
     styles: CCstyles,
     display_labels: true,
     labels: ccLabels,
     label_position: 'top',
     mask_ccnum: true,
     display_errors: true

 }

 paymentCard.generateHTML(paymentCardConfig)
 paymentCard.addHTML('paymentCardContainer')
 
 paymentCard.addEventListener('error', err => {
    let errorContainer = document.getElementById('paymentCardErrorContainer')
    errorContainer.textContent = err.message
     
 });



 let form = document.getElementById('paymentForm')
 form.addEventListener('submit', event =>{
    event.preventDefault()
    
    client.getPaymentKey(paymentCard).then(result=>{
        
         if(result.error){
            
             let errorContainer = document.getElementById('paymentCardErrorContainer');
             errorContainer.textContent(result.error.message)
             
         }            
         else{
            
            setFinalPaymentKey(result.key)
            setPaymentButton(false) 
            setMessageValidation('Card Validated, Do not enter a second card please hit the submit button')
            
            
            
         }

 }).catch(err=>{
    
     let errMessage = axiosErrorHandling(err)
     setErrorMessage(errMessage)
    setError(true)
 })
 },{once: true})

 
}

const paymentClick = async () =>{
      setCardProcessing(true)   
    
    await axios({
            method: 'POST',
            url: 'api/oracle6/cardpayment',
            data: { payment_key: finalPaymentKey,
                amount: newTotalDue,
                billing_address: billing_address,                
                }
        }).then( async result=>{
            
            if(result.data.message==='Payment Processed'){
                
                let id = result.data.refnum

                await axios({
                    method: "POST",
                    url: "api/oracle3/paid",
                    data: {data: paymentInfo.accounts,
                           id: result.data.refnum, 
                           mail_documents: mailDocs,
                           totalPaid: Number(newTotalDue).toFixed(2),
                           paymentType: "Card"
                            }
    
                }).then(async results=>{
                    
                    if (results.data === "Payment Complete"){
                        
                        await PDFfunction(id)
                        
                    }else{
                        
                        let errorMessage = 'error in the paid axios call: ' + results.statusText
                        setErrorMessage(errorMessage)
                        throw errorMessage
                    }
                }).catch(err=>{
                   let errMessage = axiosErrorHandling(err)
                    setErrorMessage(errMessage)
                    setCardProcessing(false)
                    setError(true)
                })


            }else{

                let errMessage = 'There was a error in the payment process'
                setErrorMessage(errMessage)
                setCardProcessing(false)
                setError(true)
            }
        })

    
}

const changeInMail = () =>{

    let newBoolean = !mailOption
    
    setMailOption(newBoolean)
    
    setTimeout(()=>{if(newBoolean){
    
    let newamount = newTotalDue + 5
    setnewTotalDue(newamount)
    setMailMessage(fiveDollarMessage)
    setMailDocs(1)}
    else{
    setMailMessage(original_message)
     let newmount = newTotalDue-5
     setnewTotalDue(newmount)
     
     
     setMailDocs(0)
    }}, 250)
}


const labelStyle = {
    fontFamily: 'Roboto',
    marginBottom: '10px',
    color: '#C31200'
 }



const onVerifyClick = () =>{
    if (billingForm){
        setBillingForm(null)
    } else {
        setBillingForm(<VerifyBillingForm customerInfo={customerInfo}/>)
    }
}

if(paidInFull){

    window.location.assign(completeLink)
    
    }

return(
    


     <Box sx={{minHeight:'75vh', mt: 2, mb: 2}}>
          
     <ErrorAlert open={error} error={errorMessage} handleClose={closeError}/>
         <form id='paymentForm' action='/charge' method='post'>
            <label htmlFor='paymentCardContainer' style={labelStyle} >Visa or MasterCard Debit/Credit Card Only</label>
                <div id='paymentCardContainer'/>
                <div>
                    <button type='submit' id='validateCard' disabled={!paymentButton}>Validate Card</button><Typography color='secondary' sx={{ml: 1}}>{messageValidation}</Typography>
                </div>
                <div id='paymentCardErrorContainer'role='alert' />
                <br>
                </br>
                <div>
                    <Typography sx={{mb:1}}variant='body2' color='primary'>{mailMessage}</Typography>
                </div>
                    <ToggleButton
                         sx={{mr: 2}} 
                        value='mailOption'
                        selected={mailOption}
                        onChange={changeInMail}
                        color='primary'
                    >
                        <MarkunreadMailboxIcon /> 
                    </ToggleButton>
                    <LoadingButton disabled={paymentButton} loading={cardProcessing} sx={{mr: 2}}id='paymentbutton' color='primary' variant='contained'endIcon={<PaymentIcon />} onClick={paymentClick}>Submit Payment ${newTotalDue}</LoadingButton>
                    <Button id='backtopaymentbutton' color='secondary' onClick={backToPaymentClick} endIcon={<RefreshIcon />}>Back to Select Payment</Button>
        </form> 
        
        <Grid container direction='column'sx={{mt: 2}} alignItems='center'>
        <Grid item xs={4} sx={{alignItems: 'center'}} >
                <Typography>Promo Codes used: {promoText}</Typography>
        </Grid>
        </Grid>
        <Grid container direction='column' sx={{mt: 2}}>
            <Grid item xs={12}>
                 <Button variant='outlined' color='secondary' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
        
    </Box>
    
    )
}


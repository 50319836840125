import * as React from 'react'
import { useRouteError } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Typography, Grid, Container, Box, Button } from "@mui/material";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Container>
      <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
     <Grid container justifyContent='center' justifyItems='center'> 
     <Grid item>
      <Typography variant='h1' color='secondary'>Oops</Typography>
     <Grid item> 
      <Typography variant='body1' color='black'>Sorry, an unexpected error has occurred.</Typography>
      <br></br>
        <Typography variant='body1' color='primary' sx={{mt:1, mb:2}}>{  error.status +" - " + error.statusText +" : " + error.message}</Typography>
    </Grid>
    <Grid item>
      <Button variant='contained' size='large' component={Link} to='/' >Go Back to Home</Button>
    </Grid>
    </Grid>
    </Grid>
    </Box>
    </Container>
  );
}

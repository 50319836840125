import * as React from 'react'
import { Link} from 'react-router-dom'
import { Grid, Typography, Box, Button} from '@mui/material'

import HomeIcon from '@mui/icons-material/Home'



export const Completed = ()=>{


const context = JSON.parse(window.localStorage.getItem('context'))
const path =  '/user/accounts/' + context.email   












    return(
       
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
           
        <Grid container direction='row' justifyContent='center' alignItems ='center'>
        <Grid item xs={7} sm={6} md={6} lg={4}>
        <Typography variant='h6'gutterBottom={true}>Payment Processed...Check your download folder for the settlement letter.  Keep the letter on file for future reference. If any other entity requests to settle the debt please show them this letter. Also NameMySettlement.com will be sending you a receipt by the end of the next business day </Typography>
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
        <Button endIcon={<HomeIcon />} component={Link} to={path}  variant='contained' color='primary'> Go Back To Account Home </Button>
        
        </Grid>
        </Grid>
        
        </Box>
        
        
    )

}
import * as React from 'react'
import { LinearProgress, Dialog, DialogTitle, DialogActions, IconButton, DialogContent } from '@mui/material'
import {styled} from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close' 

const CustomDialog = styled(Dialog)(({theme})=>({
  '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },

}));


export const CustomerUploadProgress=({open, totalTime, close}) => {
  
  
  
  const [progress, setProgress] = React.useState(0);
  
  if(progress===100){
    close()
  }



  


  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        
        if (oldProgress === 100) {
          return close();
        }
        const diff = (((oldProgress*totalTime) + .01)/totalTime)*100
        
        return Math.min(oldProgress + diff, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);


  return (
    
    <CustomDialog fullWidth open={open} onClose={close}>
      <DialogTitle>
        Customers Uploading
        <IconButton aria-label="close"
            onClick={close}
            sx={{
              ml: 2, 
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
              <CloseIcon />
            </IconButton>
      </DialogTitle>
      <DialogContent dividers>
      <LinearProgress sx={{mt: 2, mb: 2}} color='primary' variant="determinate" value={progress} />
      </DialogContent>      
    </CustomDialog>

  );
}
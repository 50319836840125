import * as React from 'react'
import {Outlet, Navigate} from 'react-router-dom'
import { AdminHeader } from './headers/Headers'
import { AdminFooter } from './footers/Footers'
import { AuthProvider } from '../misc/auth'


export const AdminLayout =()=>{

const userInfo =  window.localStorage.getItem('user')
const baseURL = window.localStorage.getItem('BASE_URL')

const user = JSON.parse(userInfo)


   



const email = user.email




const pagesList = [
                {label: 'Dashboard', path: '/admin/dashboard'},
                {label: 'Add Customers', path: '/admin/addcustomers'},
                {label: 'Add Email Template', path: '/admin/addemailtemplate'},
                {label: 'Send Marketing Email', path: '/admin/sendmarketingemail'},
                {label: 'Add Promo Code', path: '/admin/addpromo'},
                {label: 'Feedback', path: '/admin/feedback'},
                {label: 'Send Out Marketing Email', path: '/admin/sendmarketingemail'},
                {label: 'Validate Email', path: '/admin/validateemail'}
]

if (!user) {
    return <Navigate to='/login' replace={true} />
}

return(
    <>
    
        <AuthProvider userData={user}>
            <AdminHeader pages={pagesList} email={email} />
                    <Outlet />
                <AdminFooter baseURL={baseURL}  />
    </AuthProvider>
    
    </>

)

}


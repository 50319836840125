import * as React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { AdminPromoForm } from '../../forms/AdminPromo'


export const AddPromo =()=> {

return(
    
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
    <Grid container direction='row' justifyContent='center'>
        <Grid>
<Typography color='primary' variant='h5' gutterBottom>Enter New Promo Code Below:</Typography>
        </Grid> 
    </Grid>
    <AdminPromoForm/>
    </Box>
    
    

)


}

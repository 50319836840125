import * as React from 'react'
import { Link as BaseLink} from 'react-router-dom'
import {Grid, Link, Typography, Container, Box, Hidden} from '@mui/material'
import { dateFormatted } from '../../misc/dates'





export const MainFooter = () =>{

return(
    <footer style={{backgroundColor: '#565656', width: '100%', height: 'auto', minHeight: 'auto', bottom: 0, left:0, margin:0,}}>
 
<Container maxWidth='xl'>
    <Box backgroundColor='#565656' >        
     <Grid container direction='row' justifyContent='space-evenly'>
       <Grid item xs={12} sm={10} lg={4}> 
        <Grid container direction='column'justifyContent='space-evenly'>
            <Grid item>
                <Typography variant='body1' align='center'  color='white'><strong><u>Other Links</u></strong> </Typography>
            </Grid>
            <Grid item textAlign='center' >
                 <Link key={'/FAQ'} component={BaseLink} to={'/FAQ'} variant='body1'color='white' underline='hover'>Frequently Asked Questions</Link>
            </Grid>
            <Grid item textAlign='center'>
                <Link key={'/privacy-policy'} align='center' component={BaseLink} to={'/privacy-policy'} variant='body1'color='white' underline='hover'>Privacy Policy</Link>
            </Grid>
            <Grid item textAlign='center'>
                <Link key={'/termsandconditions'} align='center' component={BaseLink} to={'/termsandconditions'} variant='body1'color='white' underline='hover'>Terms and Conditions</Link>
            </Grid>
            <Grid item textAlign='center'>
                <Link key={'/refund-policy'} align='center' component={BaseLink} to={'/refund-policy'} variant='body1'color='white' underline='hover'>Refund Policy</Link>
            </Grid>
        </Grid> 
     </Grid>
     <Grid item xs={12} sm={10} lg={4}> 
        <Grid container direction='column' justifyContent='space-evenly'>
            <Grid item>
                <Typography align='center' variant='body1'  color='white'><strong><u>Contact Info</u></strong></Typography>
            </Grid>
            <Grid item>
                <Typography align='center' variant='body1'  color='white'>Phone: 1-866-465-6269 </Typography>
            </Grid>
            <Grid item>
                <Typography  align='center' variant='body1'  color='white'>Address: PO Box 15333, Spokane Valley, WA 99215-333 </Typography>
            </Grid>
        </Grid>
     </Grid>
     <Grid item xs={12} sm={10} lg={3}>
        <Grid container direction='column' justifyContent='flex-end'>
            <Hidden only = {['xs']}>
            <Grid item>             
                <img src="nmsfootersvg.svg" height='75' alt='footerlogo' />
            </Grid>
            </Hidden>
            
        </Grid>
    </Grid>
 </Grid>
  <Grid container direction='row' alignContent='space-evenly' justifyContent='space-evenly'></Grid>
    <Grid item xs={12}>
             <Typography sx={{ backgroundColor: '#565656', }} align='center' variant='body1' color='white'>©2016-{dateFormatted.thisYear}, NameMySettlement.com and any parent companies, All rights reserved.</Typography>
    </Grid>

</Box>
</Container>
</footer>


)

}

export const UserFooter = ({baseURL}) =>{

    
    const imgsource = baseURL + '/nmsfootersvg.svg'
    return(
        <footer style={{backgroundColor: '#565656', width: '100%', height: 'auto', minHeight: 'auto', bottom: 0, left:0, margin:0,}}>
     
    <Container maxWidth='xl'>
        <Box backgroundColor='#565656' >        
         <Grid container direction='row' justifyContent='space-evenly'>
           <Grid item xs={12} sm={10} lg={4}> 
            <Grid container direction='column'justifyContent='space-evenly'>
                <Grid item>
                    <Typography variant='body1' align='center'  color='white'><strong><u>Other Links</u></strong> </Typography>
                </Grid>
                <Grid item textAlign='center' >
                     <Link key={'/user/FAQ'} component={BaseLink} to={'/user/FAQ'} variant='body1'color='white' underline='hover'>Frequently Asked Questions</Link>
                </Grid>
                <Grid item textAlign='center'>
                    <Link key={'/user/privacy-policy'} align='center' component={BaseLink} to={'/user/privacy-policy'} variant='body1'color='white' underline='hover'>Privacy Policy</Link>
                </Grid>
                <Grid item textAlign='center'>
                    <Link key={'/user/termsandconditions'} align='center' component={BaseLink} to={'/user/termsandconditions'} variant='body1'color='white' underline='hover'>Terms and Conditions</Link>
                </Grid>
                <Grid item textAlign='center'>
                    <Link key={'/user/refund-policy'} align='center' component={BaseLink} to={'/user/refund-policy'} variant='body1'color='white' underline='hover'>Refund Policy</Link>
                </Grid>
            </Grid> 
         </Grid>
         <Grid item xs={12} sm={10} lg={4}> 
            <Grid container direction='column' justifyContent='space-evenly'>
                <Grid item>
                    <Typography align='center' variant='body1'  color='white'><strong><u>Contact Info</u></strong></Typography>
                </Grid>
                <Grid item>
                    <Typography align='center' variant='body1'  color='white'>Phone: 1-866-465-6269 </Typography>
                </Grid>
                <Grid item>
                    <Typography  align='center' variant='body1'  color='white'>Address: PO Box 15333, Spokane Valley, WA 99215-333 </Typography>
                </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12} sm={10} lg={3}>
            <Grid container direction='column' justifyContent='flex-end'>
                <Hidden only = {['xs']}>
                <Grid item>             
                    <img src={imgsource} height='75' alt='footerlogo' />
                </Grid>
                </Hidden>
                
            </Grid>
        </Grid>
     </Grid>
      <Grid container direction='row' alignContent='space-evenly' justifyContent='space-evenly'></Grid>
        <Grid item xs={12}>
                 <Typography sx={{ backgroundColor: '#565656', }} align='center' variant='body1' color='white'>©2016-{dateFormatted.thisYear}, NameMySettlement.com and any parent companies, All rights reserved.</Typography>
        </Grid>
    
    </Box>
    </Container>
    </footer>
    
    
    )
    
    }

export const AdminFooter = (baseURL) =>{
    

    const imgsource = baseURL.baseURL + '/nmsfootersvg.svg'
    


    return(
    <footer style={{backgroundColor: '#565656', width: '100%', height: 'auto', minHeight: 'auto', bottom: 0, left:0, margin:0,}}>
     <Container maxWidth='xl'>
        <Box backgroundColor='#565656' >        
         <Grid container direction='row' justifyContent='space-evenly'>
           
         <Grid item xs={12} sm={10} lg={4}> 
            <Grid container direction='column' justifyContent='space-evenly'>
                <Grid item>
                    <Typography align='center' variant='body1'  color='white'><strong><u>Contact Info</u></strong></Typography>
                </Grid>
                <Grid item>
                    <Typography align='center' variant='body1'  color='white'>Phone: 1-866-465-6269 </Typography>
                </Grid>
                <Grid item>
                    <Typography  align='center' variant='body1'  color='white'>Address: PO Box 15333, Spokane Valley, WA 99215-333 </Typography>
                </Grid>
            </Grid>
         </Grid>
         <Grid item xs={12} sm={10} lg={3}>
            <Grid container direction='column' justifyContent='flex-end'>
                <Hidden only = {['xs']}>
                <Grid item>             
                    <img src={imgsource} height='75' alt='footerlogo' />
                </Grid>
                </Hidden>
            
            </Grid>
        </Grid>
     </Grid>
      <Grid container direction='row' alignContent='space-evenly' justifyContent='space-evenly'></Grid>
        <Grid item xs={12}>
                 <Typography sx={{ backgroundColor: '#565656', }} align='center' variant='body1' color='white'>©2016-{dateFormatted.thisYear}, NameMySettlement.com and any parent companies, All rights reserved.</Typography>
        </Grid>
    
    </Box>
    </Container>
    
    </footer>
    )
}
import * as React from 'react'
import { Typography, Box, Button, Grid, Input, InputAdornment} from '@mui/material'
import {axiosErrorHandling, round } from '../misc/other'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import {useScript} from '@uidotdev/usehooks'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import PaymentIcon from '@mui/icons-material/Payment'
import { ErrorAlert } from '../alerts/ErrorAlert'
import RefreshIcon from '@mui/icons-material/Refresh'



export const PartialCC = ({customerInfo, paymentInfo, totalDue, promoCodes, homelink}) =>{

let roundedTotalDue = round(totalDue)


const [promoText, setPromoText] = React.useState(null)
const [billingForm, setBillingForm] = React.useState(null)
const [cardProcessing, setCardProcessing] = React.useState(false)
const [error, setError] = React.useState(false)
const [errorMessage, setErrorMessage] = React.useState('')
const [newTotalDue, setnewTotalDue] = React.useState(roundedTotalDue)
const [paidInFull, setPaidInFull] = React.useState(false)
const [finalPaymentKey, setFinalPaymentKey] = React.useState({})
const [buttonDisabled, setButtonDisabled] = React.useState(true)
const [warningMessage, setWarningMessage] = React.useState('')
const [messageValidation, setMessageValidation] = React.useState('')

let billinginfoPromos = paymentInfo.promoCodesUsed

const getFillData =(data, offerAccepted ) =>{
    let newData = []

    for(let i=0; i<data.length; i++){
        newData.push({
            settlement_amount:Number(round(offerAccepted*data[i].per_of_settlement)),
            original_creditor: data[i].original_creditor,
            original_account_number: data[i].original_account_number,
            batch_number: data[i].batch_number,
            balance: Number(round((roundedTotalDue - newTotalDue)*data[i].per_of_settlement)) 
        })
    }

    return newData

}

React.useLayoutEffect(()=>{
    if(promoCodes.length>0){
       promoCodes = promoCodes.map((item, index) =>({...item, id: index+1})) 

        setPromoText(promoCodes.map((e)=><li key={e.id}>{e.promoCode}</li>))
        
        }else{
            if(billinginfoPromos.length>0){
                billinginfoPromos = billinginfoPromos.map((item, index)=>({...item, id: index+1}))
                setPromoText(billinginfoPromos.map((e)=><li key={e.id}>{e.promoCode}</li>))
                
            }
            
        }

},[promoCodes, billinginfoPromos ])




const sendData = async(id)=>{



try{
    let fillData = getFillData(paymentInfo.accounts, totalDue)
    await axios({
        method: 'POST',
        url:'api/oracle5/partialupdate',
                data: {customer_number: customerInfo.customer_number,
                       status: 'Partial', 
                       data: fillData,
                       }
    }).then(async result=>{
        
        if(result.status===200){
            await axios({
                method: 'POST',
                url:"api/oracle3/generateinvoice",
                data: {data: paymentInfo.accounts,
                       id: id,
                       customerInfo: customerInfo,
                       totalDue: Number(totalDue).toFixed(2) 
                }
            }).then(async results=>{
                if(results.data==='Invoice Generated'){
                    await axios({
                        method: 'POST',
                        url: 'api/oracle6/postpartialcardtransaction',
                        data: {total: Number(newTotalDue).toFixed(2),
                            id: id,
                            customer_number: customerInfo.customer_number  
                     }

                    }).then(result1=>{
                        if(result1.data==='Transaction Posted'){
                            setCardProcessing(false)
                            setPaidInFull(true)
                        } else {
                            setCardProcessing(false)
                            setErrorMessage(result1.statusText)
                        }
                    }).catch(err=>{
                        setCardProcessing(false)
                        
                        throw err

                })
                }
            }).catch(err=>{
                setCardProcessing(false)
                throw err

        })
        }
    }).catch(err=>{
        setCardProcessing(false)
        throw err

})

}catch(err){
    let errorMessage = axiosErrorHandling(err)
    setCardProcessing(false)
    setErrorMessage(errorMessage)
    setError(true)


}

}


const closeError = ()=>{
    setError(false)
}

const backToPaymentClick = ()=>{
   return window.location.reload(false)

}




const status = useScript(process.env.REACT_APP_USAEPAY,
    {removeOnUnmount: true}
)

let billing_address = {
    "firstname": customerInfo.first_name,
    "lastname" : customerInfo.last_name,
    "street" : customerInfo.street_address_1,
    "street2": customerInfo.street_address_2,
    "city": customerInfo.city,
    "state": customerInfo.state,
    "postalcode": customerInfo.zip,
    "country": "USA",
    "phone": customerInfo.phone_number,
    "email": customerInfo.email
}

const isReady = status === "ready"

if (isReady){

let client = new window.usaepay.Client(process.env.REACT_APP_CCKEY)

let paymentCard = client.createPaymentCardEntry();

let CCstyles = {
         'container': {
             'background-color': '#f1f1f1',
             'margin': '10px',
             'border': '2px  #f1f1f1',
             'border-radius': '5px'
         },
         'base': {
             'font-family': 'Roboto',
             'color': 'black',
             'font-size': '16px',
             'height': '75px',
             'border': '2px black',
             'border-radius': '5px'
         },
         'wrapper':{
            'border': '2px  #f1f1f1',
             'border-radius': '5px'
         },
         'label':{
            'font-size': '12px',
            'border': '2px  #f1f1f1',
             'border-radius': '5px'
         }
         
         
 }

 let ccLabels = {
     cnum: 'Enter Card Number',
     exp: 'MM/YY',
     cvv: 'CVV'
 }

 const paymentCardConfig = {
     styles: CCstyles,
     display_labels: true,
     labels: ccLabels,
     label_position: 'top',
     mask_ccnum: true,
     display_errors: true

 }

 paymentCard.generateHTML(paymentCardConfig)
 paymentCard.addHTML('paymentCardContainer')
 
 paymentCard.addEventListener('error', err => {
    let errorContainer = document.getElementById('paymentCardErrorContainer')
    errorContainer.textContent = err.message
     
 });

 let form = document.getElementById('paymentForm')
 form.addEventListener('submit', event =>{
     event.preventDefault();
    
     client.getPaymentKey(paymentCard).then(result=>{
         if(result.error){
            
             let errorContainer = document.getElementById('paymentCardErrorContainer');
             errorContainer.textContent(result.error.message)
         } else {
            
            setFinalPaymentKey(result.key)
            setButtonDisabled(false) 
            setMessageValidation('Card Validated, Do not enter a second card please hit the submit button')
         }
 }).catch(err=>{
     setErrorMessage(err)
    setError(true)
 })
 })

 



} 

const paymentClick = async () =>{
    
    setCardProcessing(true)   
    
    axios({
           method: 'POST',
           url: 'api/oracle6/cardpayment',
           data: { payment_key: finalPaymentKey,
               amount: newTotalDue,
               billing_address: billing_address,                
               }
       }).then( async result=>{
           if(result.data.message==='Payment Processed'){
               
               let id = result.data.refnum
               let balance = roundedTotalDue - newTotalDue
               
               await axios({
                   method: "POST",
                   url: "api/oracle3/partial",
                   data: {data: paymentInfo.accounts,
                          id: result.data.refnum, 
                          mail_documents: 0,
                          totalPaid: Number(newTotalDue).toFixed(2),
                          balance: balance,
                          paymentType: "Card"
                           }
   
               }).then(async results=>{
                   
                   if (results.data === "Payment Complete"){
                       
                       await sendData(id)
                       
                   }else{
                       
                       let errorMessage = 'error in the paid axios call: ' + results.statusText
                       setErrorMessage(errorMessage)
                       throw errorMessage
                   }
               }).catch(err=>{
                   let errorMessage = axiosErrorHandling(err)
                   setErrorMessage(errorMessage)
                   setCardProcessing(false)
                   setError(true)
               })


           }else{
            setCardProcessing(false)
            setErrorMessage('There was a problem with processing the payment')
            setError(true)
           }
       })


}

const changeInTotal = (event) =>{
    
    setnewTotalDue(event.target.value)
    if(event.target.value<5){
        setButtonDisabled(true)
        setWarningMessage('Partial Payment needs to be Greater than $5')

    }
    else if(event.target.value>roundedTotalDue){
        setButtonDisabled(true)
        setWarningMessage('Payment must be less than original owed.  We appreciate the offer though. If you need help call the customer service number below')
    }
    else if(event.target.value===roundedTotalDue){
        setButtonDisabled(true)
        setWarningMessage('To make full payment please click on the back to select payment button and choose Credit Card')

    }
    else{
        setWarningMessage('')
        if(Object.keys(finalPaymentKey).length>0){
        setButtonDisabled(false)
        
        }
    }
}




const labelStyle = {
    fontFamily: 'Roboto',
    marginBottom: '10px',
    color: '#C31200'
 }



const onVerifyClick = () =>{
    if (billingForm){
        setBillingForm(null)
    } else {
        setBillingForm(<VerifyBillingForm customerInfo={customerInfo}/>)
    }
}

if(paidInFull){
    window.location.assign(homelink)
}

return(
    


     <Box sx={{minHeight:'75vh', mt: 2, mb: 2}}>
          
     <ErrorAlert open={error} error={errorMessage} handleClose={closeError}/>
         <form id='paymentForm' action='/charge' method='post'>
                <label htmlFor='paymentCardContainer' style={labelStyle} >Visa or MasterCard Debit/Credit Card Only</label>
                
                <div id='paymentCardContainer'/>
                <div>
                    <button type='submit' id='validateCard' disabled={!buttonDisabled}>Validate Card</button><Typography color='secondary' sx={{ml: 1}}>{messageValidation}</Typography>
                </div>
                <div id='paymentCardErrorContainer'role='alert'/>
                
                <div>
                    <Typography sx={{mb:1}} variant='body1' color='secondary'>{warningMessage}</Typography>
                   
                </div>
                
                
                <Input
                 id='partialpaymentamount'
                 size='small'
                 label='Payment Ammount'
                 autoFocus
                 value={newTotalDue}
                 type='number'
                 startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                 onChange={changeInTotal} 
                 sx={{mr: 1, maxWidth: 100}}
                 
                                

                />
                
                <LoadingButton disabled={buttonDisabled} loading={cardProcessing} sx={{mr: 2}}id='paymentbutton' onClick={paymentClick} color='primary' variant='contained'endIcon={<PaymentIcon />} >Submit Payment ${newTotalDue}</LoadingButton>
                <Button id='backtopaymentbutton' color='secondary' endIcon={<RefreshIcon />} onClick={backToPaymentClick}>Back to Select Payment</Button>
        </form> 
        
        <Grid container direction='column'sx={{mt: 2}} alignItems='center'>
        <Grid item xs={4} sx={{alignItems: 'center'}} >
                <Typography>Promo Codes used: {promoText}</Typography>
        </Grid>
        </Grid>
        <Grid container direction='column' sx={{mt: 2}}>
            <Grid item xs={12}>
                 <Button  variant='outlined' color='secondary' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
        
    </Box>
    
    )
}


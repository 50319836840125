import * as React from 'react'
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {styled} from '@mui/material/styles'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export const ErrorAlert = ({error, open, handleClose}) =>{
    

    return(
        <React.Fragment>
            <BootstrapDialog open={open} onClose={handleClose}>
            
                <DialogTitle color='secondary'>An Error has Occured</DialogTitle >
                <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
               <Typography> 
                We have already reported the error, if you would like updates on the error message, please hit the feedback button and copy and paste the following error message: <strong>{error}</strong> 
                </Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>  
            </BootstrapDialog> 
        </React.Fragment>    
            
        

    )


}
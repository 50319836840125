import * as React from 'react'
import { Typography, Box, Button, Grid, FormControl, InputLabel, Select, MenuItem} from '@mui/material'
import { VerifyBillingForm } from './VerifyBilling'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { PayByCreditRec } from './RecurringCC'
import { round } from '../misc/other'
import { PaybyCheckRec } from './RecurringCheck'



export const RecurringPayment = ({customerInfo, paymentInfo, setBillingInfo, promoCodes, setupLink}) =>{
    
    const [billingForm, setBillingForm] = React.useState(null)
    const [numberOfMonths, setNumberofMonths] = React.useState('6')
    const [newPaymentDue, setNewPayMentDue] = React.useState(paymentInfo.totalDue/6)
    const [disabledMonths, setDisabledMonths] = React.useState(false)
    const [newPaymentInfo, setNewPaymentInfo] = React.useState(paymentInfo)
    const [paymentModule, setPaymentModule] = React.useState(<PaybyCheckRec customerInfo={customerInfo} paymentInfo={newPaymentInfo} promoCodes={promoCodes} numberOfMonths={Number(numberOfMonths)} paymentDue={newPaymentDue} setDisabledMonth={setDisabledMonths} setupLink={setupLink}/>)
    const [paymentSelection, setPaymentSelection] = React.useState('check')
    
    const originalAmount = paymentInfo.totalDue

    const onVerifyClick = () =>{
        if (billingForm){
            setBillingForm(null)
        } else {
            setBillingForm(<VerifyBillingForm customerInfo={customerInfo} setBillingInfo={setBillingInfo} promoCodes={promoCodes}/>)
        }
    }

    

    const onPaymentNumberChange =(event) =>{
        setPaymentModule(null)
        setNumberofMonths(event.target.value)
       
        let oldPaymentInfo = newPaymentInfo 
        oldPaymentInfo.monthlyBill = round(originalAmount/numberOfMonths)
        oldPaymentInfo.numberofMonths = event.target.value
        setNewPayMentDue(oldPaymentInfo.monthlyBill)
        setNewPaymentInfo(oldPaymentInfo)
    
    } 


React.useLayoutEffect(()=>{
    let totalDueToday = round(originalAmount/numberOfMonths)

    if(numberOfMonths>0 && paymentSelection ==='credit'){
        setPaymentModule(<PayByCreditRec paymentInfo={newPaymentInfo} customerInfo={customerInfo}/>)
    } else {
        setPaymentModule(<PaybyCheckRec customerInfo={customerInfo} paymentInfo={newPaymentInfo} promoCodes={promoCodes} numberOfMonths={Number(numberOfMonths)} paymentDue={totalDueToday} setDisabledMonth={setDisabledMonths} setupLink={setupLink}/>)
    }   

},[numberOfMonths, paymentSelection])

    




return(
    <Box sx={{minHeight:'75vh', mt: 2, mb: 1}}>
        <Grid container direction='row' sx={{mb: 1}}>
            <Grid item>
                <Typography color='secondary'>We can only set up monthly payments using ACH/E-Check </Typography>   
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: 1}}>
        <Grid item>
                <Typography>Select Number of Payments:</Typography>   
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: 1}} spacing={1}>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id='number_of_payments'># of Payments</InputLabel>
                        <Select
                            onChange={onPaymentNumberChange}
                            fullWidth
                            label='number_of_payments'
                            id='number_of_payments'
                            defaultValue={'6'}
                            value={numberOfMonths}
                            disabled={disabledMonths}
                        >
                            <MenuItem value='2'>2</MenuItem>
                            <MenuItem value='3'>3</MenuItem>
                            <MenuItem value='4'>4</MenuItem>
                            <MenuItem value='5'>5</MenuItem>
                            <MenuItem value='6'>6</MenuItem>
                            <MenuItem value='7'>7</MenuItem>
                            <MenuItem value='8'>8</MenuItem>
                            <MenuItem value='9'>9</MenuItem>
                            <MenuItem value='10'>10</MenuItem>
                            <MenuItem value='11'>11</MenuItem>
                            <MenuItem value='12'>12</MenuItem>
                            <MenuItem value='13'>13</MenuItem>                

                        </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={6}>
                  
            </Grid>
        </Grid>
        <Grid container direction='row'>
            <Grid item>
                {paymentModule}
            </Grid>

        </Grid>
         <Grid container direction='column'>
            <Grid item xs={12}>
                <Button color='secondary' variant='outlined' fullWidth onClick={onVerifyClick} endIcon={<AccountBoxIcon />}>Verify Billing Info</Button>
            </Grid>
            {billingForm}
        </Grid>
    
    </Box>
)
}
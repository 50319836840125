import * as React from 'react'
import { Typography, Box, 
        Grid, TextField, 
        FormControl, InputLabel, 
        ListSubheader, MenuItem, 
        Select } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { useFormik } from 'formik'   
import * as yup from 'yup'
import {MuiTelInput, matchIsValidTel} from 'mui-tel-input' 
import axios from 'axios' 
import { BillingInfoConfirmed } from '../alerts/BillingInfoUpdated'
import { axiosErrorHandling } from '../misc/other'
import { ErrorAlert } from '../alerts/ErrorAlert'

const validationSchema = yup.object().shape({
    first_name: yup.string().min(2, "Please enter a valid first name"),
    last_name: yup.string().min(2, "Please enter a valid last name"),
    address1: yup.string().min(5, 'Please enter a valid address'),
    address2: yup.string().nullable(),
    city: yup.string().min(3, 'Please enter a valid city name'),
    zip: yup.string().min(5,'Please enter 5 numbers').max(5, 'Please enter only 5 numbers'),
    zip_plus4: yup.string().min(4, 'Please enter 4 numbers').max(4, "Please enter only 4 numbers"),
    email: yup.string().email("Email needs to include an @ and a . for registering")
})

export const VerifyBillingForm = ({customerInfo, setBillingInfo}) =>{
    
    const initialPhone = '+1' + customerInfo.phone_number

const [phoneNumber, setPhoneNumber] = React.useState(initialPhone)
const [phoneToStore, setPhoneToStore] = React.useState(customerInfo.phone_number)
const [phoneError, setPhoneError] = React.useState(false)
const [phoneErrorMessage, setPhoneErrorMessage] = React.useState('')
const [billingUpdatedAlert, setBillingUpdatedAlert] = React.useState(false)
const [errorAlert, setErrorAlert] = React.useState(false)
const [errorMessage, setErrorMessage] = React.useState('')

const closeBillingInfoAlert = () =>{

    setBillingUpdatedAlert(false)
}

const errorClose = () =>{
    setErrorAlert(false)
}

const handlePhoneChange = (newValue, info) =>{
    
    setPhoneNumber(newValue)
    
    if(matchIsValidTel(info.numberValue)){
        setPhoneToStore(info.nationalNumber)
        setPhoneToStore(Number(info.nationalNumber))
        setPhoneError(false)
        setPhoneErrorMessage("")
    }else{
        setPhoneError(true)
        setPhoneErrorMessage('Please enter a valid phone number')
    }

}


const formik = useFormik({
    initialValues:{
        first_name: customerInfo.first_name,
        last_name: customerInfo.last_name,
        street_address_1: customerInfo.street_address_1,
        street_address_2: customerInfo.street_address_2,
        city: customerInfo.city,
        state: customerInfo.state,
        zip: customerInfo.zip,
        zip4: customerInfo.zip4


    },
    validationSchema: validationSchema,
    onSubmit:(values, actions) =>{
        actions.setSubmitting(true)
        
        customerInfo.first_name = values.first_name
        customerInfo.last_name = values.last_name
        customerInfo.street_address_1 = values.street_address_1
        customerInfo.street_address_2 = values.street_address_2
        customerInfo.city = values.city
        customerInfo.zip = values.zip
        customerInfo.zip4 = values.zip4
        customerInfo.phone_number = Number(phoneToStore)
        
        setBillingInfo(customerInfo) 
        window.localStorage.setItem('context', JSON.stringify(customerInfo))
        
        axios({
            method: 'POST',
            url: 'api/oracle2/updatebilling',
            data: customerInfo
        }). then(result=>{
            
            if (result.data === 'You have updated the billing information'){
                setBillingUpdatedAlert(true)
                actions.setSubmitting(false)
            } else{
                actions.setSubmitting(false)
                setErrorMessage('Something went wrong in the call: ' + result.status + result.data)
                setErrorAlert(true)
            }

        }).catch(err =>{
            let message = axiosErrorHandling(err)
            actions.setSubmitting(false)
            setErrorMessage(message)
            setErrorAlert(true)
        }
        )
        
    }
})


    return(
        <Box sx={{minHeight:'80vh', mt: 2, mb: 2}} component='form' onSubmit={formik.handleSubmit}>
            <ErrorAlert open={errorAlert} error={errorMessage} handleClose={errorClose} />
            <BillingInfoConfirmed open = {billingUpdatedAlert} handleClose={closeBillingInfoAlert} />
            <Grid container direction='row'>
                <Grid item sx={{mb: 1}}>
                <Typography>Please update your billing info, if needed. </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{mb: 1}} direction='row' spacing={2} justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={8} s={4} md={6}>
                    <TextField
                    fullWidth
                    label='First Name'
                    id='first_name'
                    name='first_name'
                    type='text'
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.errors.first_name}
                    />
                </Grid>    
                <Grid item xs={8} s={4} md={6}>    
                    <TextField
                    fullWidth 
                    label='Last Name'
                    id='last_name'
                    name='last_name'
                    type='text'
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.errors.last_name}
                    />
                </Grid>
            </Grid>
            <Grid container sx={{mb: 1}} direction='row' justifyContent='flex-start' alignItems='flex-start'>    
                <Grid item xs={8} sm={8} md={12}>
                    <TextField
                    fullWidth
                    label='Street Address 1'
                    id='street_adddress_1'
                    name='street_address_1'
                    type='text'
                    value={formik.values.street_address_1}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_1 && Boolean(formik.errors.street_address_1)}
                    helperText={formik.errors.street_address_1}
                    />
                </Grid>
            </Grid>
            <Grid container direction='row' sx={{mb: 1}} justifyContent='flex-start' alignItems='flex-start'>    
                <Grid item xs={8} sm={8} md={12}>
                    <TextField
                    fullWidth
                    label='Street Address 2'
                    id='street_adddress_2'
                    name='street_adddress_2'
                    type='text'
                    value={formik.values.street_address_2}
                    onChange={formik.handleChange}
                    error={formik.touched.street_address_2 && Boolean(formik.errors.street_address_2)}
                    helperText={formik.errors.street_address_2}
                    />
                </Grid>
            </Grid>
            <Grid container direction='row' spacing={1} sx={{mb: 1}} justifyContent='flex-start' alignItems='flex-start'>
                <Grid item xs={6} sm={3} md={6}>
                    <TextField 
                        fullWidth
                        type='text'
                        margin='none'
                        id='city'
                        name='city'
                        label='City'
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.errors.city}
                    />

                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <FormControl variant='outlined' fullWidth>
                        <InputLabel id='state-label'>State</InputLabel>
                            <Select
                                fullWidth
                                label='state-label'
                                id='state'
                                defaultValue={formik.values.state}
                                value={formik.values.state}
                                onChange={formik.handleChange}
                            >
                                <ListSubheader>States</ListSubheader>
                                <MenuItem value="AL">AL</MenuItem>
                                <MenuItem value="AK">AK</MenuItem>
                                <MenuItem value="AR">AR</MenuItem>
                                <MenuItem value="AZ">AZ</MenuItem>
                                <MenuItem value="CA">CA</MenuItem>
                                <MenuItem value="CO">CO</MenuItem>
                                <MenuItem value="CT">CT</MenuItem>
                                <MenuItem value="DC">DC</MenuItem>
                                <MenuItem value="DE">DE</MenuItem>
                                <MenuItem value="FL">FL</MenuItem>
                                <MenuItem value="GA">GA</MenuItem>
                                <MenuItem value="HI">HI</MenuItem>
                                <MenuItem value="IA">IA</MenuItem>
                                <MenuItem value="ID">ID</MenuItem>
                                <MenuItem value="IL">IL</MenuItem>
                                <MenuItem value="IN">IN</MenuItem>
                                <MenuItem value="KS">KS</MenuItem>
                                <MenuItem value="KY">KY</MenuItem>
                                <MenuItem value="LA">LA</MenuItem>
                                <MenuItem value="MA">MA</MenuItem>
                                <MenuItem value="MD">MD</MenuItem>
                                <MenuItem value="ME">ME</MenuItem>
                                <MenuItem value="MI">MI</MenuItem>
                                <MenuItem value="MN">MN</MenuItem>
                                <MenuItem value="MO">MO</MenuItem>
                                <MenuItem value="MS">MS</MenuItem>
                                <MenuItem value="MT">MT</MenuItem>
                                <MenuItem value="NC">NC</MenuItem>
                                <MenuItem value="NE">NE</MenuItem>
                                <MenuItem value="NH">NH</MenuItem>
                                <MenuItem value="NJ">NJ</MenuItem>
                                <MenuItem value="NM">NM</MenuItem>
                                <MenuItem value="NV">NV</MenuItem>
                                <MenuItem value="NY">NY</MenuItem>
                                <MenuItem value="ND">ND</MenuItem>
                                <MenuItem value="OH">OH</MenuItem>
                                <MenuItem value="OK">OK</MenuItem>
                                <MenuItem value="OR">OR</MenuItem>
                                <MenuItem value="PA">PA</MenuItem>
                                <MenuItem value="RI">RI</MenuItem>
                                <MenuItem value="SC">SC</MenuItem>
                                <MenuItem value="SD">SD</MenuItem>
                                <MenuItem value="TN">TN</MenuItem>
                                <MenuItem value="TX">TX</MenuItem>
                                <MenuItem value="UT">UT</MenuItem>
                                <MenuItem value="VT">VT</MenuItem>
                                <MenuItem value="VA">VA</MenuItem>
                                <MenuItem value="WA">WA</MenuItem>
                                <MenuItem value="WI">WI</MenuItem>
                                <MenuItem value="WV">WV</MenuItem>
                                <MenuItem value="WY">WY</MenuItem>
                                <ListSubheader>Outlying Territories</ListSubheader>
                                <MenuItem value="AS">AS</MenuItem>
                                <MenuItem value="GU">GU</MenuItem>
                                <MenuItem value="MP">MP</MenuItem>
                                <MenuItem value="PR">PR</MenuItem>
                                <MenuItem value="UM">UM</MenuItem>
                                <MenuItem value="VI">VI</MenuItem>
                                <ListSubheader>Armed Forces </ListSubheader>
                                <MenuItem value="APO-AA">AA</MenuItem>
                                <MenuItem value="APO-AP">AP</MenuItem>
                                <MenuItem value="APO-AE">AE</MenuItem>
                                <MenuItem value="FPO-AA">AA</MenuItem>
                                <MenuItem value="FPO-AP">AP</MenuItem>
                                <MenuItem value="FPO-AE">AE</MenuItem>


                            </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <TextField 
                        sx={{ml: .5}}
                        fullWidth
                        margin='none'
                        type='phone'
                        id='zip'
                        name='zip'
                        label='Zip'
                        value={formik.values.zip}
                        onChange={formik.handleChange}
                        error={formik.touched.zip && Boolean(formik.errors.zip)}
                        helperText={formik.errors.zip}
                    />
                </Grid>
                <Grid item xs={3} sm={3} md={2}>
                    <TextField 
                        sx={{ml: 1}}
                        margin='none'
                        type='phone'
                        id='zip4'
                        name='zip4'
                        label='+4'
                        value={formik.values.zip4}
                        onChange={formik.handleChange}
                        error={formik.touched.zip4 && Boolean(formik.errors.zip4)}
                        helperText={formik.errors.zip4}                    
                    />
                </Grid>

            </Grid>
            <Grid container spacing={2} direction='row' justifyContent='center' sx={{mb: '1rem'}}>
            <Grid item xs={10} sm={8} md ={8} lg={12}>
            <MuiTelInput
                        fullWidth
                        sx={{mt: 2}}                    
                        variant='outlined'
                        defaultCountry='US'
                        forceCallingCode={true}
                        onlyCountries={['US']}
                        disableDropdown={true}
                        value={phoneNumber}
                        label='Phone Number'
                        id='phone'
                        name='phone'
                        type='phone'
                        onChange={handlePhoneChange}
                        error={phoneError}
                        helperText={phoneErrorMessage}/>
            </Grid>
            </Grid>
            <Grid container direction='row-reverse'>
                <Grid item>
                    <LoadingButton disabled={!formik.isValid} type='submit' loadingPosition='end' loading={formik.isSubmitting} variant='contained' color='primary' endIcon={<AccountBoxIcon />}>Update Billing</LoadingButton>
                </Grid>
                
            </Grid>
        </Box>
    )

}
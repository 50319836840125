import * as React from 'react'
import {useFormik} from 'formik'
import * as yup from 'yup'
import { TextField, Grid, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import { AdminSearchErrorCN } from '../alerts/AdminSearchError'
import { ErrorAlert } from '../alerts/ErrorAlert'
import { axiosErrorHandling } from '../misc/other'
import { Navigate } from 'react-router-dom'


const validationSchema = yup.object().shape({
    customer_number: yup.number()
                        .positive()
                        .required('Customer Number is Required')
                        .min(10000000,"Not enough numbers please enter 8 digits and no decimal points (.) ")
                        .max(9999999999, "Too many numbers we can only accept up to 10 digits and no decimal points (.)")
                        .integer("No Periods/Decimal Points(.)")
})

export const CNSearchForm = () =>{

    const [isSearching, setIsSearching] = React.useState(false)
    const [findAlert, setFindAlert] = React.useState(false)
    const [errorAlert, setErrorAlert] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState(null)
    const [hasData, setHasData] = React.useState(false)
    
    
    

const alertClose = () =>{
    setFindAlert(false)
}
const errorAlertClose = () =>{
    setErrorAlert(false)
}

const formik = useFormik({
    initialValues:{
        customer_number: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) =>{
        setIsSearching(true)
    axios({method: 'POST',
           url: '/api/oracle2/checkaccounts',
           data: values
    }).then(results=>{
        
        if(results.data==='Customer Number Not Found'){ 
            
            setIsSearching(false)
            setFindAlert(true)
            actions.setSubmitting(false)
        }else{
            window.localStorage.setItem('usercontext', JSON.stringify(results.data))
            setIsSearching(false)
            setHasData(true)
             
            

        }

    }).catch((err)=>{
        const error_message = axiosErrorHandling(err)
        setIsSearching(false)
        setErrorMessage(error_message)
        
        setErrorAlert(true)

        
         
    })
    }

})

if (hasData){
    return <Navigate to='/admin/customercare/confirm' />
}


return( 
<Box component='form' onSubmit={formik.handleSubmit}>
    <Grid container direction='row'>
      <Grid item xs={12} lg={8}>
        <TextField 
        id='customer_number' 
        label='Customer Number Search' 
        type='number' 
        fullWidth variant="standard" 
        value={formik.values.customer_number} 
        onChange={formik.handleChange} 
        error={formik.touched.customer_number && Boolean(formik.errors.customer_number)}
        helperText={formik.errors.email} />
      </Grid>
      <Grid>
        <LoadingButton loading={isSearching} 
                        loadingPosition='end' 
                        sx={{ml: 2}} 
                        endIcon={<SearchIcon />} 
                        variant='contained'
                        type='submit'
                        disabled={!formik.isValid}
                        onClick={formik.handleSubmit}> Search </LoadingButton>
      </Grid>
    </Grid>
    <AdminSearchErrorCN open={findAlert} handleClose={alertClose}/>
    <ErrorAlert error={errorMessage} open={errorAlert} handleClose={errorAlertClose} />
</Box>
)
}
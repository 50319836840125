import * as React from 'react'
import {Alert, AlertTitle, Stack, Collapse} from '@mui/material'




export const SuccessAlert = ({message, setClose, open}) =>{

    return(
        <Stack sx={{width: '100%'}} spacing={2}>
            <Collapse in={open}>
            <Alert severity='success' onClose={setClose}>
                <AlertTitle>Success</AlertTitle>
                <strong> {message} </strong>
            </Alert>
            </Collapse>
        </Stack>
    )
}
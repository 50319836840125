import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import {styled} from '@mui/material/styles'

const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));


  export const BillingInfoConfirmed =({open, handleClose}) =>{

    return(
        <CustomDialog open={open} onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: 3}} onClose={handleClose}>
                Billing Info has been Updated.
            </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                       Your Billing information has been updated. 
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color ='secondary' endIcon={<CloseIcon />}>
                        Close
                    </Button>
                </DialogActions>
        </CustomDialog>


    )
  }
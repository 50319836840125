import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography  } from '@mui/material';
import {styled} from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'


const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  
  export const AdminSearchErrorCN = ({open, handleClose}) => {


    return(

        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
           Could not find this Customer Number
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Please check the Customer Number again.
            </Typography>
            <Typography gutterBottom>
              If the Customer Number is correct please try searching by Name and Address.
            </Typography>
            
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    )
} 

export const AdminSearchErrorFLNS = ({open, handleClose}) => {


    return(

        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
            
            Could not find First or Last Name
            </DialogTitle>
            <DialogContent dividers>
            <Typography gutterBottom>
              Please check the spelling of the first or last name.  Or try another name or nickname for Example William could be Bill, etc. 
            </Typography>    
            <Typography variant='body1'gutterBottom>
              If you still can't find a name, please try another method to find the customer. 
            </Typography>
                      
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    )
} 

export const AdminSearchErrorSSN = ({open, handleClose, errorMessage}) => {


    return(

        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
            
            Could not find the SSN
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Please retype the SSN.
            </Typography>
            <Typography gutterBottom>
              If you can't find the SSN, you can try one of the other methods but we probably don't have any accounts with the SSN. 
            </Typography>
            <Typography gutterBottom>Here is the error: {errorMessage}</Typography>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    )
} 

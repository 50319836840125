import * as React from 'react'
import { Typography, Box, Breadcrumbs, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { AdminConfirmationForm } from '../../../forms/AdminConfirmationForm'
import axios from 'axios'


export const AdminConfirmAccounts = () =>{


let data = JSON.parse(window.localStorage.getItem('usercontext'))



const [userData, setUserData] = React.useState(data)




React.useEffect(()=>{
    console.log('Inside the react useEffect Call')
    if(!Object.hasOwn(data, 'wave_customer_number')){
         axios({method: "POST",
             url:  '/api/oracle2/getwaveid',
             data: {email: data.email}
      }).then( async result=>{
          
          console.log("Result: ", result.data)
          let waveID = await result.data.waveID
          data.wave_customer_number = waveID
          window.localStorage.setItem('usercontext', JSON.stringify(data))
          return data
      }).catch(err=>console.log('Here is the err: ', err))
          }

        console.log('Here is the data inside the ReactUseEffect Call: ', data)
        setUserData(data)
          
    
},[data])



console.log('Here is the userData: ', userData)



return(
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
    <Breadcrumbs>
        <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
        <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
    </Breadcrumbs>    
    <Typography align='center' variant='h6'sx={{mb: .5}} > Please Confirm The Following Information with the Customer: </Typography>
    <Typography align='center' variant='h6' sx={{mb: 3}}>Customer Number: {data.customer_number}</Typography>
    <AdminConfirmationForm data={userData}/>
    </Box>
)
}
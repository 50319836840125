import * as React from 'react'
import { Typography, Box, ButtonGroup, Button, Grid, Breadcrumbs, Link } from '@mui/material'
import { useLocation, Link as RouterLink, useLoaderData } from 'react-router-dom'
import { axiosErrorHandling, round } from '../../../misc/other'
import { PaybyCC } from '../../../forms/PaybyCC'
import { PaybyCheck } from '../../../forms/PaybyCheck'
import { RecurringPayment } from '../../../forms/ReccuringPayments'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import EventRepeatIcon from '@mui/icons-material/EventRepeat'
import { UserPromo } from '../../../alerts/UserPromo'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import axios from 'axios'
import { ErrorAlert } from '../../../alerts/ErrorAlert'
import { PartialCC } from '../../../forms/PartialCC'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import { AdminMoneyOrder } from './AdminMoneyOrder'










export const AdminPayment =  () =>{
    
    

    const context = JSON.parse(window.localStorage.getItem('usercontext'))
    const previouslyUsedPromoCodes = JSON.parse(window.localStorage.getItem('userpromocodes'))
    const location = useLocation()
    
    
    const accountpath = '/admin/customercare/accounts/' + context.email
    const paymentInfo = location.state
    paymentInfo.monthlyBill = round(location.state.totalDue/6)
    paymentInfo.numberofMonths = 6
    const completeLink = '/admin/customercare/complete'
    const setupLink ='/admin/customercare/recurringsetup'
   
    const [totalDue, setTotalDue] = React.useState(0)
    const [payByCC, setPayByCC] = React.useState(null)
    const [payByCheck, setPayByCheck] = React.useState(null)
    const [recurringPayment, setRecurringPayment] = React.useState(null)
    const [moneyOrder, setMoneyOrder] = React.useState(null)
    const [partial, setPartial] = React.useState(null)
    const [billingInfo, setBillingInfo] = React.useState(context)
    const [promoDisabled, setPromoDisabled] = React.useState(false)
    const [promoAlert, setPromoAlert] = React.useState(false)
    const [promoCodesUsed, setPromoCodesUsed] = React.useState([])
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [disableBG, setDisableBG] = React.useState(false)

    const getSettlementData = (accounts) =>{
        let original_amount = 0
        for(let b=0; b<accounts.length;b++){
            original_amount +=accounts[b].settlement_amount
        }
        return original_amount

    } 

    const closeError =() =>{
        setError(false)
    }

    const original_amount = getSettlementData(paymentInfo.accounts)
    

    

    React.useLayoutEffect(()=>{
        if(previouslyUsedPromoCodes.length==0){
            
            setTotalDue(location.state.totalDue)

        }else{
            
            let AlreadyUsedPromoArray = previouslyUsedPromoCodes
            
            let accounts = location.state.accounts
            
            let newTotal = 0
            for(let b=0; b<accounts.length; b++){
                let promoArrayOld = accounts[b].promo_code_used
                
                if(promoArrayOld.length!=0){
                   var old = new Set(promoArrayOld.map(({promoCode})=> promoCode))
                    var differenceArray = AlreadyUsedPromoArray.filter(({promoCode})=>!old.has(promoCode))
                    let ammount = accounts[b].account_balance
                    for(let c=0; c<differenceArray.length; c++){
                         ammount-=(round(ammount*differenceArray[c].percentage))

                    }
                
                newTotal += ammount
                
                    axios({
                        method: 'POST',
                        url: 'api/oracle3/promobalanceupdate',
                        data: {account: accounts[b], newBalance: ammount, promo_code_used: AlreadyUsedPromoArray }
                    }).then(result=>{
                        if(result.data !='Balance Updated'){
                            setErrorMessage('Let the developer team know about the error in the oracle3/promobalanceupdate call')
                            setError(true)
                        }
                    }).catch(err=>{
                        let errMess = axiosErrorHandling(err)
                        setErrorMessage(errMess)
                        setError(true)
                    })
                    setTotalDue(round(newTotal))



                } else{
                    
                    let ammount = accounts[b].settlement_amount
                    for(let a=0; a<AlreadyUsedPromoArray.length; a++){
                        ammount -=(round(ammount*AlreadyUsedPromoArray[a].percentage))
                        
                   }
                   axios({
                    method: 'POST',
                    url: 'api/oracle3/promobalanceupdate',
                    data: {account: accounts[b], newBalance: ammount, promo_code_used: AlreadyUsedPromoArray }
                }).then(result=>{
                    if(result.data != 'Balance Updated'){
                        setErrorMessage('Let the developer team know about the error in the oracle3/promobalancupdate call')
                        setError(true)
                    }
                    
                }).catch(err=>{
                    let errMess = axiosErrorHandling(err)
                    setErrorMessage(errMess)
                    setError(true)
                })

                   
                   newTotal += ammount                   
                   setTotalDue(round(newTotal))

                }

            }
            setPromoCodesUsed(AlreadyUsedPromoArray)
             
            
        }


    },[location])


    
    
    const closePromoAlert =() =>{
        setPromoAlert(false)
    
    }

    const addPromoClick = () =>{
        setPromoAlert(true)
    }

   

const changeBillingInfo = (info) =>{
    setBillingInfo(info)
}

const onPayByCCClick = () =>{

    

    setPromoDisabled(true)
    setPayByCheck(null)
    setRecurringPayment(null)
    setMoneyOrder(null)
    setPartial(null)
    if(payByCC){
        
        setPayByCC(null)
        setPromoDisabled(false)
        
    }else{
        setDisableBG(true)
        setPayByCC(<PaybyCC paymentInfo={paymentInfo} customerInfo={billingInfo} totalDue={totalDue} promoCodes={previouslyUsedPromoCodes} completeLink={completeLink}/>)
    }

}


const onPayByCheckClick =  () =>{

    setPromoDisabled(true)
    setPayByCC(null)
    setRecurringPayment(null)
    setMoneyOrder(null)
    setPartial(null)
    if(payByCheck){
        
        setPayByCheck(null)
        setPromoDisabled(false)
        
    }else{
        
        setPayByCheck(<PaybyCheck paymentInfo={paymentInfo} customerInfo={billingInfo} setBillingInfo={changeBillingInfo} promoCodes={previouslyUsedPromoCodes} totalDue={totalDue} completeLink={completeLink}/>)
    }

}

const onRecurringPaymentClick = () =>{
    
    
    setPromoDisabled(true)
    setPayByCheck(null)
    setPayByCC(null)
    setMoneyOrder(null)
    if(recurringPayment){setRecurringPayment(null)
        setPromoDisabled(false)
    }else{
        setRecurringPayment(<RecurringPayment paymentInfo={paymentInfo} customerInfo={billingInfo} setBillingInfo={changeBillingInfo} promoCodes={previouslyUsedPromoCodes} totalDue={totalDue} setupLink={setupLink}/>)
    }

}

const onMoneyOrderClick = () =>{
    setPromoDisabled(true)
    setPayByCheck(null)
    setPayByCC(null)
    setRecurringPayment(null)
    setPartial(null)
    setMoneyOrder(true)
    if(moneyOrder){setMoneyOrder(null) 
                   setPromoDisabled(false)
    }else{
        setMoneyOrder(<AdminMoneyOrder paymentInfo={paymentInfo} totalDue={totalDue} />)
    }
}

const onPartialClick = () =>{

    setPromoDisabled(true)
    setPayByCheck(null)
    setPayByCC(null)
    setRecurringPayment(null)
    setMoneyOrder(null)
    if(partial){
        
        setPartial(null)
        setPromoDisabled(false)
        
    }else{
        setDisableBG(true)
        setPartial(<PartialCC paymentInfo={paymentInfo} customerInfo={billingInfo} totalDue={totalDue} promoCodes={previouslyUsedPromoCodes} homelink={accountpath}/>)
    }


}


    return (
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <ErrorAlert open={error} handleClose={closeError} error={errorMessage} />
        <Breadcrumbs sx={{mb: 2}}>
            <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirm'>Confirm Info</Link>
            <Link underline='hover' component={RouterLink} to={accountpath}>Select Accounts</Link>
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirmaccounts'>Confirm Type</Link>
            <Link underline='hover' component={RouterLink} to='/admin/customercare/settlement'>Settlement</Link>
        </Breadcrumbs>
        <Grid container direction='column' alignItems='center' justifyContent='center' spacing={3} >
            <UserPromo open={promoAlert} original_ammount={original_amount} settotaldue={setTotalDue} customer_number={context.customer_number} setpromocodesused={setPromoCodesUsed} close={closePromoAlert} accounts={location.state} promoCodesUsed={previouslyUsedPromoCodes}/>
            <Grid item>    
                <Typography variant='h4' align='center' >Enter Payment Type </Typography>
            </Grid>
            <Grid item alignContent='center' alignSelf='center'>
                <ButtonGroup disabled={disableBG}>
                    <Button id='bgcard' endIcon={<CreditCardIcon />} onClick={onPayByCCClick}>Credit Card</Button>
                    <Button id='bgcheck' endIcon={<AccountBalanceIcon />} onClick={onPayByCheckClick}>E-Check</Button>
                    <Button id='bgreccuring' endIcon={<EventRepeatIcon />} onClick={onRecurringPaymentClick}>Set Up Monthly Payments</Button>
                    <Button id='bgmo' endIcon={<CurrencyExchangeIcon />} onClick={onMoneyOrderClick}>Money Order</Button>
                    <Button id='bgpartial' endIcon={<PriceChangeIcon />} onClick={onPartialClick}>Partial</Button>
                </ButtonGroup>
            </Grid>
            <Grid item sx={{minWidth: '35%', mb: 2}}>
                <Button disabled={promoDisabled} fullWidth variant='contained' onClick={addPromoClick} >Add Promo Code</Button>
            </Grid>
            <Grid container direction='row' alignItems='center' justifyContent='center'>
            <Grid item xs={10} md={8} lg={4}>
                   {payByCC}
                   {payByCheck}
                   {recurringPayment}
                   {moneyOrder}
                   {partial}      
            </Grid>
            </Grid>
            
        </Grid>
        </Box>
    )
}
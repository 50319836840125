import * as React from 'react'
import { Grid, FormControl, Input, InputAdornment, 
        Typography, Slider, Box, Link, Breadcrumbs } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useLocation, Navigate, Link as RouterLink} from 'react-router-dom'
import { round, axiosErrorHandling } from '../../../misc/other'
import axios from 'axios'
import RedoIcon from '@mui/icons-material/Redo'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import { PDFDate } from '../../../misc/dates'
import { OfferLetterPDF, getProps } from '../../../letters/OfferLetter'
import { pdf } from '@react-pdf/renderer'
import FileSaver from 'file-saver' 
import { ErrorAlert } from '../../../alerts/ErrorAlert'




export const AdminSettlement =()=>{
    let location = useLocation()
    const context = JSON.parse(window.localStorage.getItem('usercontext'))
    const accountpath = '/admin/customercare/accounts/' + context.email
    
    console.log('Her is the user context data: ', context)
    const customer_number = context.customer_number
    
    console.log('here is the location data: ', location)

    let settlementLeast = 0
    let settlementData = location.state.accounts
    console.log('Here is the settlementData: ', settlementData )
    console.log('Here is the settlementdata length: ', settlementData.length)


    for(let i=0; i<settlementData.length; i++){
       
        settlementLeast += (settlementData[i].principal * settlementData[i].settlement_per)
        
    }

    for(let j=0; j<settlementData.length; j++){
        
        settlementData[j].per_of_settlement = Math.round(((settlementData[j].principal * settlementData[j].settlement_per) / settlementLeast)*100)/100
        }
        
        
       
        const closetosixhundred = 660

        const [max, setMax] = React.useState(location.state.totalDue)
        const [min, setMin] = React.useState(0)
        const [userOffer, setUserOffer] = React.useState(0)
        const [sixHundredAlert, setSixHundredAlert] = React.useState(false)
        const [readyForPayment, setReadyForPayment] = React.useState(false)
        const [totalDue, setTotalDue] = React.useState(0)
        const [savings, setSavings] = React.useState(Math.round((max- userOffer)*100)/100)
        const [belowTarget, setBelowTarget] = React.useState(false)
        const [aboveTarget, setAboveTarget] = React.useState(false)
        const [errorAlert, setErrorAlert] = React.useState(false)
        const [errorMessage, setErrorMessage]  = React.useState('')

        const handleSliderChange = (event, newValue) =>{
            let newSavings = location.state.totalDue - newValue
            setUserOffer(newValue)
            setSavings(newSavings)
      if(closetosixhundred > savings && savings > 600 ){
        setSixHundredAlert(true)
    } else {setSixHundredAlert(false)}
        }
    
        const handleInputChange = (event) =>{
            setTotalDue(event.target.value)
            setUserOffer(event.target.value === '' ? '' : Number(event.target.value))
            let newSavings = location.state.totalDue - userOffer
            setSavings(newSavings)
            
            if(closetosixhundred > savings && savings > 600 ){
                setSixHundredAlert(true)
            } else {setSixHundredAlert(false)}
            
        } 

    React.useLayoutEffect(()=>{
        if(userOffer<settlementLeast){
            setBelowTarget(true)
            setAboveTarget(false)
        } else{
            setAboveTarget(true)
            setBelowTarget(false)
        }
    },[userOffer,settlementLeast])
    
    
        
        const handleBlur = () =>{
            if(userOffer < min) {
                setUserOffer(min)
            } else if (userOffer > max){
                setUserOffer(max)
            }
            if(closetosixhundred > savings && savings > 600 ){
                setSixHundredAlert(true)
            } else {setSixHundredAlert(false)}
        
        }
        
        
        
        
        
        
        const marks = [
        { value: min,
            label: ('$'+ min)
        },
        { value: max,
            label: ('$' + max)
        
        }
        ]
       
  const handleErrorClose = () =>{
    setErrorAlert(false)
  }    
     
  
  const counterOffer = () =>{
 
    
    if(userOffer < settlementLeast){
        let newOffer;
        axios({
              method: 'POST',
              url: 'api/oracle5/counteroffer',
              data: {settlementLeast: settlementLeast, userOffer: userOffer}
        }).then(result=>{
            console.log('Here are the results: ', result)
            newOffer = result.data.newOffer
            return newOffer
            
            
        }).then(newOffer=>{
            setTotalDue(newOffer)
            setUserOffer(newOffer)
            setMax((newOffer-1))
            setMin((userOffer+1))
                 
            
            
        })
            
            
        
        
    } else{
        
        setTotalDue(userOffer)
        
        
    }

}




const acceptOffer = async () =>{

    const getFillData = (data, offerAccepted) =>{
            
            
        let newData = []
        for(let i=0; i<data.length; i++){
            newData.push({
                settlement_amount: (offerAccepted*data[i].per_of_settlement),
                original_creditor: data[i].original_creditor,
                original_account_number: data[i].original_account_number,
                batch_number: data[i].batch_number,
                balance: round(offerAccepted*data[i].per_of_settlement)
            })

        }
        return newData
    }

    const file_name = customer_number.toString() + '_OfferLetter_' + PDFDate + '.pdf'

    const blobData = {customer_number: customer_number,
        file_name: file_name,
        blob_file: null}
        
let fillData = getFillData(settlementData, totalDue)

const PDFprops = await getProps(settlementData, totalDue)
const doc=<OfferLetterPDF {...PDFprops} />
const asPDF = pdf([])
asPDF.updateContainer(doc)
const blob = await asPDF.toBlob()
FileSaver.saveAs(blob, blobData.file_name)

await axios({
            method: 'POST',
            url: 'api/oracle4/upload',
            headers: {'Content-Type': 'application/octet-stream'},
            params:{fileName: file_name,
                    customer_number: customer_number, 
                    autogenerated: 1,
                    email: context.email
                },
            data: blob
    
}).then(async response=>{
    
    if (response.status===200){
        
       await axios({
                method: 'POST',
                url:'api/oracle5/confirm',
                data: { customer_number: customer_number,
                        status: 'Settled', 
                        data: fillData,
                        file_name: file_name}
        
          }).then(result=>{
            if (result.status===200){
                console.log("here is the old account data: ", settlementData)
              for(let a=0; settlementData.length>a; a++)

              { let perc = settlementData[a].per_of_settlement
               settlementData[a].settlement_amount=totalDue*perc
               settlementData[a].account_balance=totalDue*perc
              }
              
             
              setReadyForPayment(true)
                
            }
            
          })
          .catch(errors=>{

            console.log('Here is the errors in the confirm call: ', errors)
            // let errs=axiosErrorHandling(errors)
            // setErrorMessage(errs)
            // setErrorAlert(true)
        })
        
        
    } else {
        
        console.log('Here is the response: ', response)
    }
}).catch(error=>{
    let err= axiosErrorHandling(error)
    setErrorMessage(err)
        setErrorAlert(true)
})


} 

let sixHundredWarning;
if(sixHundredAlert) { sixHundredWarning = <Grid item> <Typography sx={{mb:'2rem'}} variant='h6'color='primary' gutterBottom={true}> *If savings are less than $600 we do not have to report to the IRS any savings as taxable income.</Typography> </Grid>
} else {<Grid></Grid>}


if(readyForPayment){ return <Navigate to={'/admin/customercare/payment/'} state={{accounts: settlementData, totalDue: totalDue}} />}


    return(
        
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Breadcrumbs sx={{mb: 2}}>
            <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare'>Search </Link> 
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirm'>Confirm Info</Link>
            <Link underline='hover' component={RouterLink} to={accountpath}>Select Accounts</Link>
            <Link underline='hover' component={RouterLink} to='/admin/customercare/confirmaccounts'>Confirm Type</Link>
        </Breadcrumbs>
        <ErrorAlert error={errorMessage} open={errorAlert} handleClose={handleErrorClose} />
<Grid container direction='column' justifyContent='center' alignItems='center'>
    <Grid item>
        <Typography sx={{mb:'2rem'}} variant='h6'color='secondary' gutterBottom={true}> Make Your Offer Below: </Typography>
    </Grid>
    <Grid item>
        <FormControl fullWidth sx={{mb:'2rem', width: '12rem'}} variant='outlined'>
        
        <Input
        fullWidth
        id='offer'
        variant='outlined'
        value={userOffer}
        startAdornment={<InputAdornment position='start'>$</InputAdornment>}
        type='number'
        onChange={handleInputChange}
        onBlur={handleBlur}
        
        
        inputProps={{
            min: min,
            max: max,
            type: 'number',
            'aria-labelledby' : 'offer-slder',
            label: 'Offer Ammount',
            

        }}
        />
    
        </FormControl>
    </Grid>
    <Grid item>
        <Slider sx={{width: '12rem', mb: 3}}
                value={userOffer} 
                min={min} 
                max={max} 
                onChange={handleSliderChange} 
                aria-labelledby="offer-slider"
                marks={marks}/>
    </Grid>
    <Grid container direction='row' justifyContent='center' alignItems='center' sx={{mt: 2, mb:2}} spacing={1}>
    <Grid item xs={3} >
        <LoadingButton disabled={!aboveTarget} sx={{ml:30}}variant='contained' color='secondary' type='button' endIcon={<PriceCheckIcon />} onClick={acceptOffer}>Confirm and Print</LoadingButton>
    </Grid>
    <Grid item xs={3}>
        <LoadingButton disabled={!belowTarget} variant='contained' color='primary' type='button' endIcon={<RedoIcon/>} onClick={counterOffer}>Get Counter Offer</LoadingButton>
    </Grid>

    </Grid>
    <Grid item>
        <Typography sx={{mb: 1}} variant='h6'color='secondary' gutterBottom={true}>Total Savings: ${savings.toFixed(2)}</Typography>
    </Grid>
    <Grid item>
        <Typography variant='h6' color='primary' gutterBottom={true}> Target to hit: ${round(settlementLeast)} **Do not share with customer**</Typography>
    </Grid>
    <Grid item>
        {sixHundredWarning}
    </Grid>
</Grid>
</Box>
    )


}
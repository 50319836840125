import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        primary:{
            main: '#FE5200'
        },
        secondary: {
            main: '#C31200'
        },
    },


})
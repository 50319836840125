
import * as React from 'react'
import { Link} from 'react-router-dom'
import { Grid, Button, Container, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import EditIcon from '@mui/icons-material/Edit';
import PasswordIcon from '@mui/icons-material/Password';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DescriptionIcon from '@mui/icons-material/Description';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';

export const AdminDashboard = () => {
    
    


    return(
        
        <Container>
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>    
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<AddIcon />}
            size='large'
            >Add Administrator
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<PersonAddIcon/>}
            size='large'
            component={Link} to='/admin/addcustomers'
            >Add Customers
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<AssessmentIcon />}
            size='large'
            >Export Report
            </Button>
        </Grid>
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<UnsubscribeIcon />}
            component={Link} to='/admin/removeemail'
            size='large'
            >Remove Email Validation
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<EmailIcon />}
            size='large'
            >Change Email
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem', textAlign: 'center' }}
            variant='contained'
            color='primary'
            component={Link} to='/admin/sendmarketingemail'
            endIcon={<SendIcon />}
            size='large'
            >Send Marketing Email
            </Button>
        </Grid>
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            component={Link} to='/admin/addpromo'
            endIcon={<LocalOfferIcon />}
            size='large'
            >Add Promo Code
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            component={Link} to='/admin/validateemail'
            color='primary'
            endIcon={<AllInboxIcon />}
            size='large'
            >Email Validation
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            component={Link} to='/admin/addemailtemplate'
            variant='contained'
            color='primary'
            endIcon={<EditIcon />}
            size='large'
            >+ Email Template
            </Button>
        </Grid>
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            component={Link} to='/admin/changepassword'
            endIcon={<PasswordIcon />}
            size='large'
            >Change Password
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            color='primary'
            endIcon={<SettingsApplicationsIcon />}
            size='large'
            >Change Email
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem', textAlign: 'center' }}
            variant='contained'
            color='primary'
            component={Link} to='/admin/customercare'
            endIcon={<ContactPhoneIcon />}
            size='large'
            >Customer Care Portal
            </Button>
        </Grid>
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem', textAlign: 'center'}}
            variant='contained'
            color='primary'
            component={Link} to='/admin/feedback'
            endIcon={<ForumRoundedIcon />}
            size='large'
            >Respond to Feedback
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            // component={Link} to='/admin/validateemail'
            color='primary'
            endIcon={<PaymentsIcon />}
            size='large'
            >Recurring Payment Process
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            component={Link} to='/admin/processing'
            variant='contained'
            color='primary'
            endIcon={<LocalAtmIcon />}
            size='large'
            >Check Processing
            </Button>
        </Grid>
        <Grid container 
        justifyContent='space-around'
        direction='row'>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem', textAlign: 'center'}}
            variant='contained'
            color='primary'
            component={Link} to='/admin/customercare'
            endIcon={<EditNoteIcon />}
            size='large'
            >Update Customers
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            variant='contained'
            // component={Link} to='/admin/updatecustomers'
            color='primary'
            endIcon={<RequestQuoteIcon />}
            size='large'
            >Process Money Orders
            </Button>
            <Button
            sx={{margin:2, height: '4rem', width: '14rem'}}
            component={Link} to='/admin/customercare/'
            variant='contained'
            color='primary'
            endIcon={<DescriptionIcon />}
            size='large'
            >Process Documents
            </Button>
        </Grid>
        </Box>
        
        </Container>
        
        
    )
}
import * as React from 'react'
import { Typography, Grid, Select, 
         FormControl, 
         MenuItem, InputLabel, 
         FormHelperText, Box, 
         } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro'
import SendIcon from '@mui/icons-material/Send'
import axios from 'axios'
import { axiosErrorHandling } from '../../misc/other'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import GroupIcon from '@mui/icons-material/Group';




export const SendMarketingEmail = () =>{

    const [marketingEmails, setMarketingEmails] = React.useState([])
    const [selectedEmailTemplate, setSelectedEmailTemplate] = React.useState('')
    const [selectedCustomerType, setSelectedCustomerType] = React.useState('')
    const [menuItems, setMenuItems] = React.useState(null)
    const [selectedCustomer, setSelectedCustomer ] = React.useState(false)
    const [dirtyForm, setDirtyForm] = React.useState(false)
    const [submiting, setSubmitting] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [dataGrid, setDataGrid] = React.useState(<Grid></Grid>)
    const [customersToSend, setCustomersToSend] = React.useState([])
    const [customersSelected, setCustomersSelected] = React.useState(false)
    
    const closeError = () =>{
        setError(false)
    }
    
    const columns = [
        {field: 'id', headerName: 'ID', flex:1},
        {field: 'first_name', headerName: 'First', flex: 2},
        {field: 'last_name', headerName: 'Last', flex: 2},
        {field: 'email', headerName: 'Email', flex: 2},
        {field: 'email_sent', headerName: 'Campaigns', flex: 2},
        {field: 'last_email_sent', headerName: 'Date', flex: 2},
        {field: 'original_creditor', headerName: 'Account', flex: 2},
        {field: 'original_account_number', headerName:'Account_Number', flex: 2}
    ]


React.useEffect(()=>{
    axios({
        method: 'POST',
        url: 'api/oracle7/getemailtemplates'
        

    }).then(async results=>{
        
        console.log('Here are the results', results)
        let marketingData = await results.data
        
        console.log('Here is the marketingData:  ', marketingData)
        setMarketingEmails(marketingData)

    }).catch(err=>{

         let errMessage = axiosErrorHandling(err)
        setErrorMessage(errMessage)
        setError(true)

    })
    

},[])

// const rowSelectionChange = (row, selectedData) =>{
//     console.log('You have changed the row', row)
//     console.log('here is the selected data, ', selectedData)
//     console.log('Here is the row length: ', row.length)
//     console.log('Here is the raw data length: ', selectedData.length)
//     if(row.length){
//         let rowsSelected = []
//         setCustomersSelected(true)
//         let a = 0
//         while(a<selectedData.length ){
//             if (row.includes(selectedData[a].id)){
//                 console.log('Here is the rows, we have selected 1st time: ', rowsSelected)
//                 rowsSelected.push(selectedData[a])
                
//             }
//             console.log('Here is the rows, we have selected: ', rowsSelected)
//             a++
//         }
//         console.log('Here is the rows, we have selected 2nd time: ', rowsSelected)
//         setCustomersToSend(rowsSelected)
        
//     }
//     else{
        
//         setCustomersSelected(false)
//         setCustomersToSend([])
//         }

    
// }

const selectChange = (event) => {
    setSelectedEmailTemplate(event.target.value)
    setDirtyForm(true)
    setError(false)
}

const selectCustomerChange = (event) =>{
    setSelectedCustomerType(event.target.value)
    setSelectedCustomer(true)
    setError(false)
    
    
}

const getCustomers = () =>{
    console.log('Hit the button')
    setLoading(true)
    axios({
        method: "POST",
        url: 'api/oracle3/getcustomeremail',
        data: {customer_type: selectedCustomerType}
    }).then( (result)=>{
        
         console.log('Here is the result: ', result)
        
        if(result.data.length>0){
            
            setDataGrid(<Grid container direction='row'>
                <Grid item xs={12} sx={{mt: 2}}>
                    <DataGridPro 
                    density='comfortable'
                    rows={result.data}
                    columns={columns}
                    checkboxSelection
                    columnBufferPx={150}
                    hideFooter={false}
                    slots={{toolbar: GridToolbar}}
                    slotProps={{toolbar: {showQuickFilter: true}}}
                    onRowSelectionModelChange={(row)=>{ console.log('Here is the row', row)
                                                         console.log('Here is the data: ', result.data)
                                                            if(row.length){
                                                                let rowsSelected = []
                                                                setCustomersSelected(true)
                                                                let a = 0
                                                                while(a<result.data.length ){
                                                                    if (row.includes(result.data[a].id)){
                                                                        console.log('Here is the rows, we have selected 1st time: ', rowsSelected)
                                                                        rowsSelected.push(result.data[a])
                                                                        
                                                                    }
                                                                    console.log('Here is the rows, we have selected: ', rowsSelected)
                                                                    a++
                                                                }
                                                                console.log('Here is the rows, we have selected 2nd time: ', rowsSelected)
                                                                setCustomersToSend(rowsSelected)
                                                                
                                                            }
                                                            else{
                                                                
                                                                setCustomersSelected(false)
                                                                setCustomersToSend([])
                                                                }
                                                        
                                                            
                                                        }



                                                         }
                    />
                </Grid> 
            </Grid>)
        }
        

    })
    
    setLoading(false)


}

const sendEmailButton =()=>{
    setSubmitting(true)
    console.log('Here is the customerData to Send: ', customersToSend)
    console.log('Here is the selectedEmailTemplate', selectedEmailTemplate)
    console.log('Here is the selected_Type: ', selectedCustomerType)
    axios({
        method: "POST",
        url: 'api/oracle7/sendemail',
        data: {customers: customersToSend,
               template: selectedEmailTemplate 
         }
    }).then(result=>{
        console.log('Here is the result: ', result)
    })
    setSubmitting(false)
    
}

 
// const sendEmailButton =() =>{
//     setSubmitting(true)
//     setError(false)
// console.log('Here is the selection: ', selectedEmailTemplate)

// const isolatedObject = marketingEmails.find((template)=> template._id===selectedEmailTemplate)

// console.log('Here is the isolated Object: ', isolatedObject)
// if(isolatedObject._id==='starter_test'){
// axios({
//     method: "POST",
//     url:'api/oracle7/sendemailstarter',
//     data: isolatedObject
// }).then(results=>{
//     console.log('Here are the results', results)
    
//     if(results.status===200){
//         setSubmitting(false)
//         setDirtyForm(false)
//         return <Alert severity='success' >Email has been Set</Alert>
        
//     }else{
//         setSubmitting(false)
//         setDirtyForm(false)
//         setErrorMessage(results.data)
//         setError(true) 
//     }
    
    
// }).catch(err=>{
    
//     setSubmitting(false)
//     setDirtyForm(false)
//     let errMessage = axiosErrorHandling(err)
//     setErrorMessage(errMessage)
//     setError(true)
// }).catch(err=>{
    
//     setSubmitting(false)
//     setDirtyForm(false)
//     let errMessage = axiosErrorHandling(err)
//     setErrorMessage(errMessage)
//     setError(true)
// })
// } else if (isolatedObject._id==='document_reminder'){
//     axios({
//         method: "POST",
//         url:'api/oracle7/senddocumentemail',
//         data: isolatedObject
//     }).then(results=>{
//         console.log('Here are the results', results)
        
//         if(results.status===200){
//             setSubmitting(false)
//             setDirtyForm(false)
//             return <Alert severity='success' >Email has been Set</Alert>
            
//         }else{
//             setSubmitting(false)
//             setDirtyForm(false)
//             setErrorMessage(results.data)
//             setError(true)  
//         }
        
        
//     }).catch(err=>{
        
//         setSubmitting(false)
//         setDirtyForm(false)
//         let errMessage = axiosErrorHandling(err)
//         setErrorMessage(errMessage)
//         setError(true)
//     }).catch(err=>{
        
//         setSubmitting(false)
//         setDirtyForm(false)
//         let errMessage = axiosErrorHandling(err)
//         setErrorMessage(errMessage)
//         setError(true)
//     })


// }


// else {
//     setError(true)
//     setDirtyForm(false)
//     setSubmitting(false)


// }
// }

React.useEffect(()=>{
    console.log('Here are the marketingEmails', marketingEmails)
    let menuitems = marketingEmails.map((e)=>
        <MenuItem key={e.key} value={e._id}>{e._id} </MenuItem>
    )
    console.log('Here are the menuitems', menuitems)
    setMenuItems(menuitems)
    

},[marketingEmails])

 

    return(
        
        <Box sx={{minHeight:'70vh', mt: 15, mb: 2}}>
            <Grid container direction='column' justifyContent='center' alignContent='center' sx={{mb: 2}}>
                <ErrorAlert open={error} handleClose={closeError} error={errorMessage}/>
                <Grid item>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id='select-email-template-label'>Type of Customer</InputLabel>
                <Select labelID='select-email-template-label'
                id='select-email-template'
                label='Template'
                value={selectedCustomerType}   
                onChange={selectCustomerChange}
                >
                
                <MenuItem value=''><em>None</em></MenuItem>
                <MenuItem value='setup'>Account Set Up</MenuItem>
                <MenuItem value='not_active'>New</MenuItem>

                </Select>
                <FormHelperText> Please select a template</FormHelperText>
                

            </FormControl>

                </Grid>
                <Grid item>
                    <LoadingButton 
                    variant='contained' 
                    color='primary' 
                    disabled={!selectedCustomer} 
                    endIcon={<GroupIcon />}
                    onClick={getCustomers}
                    loading={loading}
                    loadingPosition='end'
                    >Get Customers</LoadingButton>

                </Grid>
                
            </Grid>
            {dataGrid}
        <Grid container direction='column' justifyContent='center' alignItems='center'>
        <Grid item>
        <Typography sx={{mb: '1rem'}} variant='h4' color='secondary' >Number of Accounts to send: {customersToSend.length} </Typography>
        </Grid>
        <Grid item>
        <Typography sx={{mb: '1rem'}} variant='h4' color='secondary' >Please select a Template to send</Typography>
        </Grid>
        <Grid item>

            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id='select-email-template-label'>Template</InputLabel>
                <Select labelID='select-email-template-label'
                id='select-email-template'
                label='Template'
                value={selectedEmailTemplate}
                onChange={selectChange}
                >
                
                <MenuItem value=''><em>None</em></MenuItem>
                {menuItems}

                </Select>
                <FormHelperText> Please select a template</FormHelperText>
                

            </FormControl>
        </Grid>
        <Grid item>
        <LoadingButton
        sx={{m: 1}}
        variant='contained'
        disabled={!dirtyForm || !customersSelected}
        loading={submiting}
        loadingPosition='end'
        endIcon={<SendIcon/>}
        onClick={sendEmailButton}
        
        >Send Email</LoadingButton>
        
        </Grid>
        <Grid item>
        <Typography color='primary'>{errorMessage}</Typography>
        </Grid>
        </Grid>
        </Box>
        
        
    )
}
import * as React from 'react'
import { Link} from 'react-router-dom'
import { Grid, Typography, Box, Button} from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'



export const AdminCompleted = ()=>{
        const path =  '/admin/dashboard'   
        
        return(
           
            <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
               
            <Grid container direction='row' justifyContent='center' alignItems ='center'>
            <Grid item xs={7} sm={6} md={6} lg={4}>
            <Typography variant='h6'gutterBottom={true}>Payment Has Been Processed...Tell the customer to check their inbox for the settlement letter. The downloaded one can also be re-sent.  Make sure the customer keeps the letter on file for future reference. Remind the customer that if any other entity requests to settle the debt please show them this letter. Also, let the customer know,  NameMySettlement.com will be sending a receipt by the end of the next business day. </Typography>
            </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Grid item>
            <Button endIcon={<DashboardIcon />} component={Link} to={path}  variant='contained' color='primary'> Go Back To Account Home </Button>
            
            </Grid>
            </Grid>
            
            </Box>
            
            
        )
    
    }
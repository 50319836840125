import * as React from 'react'
import {Dialog, 
        DialogTitle, 
        DialogContent, 
        DialogActions, 
        IconButton,
        Button, 
        Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export const AdminUserCreationSuccess =({open, handleClose}) =>{

<React.Fragment>
        <BootstrapDialog open={open} onClose={handleClose}>
        <DialogTitle color='secondary' sx={{ m: 0, padding: 2, mr: '3rem'}} onClose={handleClose}>
                The User Has been succesfully created.
        </DialogTitle>
        <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Please have the client check their email for a password confirmation email.
              You may close this alert.
            </Typography>
        </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </BootstrapDialog>
        </React.Fragment>

  }

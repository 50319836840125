import * as React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { UserHeader } from './headers/Headers'
import { UserFooter } from './footers/Footers'
import { FeedbackButton } from '../alerts/FeedbackButton'
import { AuthProvider } from '../misc/auth'




export const UserLayout =()=>{
    
    
    const userInfo =  window.localStorage.getItem('user')
    const user = JSON.parse(userInfo)
    const baseURL = window.localStorage.getItem('BASE_URL')
    const [userEmail, setUserEmail] = React.useState(user.email)
    const [userPath, setUserPath] = React.useState('/user/accounts' + userEmail)
    

    
    
    const pagesList = [ {label: 'Accounts', path: userPath},]
    
    React.useLayoutEffect(()=>{
        setUserPath('/user/accounts' + userEmail)

    },[userEmail])

    const changeUserEmail = (value) =>{
        setUserEmail(value)
    }
    
    if(!user) {
        return <Navigate to= '/' replace={true} />
    }
    
    return(
    <>
    
      
        <AuthProvider>
        <UserHeader pages={pagesList} email={userEmail} />    
            <Outlet context={changeUserEmail}/>
            <FeedbackButton />
        <UserFooter baseURL={baseURL} />
        </AuthProvider> 
    </>
    )
    
}
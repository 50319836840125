import * as React from 'react'
import { Typography, Box, ButtonGroup, Button, Grid, Breadcrumbs, Link } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { CNSearchForm } from '../../forms/CNSearchForm'
import { FLNSearchForm } from '../../forms/FLNSearchForm'
import { SSNSearchForm } from '../../forms/SSNSearchForm'




export const CustomerCare = ()=>{
    const [cnsearch, setCNSearch] = React.useState(null)
    const [flnsearch, setFLNSearch] = React.useState(null)
    const [ssnsearch, setSSNSearch] = React.useState(null)

const onCNSClick = () => {
    setFLNSearch(null)
    setSSNSearch(null)
    if (cnsearch){setCNSearch(null)}
    else{
        setCNSearch(<CNSearchForm />)
    }
}

const onFLNSClick = () => {
    setCNSearch(null)
    setSSNSearch(null)
    if (flnsearch){setFLNSearch(null)}
    else{
        setFLNSearch(<FLNSearchForm />)
    }
}

const onSSNClick = () => {
    setFLNSearch(null)
    setCNSearch(null)
    if (ssnsearch){setSSNSearch(null)}
    else{
        setSSNSearch(<SSNSearchForm />)
    }
}

    return(
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <Breadcrumbs>
            <Link underline='hover' component={RouterLink} to='/admin/dashboard'>Dashboard</Link> 
        </Breadcrumbs>    
        <Grid container direction='column' alignContent='center' justifyContent='center' spacing={3} >
            <Grid item>    
                <Typography variant='h4' align='center' >Search by: </Typography>
            </Grid>
            <Grid item alignContent='center' alignSelf='center'>
                <ButtonGroup>
                    <Button onClick={onCNSClick}>Customer Number</Button>
                    <Button onClick={onFLNSClick}>First and Last Name</Button>
                    <Button onClick={onSSNClick}>SSN-LAST RESORT</Button>
                </ButtonGroup>
            </Grid>
            <Grid item>
                   {cnsearch}
                   {flnsearch}
                   {ssnsearch}      
            </Grid>
        </Grid>
        </Box>
    )
}
import * as React from 'react'
import {Grid, Typography, MenuItem, TextField, Select, Container, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { axiosErrorHandling } from '../../misc/other'
import { ErrorAlert } from '../../alerts/ErrorAlert'




export const AddEmailTemplate = () => {

    const [to, setTo] = React.useState('new_accounts')
    const [emailTemplateName, setEmailTemplateName] = React.useState('mailgun_template')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const closeError = () =>{
        setError(false)
    }

    const selectChange = (event) =>{
        setTo(event.target.value)
    }

    const nameChange = (event) => {
        setEmailTemplateName(event.target.value)
    }

    const onSubmit = () =>{
        setLoading(true)
        axios({
            method: 'POST',
            url: 'api/oracle7/addemailtemplate',
            data: {to: to, email_template_name: emailTemplateName }
        }).then(result=>{
            
            if (result.data==='Added'){
                setEmailTemplateName('mailgun_template')
                setLoading(false)
            }
            else{
                setLoading(false)
            }

        }).catch(err=>{
            let errorMessage = axiosErrorHandling(err)
            setErrorMessage(errorMessage)
            setError(true)
        })
    }


return(

    
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <ErrorAlert open={error}  handleClose={closeError} error={errorMessage}/>
        <Grid container direction='column'>
            <Grid item>
                <Typography sx={{mb: '1rem'}}>Select if the email template is targeted to:  </Typography>
                <Typography sx={{textIndent: '3rem'}}> * "New Accounts" - Accounts where Users have not set up their login credentials</Typography>
                <Typography sx={{mb: '1rem', textIndent: '3rem'}}> * "Active Accounts" - Accounts where the User has set up their login credentials..</Typography>
                <Typography sx={{mb: '1rem'}}> Then enter the template name as it appers in MailGun. </Typography>
            </Grid>
        </Grid>
        <Grid container direction='row' sx={{mb: '1rem'}}>
            <Grid item >
        
        
                <Select
                    sx={{ mr: '1rem'}}
                    id='active-new-select'
                    value={to}
                    onChange={selectChange}
                >
                    <MenuItem value={'new_accounts'}>New Accounts</MenuItem>
                    <MenuItem value={'active_accounts'}>Active Accounts</MenuItem>

                </Select>
        
            </Grid>
            <Grid item>
                 <TextField
                    sx={{mr:'1rem'}} 
                    required
                    id='email_template_name'
                    label='email_template_name'
                    value={emailTemplateName}
                    onChange={nameChange}
                    helperText= 'As found in your mailgun account no spaces'
                />
            </Grid>
            <Grid item>
                 <LoadingButton sx={{minHeight: '3.5rem'}} 
                    size='large' 
                    variant='contained' 
                    loading={loading} 
                    loadingPosition='end' 
                    onClick={onSubmit}
                > Submit </LoadingButton>
            </Grid>
        </Grid>
    
    
    </Box>
    
    
    
)


}
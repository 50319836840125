import * as React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {TextField, Grid, CardContent, Typography, Button, Box} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useAuth } from '../misc/auth'
import { ErrorAlert } from '../alerts/ErrorAlert'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { ForgotPassword } from '../alerts/ForgotPassword'
import { axiosErrorHandling } from '../misc/other'


const validationSchema = yup.object().shape({
    email: yup.string().email("Email needs to include an @ and a . for logging in").required("Pleae enter a email"),
    password: yup.string().min(8, "Password must be 9 characters or longer").max(25,"Pasword has a limit of 25 Characters").required("Password is required")
  })




export const LoginForm = () =>{

    const context = JSON.parse(window.localStorage.getItem('context'))
    
    const [isError, setIsError] = React.useState(false)
    const [isSubmit, setIsSubmit] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [errorOpen, setErrorOpen] = React.useState(false)
    const [forgotPassword, setForgotPassword] = React.useState(false)
       
    const auth = useAuth()
    
    const closeForgotPassword = () =>{
        setForgotPassword(false)
    }
    
    const errorClose = ()=>{
        setErrorOpen(false)
    }
    
    const forgotPasswordClick = () =>{
        
        setForgotPassword(true)
    }
    


    const formik = useFormik({
        
        initialValues:{
            email: context.email,
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values, actions) =>{
            setIsSubmit(true)
            actions.setSubmitting(true)            
            
            
            context.email=values.email
                 axios({method: 'POST',
                        url: 'api/oracle1/login',
                        data: values })
            .then(results =>{
                if (results.data==='Email Not Set Up'){
                setErrorMessage('Please check your email address and try again if you have not set up your account click on the home button and enter your customer number and last name or ask for help with the feedback button below.')
                setIsError(true)
                actions.setSubmitting(false)
                setIsSubmit(false)
                }
                else if(results.data==='Incorrect Password'){
                    setErrorMessage('Password is incorrect Please Try Again')
                    setIsError(true)
                    actions.setSubmitting(false)
                    setIsSubmit(false)
                }
                else if(results.status===503){
                    setErrorMessage('Something Went Wrong')
                    setIsError(true)
                    actions.setSubmitting(false)
                    setIsSubmit(false)
                 }
                else{
                if(results.data.admin===0){
                
                axios({
                    method: "POST",
                    url:'api/oracle3/getdata',
                    data:{email: values.email}
                })
                .then(result=>{
                    
                    let roughData = result.data
                    let newContextData={
                        admin: 0,                        
                        first_name: roughData[0],
                        last_name: roughData[1],
                        email: values.email,
                        street_address_1: roughData[2],
                        street_address_2: roughData[3],
                        city: roughData[4],
                        state: roughData[5],
                        zip: roughData[6].slice(0,6),
                        zip4: roughData[7].slice(0,4),
                        phone_number:(roughData[8]).toString(),
                        customer_number: roughData[9],
                        mail_marketing: roughData[10],
                        email_marketing: roughData[11],
                        wave_customer_number: roughData[12]
                         }
                         
                        
                    window.localStorage.setItem('context', JSON.stringify(newContextData))
                    
                    
                    
                     
                }).catch((err)=>{
                    
                    let errorMess = axiosErrorHandling(err)
                    setErrorMessage(errorMess)
                    setErrorOpen(true)
                    actions.setSubmitting(false)
                    setIsSubmit(false)
                   
                })
            }else{
                axios({
                    method: "POST",
                    url:'api/oracle2/admininfo',
                    data:{email: values.email}
                })
                .then( async result=>{
                    
                    let data = result.data
                   
                   let newContextData = {                        
                    admin: 1,
                    email: values.email,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    customer_number: data.customer_number,
                    street_address_1: data.street_address_1,
                    street_address_2: data.street_address_2,
                    city: data.city,
                    state: data.state,
                    zip: data.zip,
                    zip4: data.zip4
                         }
                        
                    window.localStorage.setItem('context', JSON.stringify(newContextData))
                    
                    
                    
                     
                }).catch((err)=>{
                    let errorMess = axiosErrorHandling(err)
                    setErrorMessage(errorMess)
                    actions.setSubmitting(false)
                    setIsSubmit(false)
                    setErrorOpen(true)                    
                                    
                })



            }
                
                    setIsSubmit(false)    
                    actions.setSubmitting(false)
                    
                    auth.login(results.data)
                        }


                
            }).catch((err)=>{
                
                let errorMess = axiosErrorHandling(err)
                setErrorMessage(errorMess)
                actions.setSubmitting(false)
                setIsSubmit(false)
                setErrorOpen(true)   
                
            })

            
        }
            

    })
   
   
   let displayErrorMessage;

   if(isError){displayErrorMessage = <Typography color='red' variant = 'body1'>{errorMessage}</Typography>}
   
   
    return(
        <Box component='form' onSubmit={formik.handleSubmit} >
        <ForgotPassword open={forgotPassword} handleClose={closeForgotPassword} email={formik.values.email}/>    
        <Grid container direction='column' alignItems='flex-start' justifyContent='center'>
            
            <CardContent>
                <Typography  color='secondary' variant='h3'>Login: </Typography>
            </CardContent>
            
            <CardContent>
            <TextField
         variant='outlined' 
         id ='email'
         name='email'
         label= 'Email'
         type='email'
         value={formik.values.email}
         onChange={formik.handleChange}
         error={formik.touched.email && Boolean(formik.errors.email)}
         helperText={formik.errors.email}
              
        />
        </CardContent>
        <CardContent sx={{paddingBottom: .5}}>
    <TextField
         variant='outlined' 
         id ='password'
         sx={{
           marginRight: 2,
           marginBottom: 2
         }}
         name='password'
         label= 'Password'
         type='password'
         autoFocus
         value={formik.values.password}
         onChange={formik.handleChange}
         error={formik.touched.password && Boolean(formik.errors.password)}
         helperText={formik.errors.password}
              
        />
        <LoadingButton 
                       loading={isSubmit}
                       loadingPosition='end'
                       size='large'
                       onClick={formik.handleSubmit} 
                       sx={{marginLeft: 2, mt: 2}} 
                       variant='contained' 
                       disabled={!formik.isValid} 
                       type="submit" 
                       value="submit" 
                       color="primary" 
                       endIcon= {<AccountBoxIcon />}>
                           Login
                           </LoadingButton>
          <CardContent sx={{paddingTop: .025, paddingBottom: .5, justifySelf: 'flex-start'}}>
          <Button sx={{paddingLeft: .25}} variant='text' onClick={forgotPasswordClick}><u>Forgot your password</u></Button>
        </CardContent>                 
          <CardContent sx={{paddingTop: .025}}>
          {displayErrorMessage}
            </CardContent>                 
          </CardContent>

            

        <Grid item>
            <ErrorAlert error={errorMessage} open={errorOpen} handleClose={errorClose} />
            

        </Grid>
        </Grid>
         </Box>
    )


}
import * as React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { axiosErrorHandling } from '../../misc/other'
import { ErrorAlert } from '../../alerts/ErrorAlert'




export const ValidateEmail = () =>{
    const [stepOneText, setStepOneText] =React.useState('Prepping Database ....')
    const [stepTwoText, setStepTwoText] = React.useState('')
    const [unvalidatedEmails, setUnvalidatedEmails] = React.useState([{id: 1, email: 'Fetching Emails For Validation'}])
    const [emailToValidate, setEmailToValidate] = React.useState('')
    const [validateLoading, setValidateLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    
    const closeError = () =>{
        setError(false)
    }
    
   
    
    const validateClick = async ()=>{
        setValidateLoading(true)
        await axios({
            method:'POST',
            url: 'api/oracle7/validateemail',
            data: {email: emailToValidate}
        }).then( res=>{
            console.log('Here is the response length', res.data.length)
            setUnvalidatedEmails(res.data)
            setEmailToValidate(res.data[0].email)
            setValidateLoading(false)
            console.log('Here is the response: ', res.data)
            
        }).catch(err=>{
            
            setValidateLoading(false)
            let errorMessage = axiosErrorHandling(err)
            setErrorMessage(errorMessage)
            setError(true)
            
        })
    
        console.log('Here is the new data.')
    }
    
    
    React.useEffect(()=>{

       
        
     const firstCallresponse = async () =>  await axios({
          method: 'POST',
          url: 'api/oracle7/dbprep',
          data: { message: 'Updating db'}
      }).then(res=>{
          console.log('Here is the response: ', res.data)
            
          
          if(res.data === 'DB Updated'){
              setStepOneText('Database Prepped')
              setStepTwoText('Prepping Email Validation....')
              return res.data
          }else{
              setStepOneText("Refresh the page, if you see this again contact the system administrator.")
                return('Error')
            }
    
          
    
      })
     
     firstCallresponse().then((result)=>{
        if(result !== 'DB Updated'){
      
        
            setStepTwoText('Something went wrong')
        
        
          } else {
            axios({
                method: 'POST',
                url: 'api/oracle7/emailvalidation',
                data: {message: 'Validating Email Address'}
            }).then(res=>{
                console.log('Here is the response length: ', res.data.length)
                if(res.data.length !== 0){
                console.log('Here is the response: ', res.data)
                setUnvalidatedEmails(res.data)
                setEmailToValidate(res.data[0].email)
                setStepTwoText('Emails that need to be Validated:')
            
                }else{
                    setStepTwoText('No Emails to be Validated')
                    setEmailToValidate([{id: 1, email: 'No Emails to Validate'}])
                }
            })
            
          }

    }).catch((err)=>{
        
            let errorMessage = axiosErrorHandling(err)
            setErrorMessage(errorMessage)
            setError(true)
    }

    )},[])
    
    const unvalidatedEmailsList = unvalidatedEmails.map((e)=>
    <li key={e.id}>{e.email}</li> 
    )
    
    
    
    return(
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>
        <ErrorAlert open={error}  handleClose={closeError} error={errorMessage}/>  
        <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item>
    <Typography variant='body1'>{stepOneText}</Typography>
            </Grid>
            <Grid item>
            <LoadingButton loading={validateLoading} loadingPosition='end' variant='contained' sx={{color: 'white', borderColor:'#FE5200'}} onClick={validateClick} >Validate</LoadingButton>     
            </Grid>
            <Grid item>
    <Typography  variant='body1'>{stepTwoText}</Typography>
            </Grid>
            <Grid item>
    <Typography sx={{lineHeight: '1.5em',
                     borderBottom: '1px',
                     float: 'left',
                     display: 'inline'}}>{unvalidatedEmailsList}</Typography>
            </Grid>
    </Grid>
    </Box>
    
   
    )
    
    
    
    }
import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import {styled} from '@mui/material/styles'


const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  
 export const FileSizeToBig = ({open, handleClose}) => {


    return(

        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
            
            The File Size is to Big.  
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Maximum size is 2 MB
            </Typography>
            <Typography gutterBottom>
              Please edit the file and reduce the number of accounts.
            </Typography>
            
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
              Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    )
} 
import * as React from "react"
import dayjs from "dayjs"
import {  useFormik } from "formik"
import { TextField, Grid, Button, Paper } from "@mui/material"
import {DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker} from '@mui/x-date-pickers-pro'
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DataGridPro} from '@mui/x-data-grid-pro'
import * as yup from 'yup'
import axios from "axios";
import { defaultDateSelection } from "../misc/dates"






const validationSchema= yup.object().shape({
  promo_code: yup.string().required("Please fill out an easy to remember Promo Code")  
                 .min(5, 'Please enter 5 characters')
                 .max(17, 'Please only use 17 characters for the promocode'),
 promo_percentage: yup.number().required("Please input a number")
                .min(.1, "Please enter a number that is at least 10% (.1)")
                .max(.61, "The maximum percentage allowed is 60%(.6)")
 

})

const columns = [
    {field: 'id', headerName: 'ID', flex: 1},
    {field: 'promo_code', headerName: 'Promo Code', flex: 4},
    {field: 'promo_percentage', headerName: '% Off', flex: 4},
    {field: 'expiration_date', headerName: 'Expiration Date', flex: 4}]


export const AdminPromoForm = () =>{
    
    const [expirationDate, setExpirationDate] = React.useState(dayjs(defaultDateSelection)) 
    const [expirationError, setExpirationError] = React.useState(true)
    const [data, setData] = React.useState([])    
   
    
    const handleChange=(newValue)=>{
        let date = dayjs(newValue.get('year') + '-' + (newValue.get('month')+1) + '-' + newValue.get('date'))
        
        setExpirationDate(date)
        
    }

React.useEffect(()=>{
    axios({
        method:'POST',
        url:'api/oracle2/getpromocodes',
        data: {message: 'Get Promo Codes'}
        
    }).then(res=>{
        
        setData(res.data)
        
    })

},[])

React.useEffect(()=>{
    let dateA = new Date(defaultDateSelection)
    let dateB = new Date(expirationDate.format('YYYY-MM-DD'))
    if (dateA < dateB){
        setExpirationError(false)
    } else {
        setExpirationError(true)
    }


 },[expirationDate])

  
    

    const formik = useFormik({

        initialValues:{
            promo_code: 'Promo Code',
            promo_percentage: .20
            

        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)
            let dateToSend = expirationDate.format('YYYY-MM-DD')
            

            axios({
                method: 'POST',
                url: 'api/oracle2/promosubmit',
                data: {values, expiration_date: dateToSend}
            })
            .then(res=>{
                
            })
            actions.setSubmitting(false)
            actions.resetForm()
    }
            
    })

    

    return(
        <React.Fragment>
            <form onSubmit={formik.handleSubmit}>
        <Grid container direction='row' justifyContent='center'>
             
            <Grid item >            
                <TextField
                sx={{mr: 4, mb: 8}}
                variant='outlined'
                label='Promo Code'
                id='promo_code'
                name='promo_code'
                value={formik.values.promo_code} 
                onChange={formik.handleChange}
                error={formik.touched.promo_code && Boolean(formik.errors.promo_code)}
                helperText={formik.errors.promo_code}
                />
                
            </Grid>
            <Grid item>            
                <TextField
                 sx={{mr: 4, mb: 8}}
                 variant='outlined'
                 id='promo_perecentage'
                 name='promo_percentage'
                 label='% Off'
                 onChange={formik.handleChange}
                 error={formik.touched.promo_percentage && Boolean(formik.errors.promo_percentage)}
                 value={formik.values.promo_percentage} />
            </Grid>
            <Grid item>            
             <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>  
                    <DatePicker
                    label='Expiration Date'
                    inputFormat='MM/DD/YYYY'
                    defaultValue={defaultDateSelection}
                    views={['year', 'month', 'day']}
                    value={expirationDate}
                    onChange={handleChange}
                    renderInput={(params)=><TextField {...params} />}
                    />
                    </DemoContainer>
                </LocalizationProvider>
            </Grid>

        </Grid>
        <Grid container direction='row' justifyContent = 'center'>
            <Grid item spacing={2}>
                <Button variant='contained'
                        sx={{mb: 2}} 
                        color='primary' 
                        size='large'
                        disabled={expirationError}
                        type='submit'
                        value='submit'>Create Promo</Button>
            </Grid>
            
            </Grid>
            </form>
            <Grid container direction='row' justifyContent='center' alignItems='center' alignContent='center'>
                <Grid item xs sm={9} md={9} lg={8} > 
                    <Paper>
                    <DataGridPro
                    rows={data} 
                    columns={columns} 
                    autoPageSize={true} 
                    checkboxSelection 
                    disableMultipleSelection={true}
                    autoHeight={true} 
                    columnBuffer={3} 
                    hideFooter={false} />
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    )

}
import * as React from 'react'
import { Dialog, DialogTitle, Button, DialogContent, DialogActions, Typography  } from '@mui/material';
import {styled} from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'


const CustomDialog = styled(Dialog)(({theme})=>({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
      },
  
  }));

  export const FoundCustomerNumber =({open, handleClose, customerNumber}) =>{

    return(
        <CustomDialog 
        open={open}
        onClose={handleClose}>
            <DialogTitle sx={{padding: 1, mr: '3rem'}} onClose={handleClose}>
           Below is the Customer Number we found.
            </DialogTitle>
            <DialogContent dividers>
            <Typography variant='body1'gutterBottom>
              Here is the customer Number: <strong>{customerNumber}</strong> 
            </Typography>
            <Typography gutterBottom>
             Click the button below to copy the number. Don't forget to give the customer number to the customer.
            </Typography>
            
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary" endIcon={<CloseIcon />}>
               Copy and Close
            </Button>
          </DialogActions>

    </CustomDialog>
   
    


    )
  }
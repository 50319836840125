import * as React from 'react'
import { Outlet, Navigate} from 'react-router-dom'
import { MainHeader } from './headers/Headers'
import { MainFooter } from './footers/Footers'
import { FeedbackButton } from '../alerts/FeedbackButton'
import { AuthProvider, initialContext} from '../misc/auth'




export const UnsecuredLayout =()=>{
    let context = JSON.parse(window.localStorage.getItem('context'))
    if(context===null || (Object.keys(context).length=== 0 && context.constructor === Object) ){
        window.localStorage.setItem('context', JSON.stringify(initialContext))
        }
        
        
        let userInfo =  JSON.parse(window.localStorage.getItem('user'))
        

    
    
      

    const pages=[
            {label: 'Home', path: '/'},
            {label: 'Login', path: '/login'},
            {label: 'FAQ', path:'/FAQ'},
            {label: 'Privacy Policy', path: '/privacy-policy'},
            {label: 'Refund Policy', path: '/refund-policy'},
            {label: 'Terms and Conditions', path: '/termsandconditions'}    
    ]

    

    

    const user = userInfo || {email: 'name@domain.com', admin: 2, token: ''}
    

    
    
    
        if(user){
            if (user.admin===1){
                return <Navigate to='/admin/dashboard' replace={true}/>
            }
            else if(user.admin===0){
                let path = '/user/accounts/' + user.email
                return <Navigate to={path} replace={true} />
            }
        }
    

    return(
    <>
           
    <AuthProvider userData={user}>   
    <MainHeader pages={pages}/>    
        <Outlet />
        <FeedbackButton />
    <MainFooter />
    </AuthProvider>
    </>
    )

}
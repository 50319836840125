import * as React from 'react'
import {useFormik} from 'formik'
import * as yup from 'yup'
import { TextField, Grid, Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import SearchIcon from '@mui/icons-material/Search';
import { AdminSearchErrorSSN } from '../alerts/AdminSearchError'
import { axiosErrorHandling } from '../misc/other'
import { FoundCustomerNumber } from '../alerts/CustomerNumberFound'

const numberRegex = /^\d{9}$/

const validationSchema = yup.object().shape({
    number: yup.string()
            .required('Please enter a number').matches(numberRegex, 'Invalid Number'  )
            .min(9, 'Please enter 9 numbers')
})


export const SSNSearchForm = () =>{

const [isSearching, setIsSearching] = React.useState(false)
const [findAlert, setFindAlert] = React.useState(false)
const [errorMessage, setErrorMessage] = React.useState(false)
const [customerNumber, setCustomerNumber] = React.useState('')
const [foundCustomerNumber, setFoundCustomerNumber] = React.useState(false)

const alertClose = () =>{
    setFindAlert(false)
}

const copyAndClose = () =>{
    navigator.clipboard.writeText(customerNumber)
    setFoundCustomerNumber(false)
}

const formik = useFormik({
    initialValues: {
        number: ''

    },
    validationSchema:validationSchema,

    onSubmit: (values, actions) =>{
        
        setIsSearching(true)
        axios({method: 'POST',
               url: 'api/oracle2/getnumber',
              data: values,
              withCredentials: true
            }).then(results=>{
                if(results.status===200){
                    setCustomerNumber(results.data[0])
                    setIsSearching(false)
                    actions.setSubmitting(false)
                    setFoundCustomerNumber(true)
                    ('Here are the correct results: ', results.data)
                }else if(results.status===504){
                    setIsSearching(false)
                   
                }else{
                    setIsSearching(false)
                    
                }
            }).catch((err)=>{
                let errMessage = axiosErrorHandling(err)
                setErrorMessage(errMessage)
                setIsSearching(false)
                setFindAlert(true)
                
                
                
            })

    }
    

})


return( 
<Box component='form' onSubmit={formik.handleSubmit}>
    <Grid container direction='row'>
      <Grid item xs={12} lg={8}>
        <TextField 
        id="number" 
        label='SSN Search' 
        type='password'
        fullWidth 
        variant="standard"
        value={formik.values.number}
        onChange={formik.handleChange}
        error={formik.touched.number && Boolean(formik.errors.number)}
        helperText={formik.errors.number}
        ></TextField>
      </Grid>
      <Grid>
        <LoadingButton loading={isSearching} 
                       loadingPosition='end' 
                       sx={{ml: 2}} 
                       endIcon={<SearchIcon />} 
                       variant='contained'
                       onClick={formik.handleSubmit}
                       disabled={!formik.isValid}
                       > Search </LoadingButton>
      </Grid>
    </Grid>
    <FoundCustomerNumber open={foundCustomerNumber} handleClose={copyAndClose} customerNumber={customerNumber}/>
    <AdminSearchErrorSSN open={findAlert} handleClose={alertClose} errorMessage={errorMessage}/>
</Box>
)
}
import * as React from 'react'
import {Card, Container, Box, Grid} from '@mui/material'
import { LoginForm } from '../forms/Login'





export const Login = () => {
    

return(
    <React.Fragment>
    <Container>
    <Box sx={{minHeight:'80vh', mt: 15, mb: 2}} >
    <Box justifyContent='center' alignItems='center'>
        <Grid container direction='row' justifyContent='center'>
            <Card sx={{display:'flex', alignContent: 'center', justifyContent: 'center', justifyItems: 'center', width: 7/10}}>

            <LoginForm />

            </Card>
        </Grid>
       
    </Box>
    </Box>
    
    </Container>
    
    </React.Fragment>
    
    
    
)


}
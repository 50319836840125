import * as React from 'react'
import {Typography, Grid, Box, ToggleButton, ToggleButtonGroup} from '@mui/material'
import { DownloadDocuments, UploadDocuments } from '../../forms/Documents'


export const UserDocuments = () =>{
    
    const [alignment, setAlignment] = React.useState('upload')

    
    const handleAlignment = (event, newAlignment) =>{
        setAlignment(newAlignment)
        
    }


    const downloadClick = () =>{
        
        setAlignment('download')
        
    }

    let form;


    if(alignment==='upload'){
        form =
                    
        <UploadDocuments download ={downloadClick}/>        
        
    }else{
        form = 
        <DownloadDocuments/>
    }

    

    return (
        
        
        <Box sx={{minHeight:'80vh', mt: 15, mb: 2}}>

        <Grid container
              direction='column'
              alignItems='center'
              justifyContent='center'
              sx={{mb: '1rem'}}>
         <Grid item sx={{mb: '1rem'}}>
            <Typography color = 'primary' variant='h5' align='center' sx={{mb: '1rem'}}>Use the Upload button to submit documentation for proof if this is not your debt or the debt has been settled.</Typography>
            <Typography color = 'secondary' variant='h5' align='center' sx={{mb: '1rem'}}>Use Download to see any documentation you have uploaded or to re-download your settlement or offer letter.</Typography>
        </Grid>         
        <Grid item sx={{mb: '1rem'}}>
    <ToggleButtonGroup color='secondary' value={alignment}size='large' aria-label='Upload Documents or Download Documents Button'>
            <ToggleButton value='upload'color='primary'  onClick={handleAlignment}>Upload</ToggleButton>
            <ToggleButton value='download' color='primary' onClick={handleAlignment}>Download
            </ToggleButton>


    </ToggleButtonGroup>
    </Grid>
    {form}
    
    </Grid>
    </Box>
    
    
    
    )



}
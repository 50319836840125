function appendLeadingZeros(n){
    if(n <= 9){
      return "0" + n;
    }
    return n
  };

  let currentDatetime = new Date()
  
  var timeElasped = Date.now()

  var today = new Date(timeElasped)

  var time = today.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second:'2-digit'})

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  export const todaysDate = new Date()

  export const purchaseDate = todaysDate.getFullYear() +'-'+ appendLeadingZeros(todaysDate.getMonth()+1) + '-'+ appendLeadingZeros(todaysDate.getDate())

  export const PrivacyPolicyDate = months[todaysDate.getMonth()] + ' ' + todaysDate.getDate() +', ' + todaysDate.getFullYear() 

  export const PDFDate = todaysDate.getFullYear() + "_" + appendLeadingZeros(todaysDate.getMonth() + 1) + "_" + appendLeadingZeros(todaysDate.getDate())  + '_' + time

  export const defaultDateSelection = (today.getFullYear()+2)+ '-' + appendLeadingZeros(today.getMonth()+1) + '-' + appendLeadingZeros(today.getDate())

  export const formatted_date = months[todaysDate.getMonth()]+' '+ todaysDate.getDate()+', '+todaysDate.getFullYear()

  export const dayOfMonth = appendLeadingZeros(todaysDate.getDate())
  
  export const secondDigits = appendLeadingZeros(todaysDate.getMonth()+1)

  export const thirdDigits = appendLeadingZeros(todaysDate.getDate())

  var gmformattedDate = appendLeadingZeros(currentDatetime.getMonth() + 1) + "/" + appendLeadingZeros(currentDatetime.getDate())+"/"+ currentDatetime.getFullYear()

  var mysqlDate = currentDatetime.getFullYear() + '-' + appendLeadingZeros(currentDatetime.getMonth() + 1) + '-' + appendLeadingZeros(currentDatetime.getDate())

  const thisMonth = months[currentDatetime.getMonth()]

  const thisYear = currentDatetime.getFullYear()

  const mysqlDateAndTimestamp = currentDatetime.toISOString().slice(0, 19).replace('T', ' ')

  export const dateFormatted = {
    gm: gmformattedDate,
    mysql: mysqlDate,
    thisMonth: thisMonth,
    thisYear: thisYear,
    mysqlDateAndTimestamp : mysqlDateAndTimestamp,
    purchaseDate: purchaseDate
}
import * as React from 'react'
import { Navigate }  from 'react-router-dom'
import { Grid, Typography,Box } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import StartIcon from '@mui/icons-material/Start'
import { ErrorAlert } from '../../alerts/ErrorAlert'
import { axiosErrorHandling } from '../../misc/other'
import axios from 'axios'





export const MoneyOrder = ({paymentInfo, customerInfo, totalDue})=>{
    
    const path = '/user/accounts/' + customerInfo.email
    const totalDueplus5 = totalDue + 5

    const [goHome, setGotoHome]= React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')

    const closeError = () =>{
        setError(false)
    }

    const onClick = () =>{
        setLoading(true)
        axios({
            method: 'POST',
            url: 'api/oracle3/moneyorder',
            data:{data: paymentInfo.accounts,
                  balance: totalDue+5,
                  promo_code_used: paymentInfo.accounts[0].promo_code_used
             } 
        }).then(result=>{
            
            if(result.data==="Pending MoneyOrder"){
                setLoading(false)
                setGotoHome(true)
            }
            setLoading(false)
        }).catch(err=>{
            
            let message = axiosErrorHandling(err)
            setErrorMessage(message)
            setError(true)
            setLoading(false)
        })

    }
    
    if (goHome){
        return <Navigate  to={path}/>
    }

  

    
    
    return(
        
        <Box sx={{minHeight:'40vh', mt: 2}}>
        <Grid container direction='row' justifyContent='center' alignItems ='center'>
        <ErrorAlert open={error} handleClose={closeError} error={errorMessage} />
        <Grid item xs={10} >
        <Typography sx ={{mb: 4}} variant='h6'gutterBottom={true}> To complete payment by money order, make the money order out
                                          to NameMySettlement.com with the amount of ${totalDueplus5}. Which is your total due plus $5 for processing. Please send the Money Order to the following address, PO Box 15333, Spokane Valley, WA 99215. Please include your offer 
                                          letter in the envelope or your customer number on the memo portion of the money order.  It is best to send the Money Order certified mail with a return on receipt service to verify that 
                                          we have received the Money Order. </Typography>
        </Grid>
        </Grid>
        <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Grid item>
        <LoadingButton loading={loading} onClick={onClick} loadingPosition='end' endIcon={<StartIcon />} variant='contained' color='primary'> Go Back to Account Home</LoadingButton>    
        
        </Grid>
        
        </Grid>
        
        
        </Box>
        
        
    
    )

}